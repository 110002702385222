import React from "react"
import { AuthUserContext } from "../Authentication/Session/Session"
import { withFirebase } from "../Authentication/Firebase"
import { withRouter } from "react-router-dom"
import { compose } from "recompose"
import { withAuthorization } from "../Authentication/Session/Session"

import CloseButton from "../Common/CloseButton"
import CheckBoxBtn from "../Common/CheckBoxBtn"

import { _positions } from "../Store/SlideDBTemplate"

const FormPosition = ({
  label,
  itemPos,
  showName,
  posName,
  oxName,
  oyName,
  onChange,
}) => (
  <div className="form-row">
    <div className="form-group col-sm-1 ">
      <label className="col-form-label">Show</label>
      <CheckBoxBtn
        id={showName}
        name={showName}
        checked={itemPos.show}
        onChange={onChange}
      />
    </div>
    <div className="form-group col-sm-5">
      <label className="col-form-label">{label}</label>
      <select
        defaultValue={itemPos.position}
        className="form-control"
        name={posName}
        onChange={onChange}
        disabled={itemPos.show ? false : true}
      >
        {_positions.map((pos) => {
          return (
            <option value={pos} key={pos}>
              {pos}
            </option>
          )
        })}
      </select>
    </div>
    <div className="form-group col-sm-3">
      <label className="col-form-label">Offset X*</label>
      <input
        defaultValue={itemPos.offsetX}
        type="number"
        className="form-control"
        name={oxName}
        onChange={onChange}
        disabled={itemPos.show ? false : true}
        required
      />
    </div>
    <div className="form-group col-sm-3">
      <label className="col-form-label">Offset Y*</label>
      <input
        defaultValue={itemPos.offsetY}
        type="number"
        className="form-control"
        name={oyName}
        onChange={onChange}
        disabled={itemPos.show ? false : true}
        required
      />
    </div>
  </div>
)

const SlideTemplateEditor = (props) => {
  const onClose = () => {
    props.dispatch({ type: "VIEW_MODE", payload: "" })
  }
  const onSubmit = (template) => {
    props.dispatch({ type: "SET_TEMPLATE", payload: template })
    props.dispatch({ type: "VIEW_MODE", payload: "" })
  }
  return (
    <div
      className="slide-editor-backdrop"
      id="backdrop"
      onMouseDown={(e) => {
        if (e.target.id === "backdrop") {
          e.stopPropagation()
          onClose()
        }
      }}
    >
      <div className="slide_template_editor">
        <CloseButton onClick={onClose} />
        <h2 className="text-center">Edit Template</h2>
        <Content {...props} onSubmit={onSubmit} onClose={onClose} />
      </div>
    </div>
  )
}

class EditUserContent extends React.Component {
  constructor(props) {
    super(props)
    this.state = props.template
  }

  onKeyDown = (event) => {
    //console.log(`OnKeyDown called on form, key is:`, event.keyCode)
    if (event.keyCode === 13) {
      // Enter key : valid the form
      if (event.target.tagName === "TEXTAREA") return
      this.props.onSubmit(this.state)
      event.stopPropagation()
    } else if (event.keyCode === 27) {
      // Esc key : clode the form, no save
      this.props.onClose()
      event.stopPropagation()
    }
  }

  onChange = (event) => {
    // huuuuuu love that :-S
    const n = event.target.name
    const v = n.includes("offset")
      ? parseInt(event.target.value, 10)
      : event.target.value
    if (n.indexOf(".") < 0) {
      this.setState({ [n]: v })
    } else {
      const [n1, n2] = n.split(".")
      if (n2 === "show") {
        const nv = !this.state[n1][n2]
        this.setState({ [n1]: { ...this.state[n1], [n2]: nv } })
      } else {
        this.setState({ [n1]: { ...this.state[n1], [n2]: v } })
      }
    }
  }

  handleSubmit = (event) => {
    this.props.onSubmit(this.state)
    event.preventDefault()
  }

  render() {
    const {
      title,
      titlePos,
      subtitle,
      subtitlePos,
      counterPos,
      partnamePos,
      partpicknamePos,
      partpickcountPos,
    } = this.state
    return (
      <AuthUserContext.Consumer>
        {() => (
          <div onKeyDown={this.onKeyDown} tabIndex={0}>
            <form className="userForm" onSubmit={this.handleSubmit}>
              <div className="form-group">
                <label className="col-form-label">Title*</label>
                <input
                  defaultValue={title}
                  type="text"
                  className="form-control"
                  name="title"
                  onChange={this.onChange}
                  required
                />
              </div>
              <FormPosition
                label="Title position"
                itemPos={titlePos}
                showName="titlePos.show"
                posName="titlePos.position"
                oxName="titlePos.offsetX"
                oyName="titlePos.offsetY"
                onChange={this.onChange}
              />
              <hr style={{ border: ".5px solid #fff" }} />
              <div className="form-group">
                <label className="col-form-label">Subtitle</label>
                <input
                  defaultValue={subtitle}
                  type="text"
                  className="form-control"
                  name="subtitle"
                  onChange={this.onChange}
                />
              </div>
              <FormPosition
                label="Subtitle position"
                itemPos={subtitlePos}
                showName="subtitlePos.show"
                posName="subtitlePos.position"
                oxName="subtitlePos.offsetX"
                oyName="subtitlePos.offsetY"
                onChange={this.onChange}
              />
              <hr style={{ border: ".5px solid #fff" }} />
              <FormPosition
                label="Counter position"
                itemPos={counterPos}
                showName="counterPos.show"
                posName="counterPos.position"
                oxName="counterPos.offsetX"
                oyName="counterPos.offsetY"
                onChange={this.onChange}
              />
              <hr style={{ border: ".5px solid #fff" }} />
              <FormPosition
                label="Step name position"
                itemPos={partnamePos}
                showName="partnamePos.show"
                posName="partnamePos.position"
                oxName="partnamePos.offsetX"
                oyName="partnamePos.offsetY"
                onChange={this.onChange}
              />
              <hr style={{ border: ".5px solid #fff" }} />
              <FormPosition
                label="Step description position"
                itemPos={partpicknamePos}
                showName="partpicknamePos.show"
                posName="partpicknamePos.position"
                oxName="partpicknamePos.offsetX"
                oyName="partpicknamePos.offsetY"
                onChange={this.onChange}
              />
              <hr style={{ border: ".5px solid #fff" }} />
              <FormPosition
                label="Number of position"
                itemPos={partpickcountPos}
                showName="partpickcountPos.show"
                posName="partpickcountPos.position"
                oxName="partpickcountPos.offsetX"
                oyName="partpickcountPos.offsetY"
                onChange={this.onChange}
              />
              <hr style={{ border: ".5px solid #fff" }} />
              <button className="btn hmk-form-btn" type="submit">
                Ok
              </button>
            </form>
          </div>
        )}
      </AuthUserContext.Consumer>
    )
  }
}

const Content = compose(withRouter, withFirebase)(EditUserContent)

const condition = (currentUser) => !!currentUser

export default withAuthorization(condition)(SlideTemplateEditor)
