import React, { useContext } from "react"

import { AuthUserContext } from "../Authentication/Session/Session"
import { withFirebase } from "../Authentication/Firebase"
import { withRouter } from "react-router-dom"
import { compose } from "recompose"
import { withAuthorization } from "../Authentication/Session/Session"

import { HMFileIO } from "../Common/HMFileIO"
import { ContentStoreCtx, GlobalContext } from "../Store/ContentStore"

import { MdPermMedia } from "react-icons/md"
import ReactTooltip from "react-tooltip"
import { deviceResolution } from "../Device/DeviceMgr"

const DEFAULT_EXPORT_RESOLUTION = { width: 1920, height: 1080 }
const DEFAULT_EXPORT_THUMBNAIL = [256, 256]
const DEBUG = false

const ExportSlidesAction = (props) => {
  const [state, dispatch] = useContext(ContentStoreCtx)
  const globalContext = useContext(GlobalContext)

  const loadImage = (href) => {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.crossOrigin = "Anonymous"
      img.addEventListener("load", () => resolve(img))
      img.addEventListener("error", (err) => reject(err))
      img.src = href
    })
  }

  // convert an image with href to external resources to a local canvas href
  const toDataURL = (image) => {
    return loadImage(image.getAttribute("href")).then((img) => {
      // we should now be able to draw it without tainting the canvas
      const canvas = document.createElement("canvas")
      canvas.width = img.width
      canvas.height = img.height
      // draw the loaded image
      canvas.getContext("2d").drawImage(img, 0, 0)
      // set our <image>'s href attribute to the dataURL of our canvas
      const newHref = canvas.toDataURL("image/png")
      //console.log(`Convert Image ref ${image.getAttribute("href")}`)
      image.setAttribute("href", newHref)
    })
  }

  const createSlideURL = (slideRootNode, resolution) => {
    const win = window.URL || window.webkitURL || window
    var svgUrl = null
    return new Promise((resolve, reject) => {
      // convert the SVG DOM to data
      if (DEBUG)
        console.log("CreateSlideURL : serialize to string node ", slideRootNode)
      const svgData = new XMLSerializer().serializeToString(slideRootNode)
      const svgBlob = new Blob([svgData], {
        type: "image/svg+xml;charset=utf-8",
      })
      svgUrl = win.createObjectURL(svgBlob)
      //console.log(`Load Image from updated SVG: ${svgUrl}`)
      // convert the SVG data to an image
      resolve(loadImage(svgUrl))
    }).then((img) => {
      // create the canvas to render the SVG image inside
      const canvas = document.createElement("canvas")
      // this resolution must be taken from device display parameters
      canvas.width = resolution.width
      canvas.height = resolution.height
      canvas.getContext("2d").drawImage(img, 0, 0)
      // once SVG is rendered to canvas, auto download it as picture file
      win.revokeObjectURL(svgUrl)
      //const uriToDownload = canvas
      //  .toDataURL("image/png")
      //  .replace("image/png", "octet/stream")
      //downloadUri(uriToDownload, "svg_slide_" + slide.seq_id + ".png")
      const uriToUpload = canvas.toDataURL("image/png")
      return uriToUpload
    })
  }

  const exportSlides = (uid, folderId, resolution) => {
    const win = window.URL || window.webkitURL || window
    // extract svg slides from the slide svg tag
    let information = []
    const svgSlides = state.slides.map((slide, slideIdx) => {
      let svgSlide = {
        id: slideIdx, //slide.seq_id,
        node: document.getElementById("svg_slide_" + slide.seq_id),
      }
      let informationSlide = {}
      informationSlide.stepname =
        slide.content && slide.content[0].part_name
          ? slide.content[0].part_name
          : `Step Name ${slideIdx + 1} (fallback)`
      informationSlide.delay = Object.prototype.hasOwnProperty.call(
        slide,
        "delay"
      )
        ? slide.delay
        : -1
      informationSlide.video = Object.prototype.hasOwnProperty.call(
        slide,
        "video"
      )
        ? slide.video
        : true
      const keys_to_copy = [
        "links",
        "video_clip",
        "audio_clip",
        "detection_mode",
        "detection_model",
        "detection_labels",
        "detection_threshold_low",
        "detection_threshold_middle",
        "detection_threshold_high",
        "blocking_mode",
        "blocking_labels",
        "checklist",
      ]
      keys_to_copy.forEach((key) => {
        if (Object.prototype.hasOwnProperty.call(slide, key)) {
          informationSlide[key] = slide[key]
        }
      })
      information.push(informationSlide)
      return svgSlide
    })
    // some stats: nb max media layer on one slide
    let nbMediaMax = 0
    svgSlides.forEach((svgSlide) => {
      const mediaContents = svgSlide.node.querySelectorAll("[id^=content_]")
      if (mediaContents.length > nbMediaMax) nbMediaMax = mediaContents.length
    })
    const content = {}
    // initialize header structure
    content["composite"] = { url: new Array(svgSlides.length).fill("") }
    content["labels"] = { url: new Array(svgSlides.length).fill("") }
    for (let i = 0; i < nbMediaMax; i++) {
      content["layer_composite_" + i] = {
        url: new Array(svgSlides.length).fill(""),
      }
      content["layer_image_" + i] = {
        url: new Array(svgSlides.length).fill(""),
      }
      content["layer_geom_" + i] = {
        url: new Array(svgSlides.length).fill(""),
      }
    }
    Promise.all(
      svgSlides.map((svgSlide) => {
        return (
          new Promise((resolve, reject) => {
            // convert external pictures reference to local image canvas
            const images = svgSlide.node.querySelectorAll("image")
            if (DEBUG)
              console.log(
                `Export Slides : process slide svg_slide_${svgSlide.id} with ${images.length} images to convert.`
              )
            resolve(
              Promise.all(
                Array.from(images).map((image) => {
                  return toDataURL(image)
                })
              )
            )
          })
            // **********************************************************
            // Export the composite (full content) version of the slide
            // **********************************************************
            .then(() => {
              return createSlideURL(svgSlide.node, resolution)
            })
            .then((uriToUpload) => {
              const filename = "composite_svg_slide_" + svgSlide.id + ".png"
              const file = new File([b64ToUint8Array(uriToUpload)], filename, {
                type: "image/png",
              })
              win.revokeObjectURL(uriToUpload)
              content["composite"].url[svgSlide.id] = filename
              return uploadUri(uid, file, folderId, false, true)
            })
            // ***********************************************************
            // then export the image only version of the slide (no text)
            // ***********************************************************
            .then(() => {
              const iSlide = svgSlide.node.cloneNode(true)
              // remove all texts from the slide
              const texts = iSlide.querySelectorAll("text")
              Array.from(texts).forEach((node) => {
                node.parentNode.removeChild(node)
              })
              const mediaContents = iSlide.querySelectorAll("[id^=content_]")
              return Promise.all(
                Array.from(mediaContents).map((mediaContent) => {
                  let mSlide = iSlide.cloneNode(false)
                  mSlide.appendChild(mediaContent)
                  return new Promise((resolve, reject) =>
                    resolve(createSlideURL(mSlide, resolution))
                  ).then((uriToUpload) => {
                    let nbMedia = mediaContent.getAttribute("index")
                    const filename =
                      "media_svg_slide_" + svgSlide.id + "_" + nbMedia + ".png"
                    // fill the header file
                    content["layer_composite_" + nbMedia].url[svgSlide.id] =
                      filename
                    // create the media file and upload it
                    const file = new File(
                      [b64ToUint8Array(uriToUpload)],
                      filename,
                      {
                        type: "image/png",
                      }
                    )
                    win.revokeObjectURL(uriToUpload)
                    return uploadUri(uid, file, folderId, false, true)
                  })
                })
              )
            })
            // ***********************************************************
            // then export the image only version of the slide (no text, no geometry)
            // ***********************************************************
            .then(() => {
              const iSlide = svgSlide.node.cloneNode(true)
              // remove all texts from the slide
              const texts = iSlide.querySelectorAll("text")
              Array.from(texts).forEach((node) => {
                node.parentNode.removeChild(node)
              })
              const geoms = iSlide.querySelectorAll("[id='isFromGeometry']")
              Array.from(geoms).forEach((node) => {
                node.parentNode.removeChild(node)
              })
              const mediaContents = iSlide.querySelectorAll("[id^=content_]")
              return Promise.all(
                Array.from(mediaContents).map((mediaContent) => {
                  let mSlide = iSlide.cloneNode(false)
                  mSlide.appendChild(mediaContent)
                  return new Promise((resolve, reject) =>
                    resolve(createSlideURL(mSlide, resolution))
                  ).then((uriToUpload) => {
                    let nbMedia = mediaContent.getAttribute("index")
                    const filename =
                      "media_image_slide_" +
                      svgSlide.id +
                      "_" +
                      nbMedia +
                      ".png"
                    // fill the header file
                    content["layer_image_" + nbMedia].url[svgSlide.id] =
                      filename
                    // create the media file and upload it
                    const file = new File(
                      [b64ToUint8Array(uriToUpload)],
                      filename,
                      {
                        type: "image/png",
                      }
                    )
                    win.revokeObjectURL(uriToUpload)
                    return uploadUri(uid, file, folderId, false, true)
                  })
                })
              )
            })
            // ***********************************************************
            // then export the geometry only version of the slide
            // ***********************************************************
            .then(() => {
              const gSlide = svgSlide.node.cloneNode(true)
              // remove all texts from the slide
              const texts = gSlide.querySelectorAll("text")
              Array.from(texts).forEach((node) => {
                node.parentNode.removeChild(node)
              })
              const non_geoms = gSlide.querySelectorAll(
                "image[id]:not([id='isFromGeometry'])"
              )
              Array.from(non_geoms).forEach((node) => {
                node.parentNode.removeChild(node)
              })
              const nodeList = gSlide.querySelectorAll("[id^=content_]")
              let mediaContents = []
              for (let i = 0; i < nodeList.length; i++) {
                if (
                  nodeList[i].querySelectorAll("[id=isFromGeometry]").length > 0
                ) {
                  mediaContents.push(nodeList[i])
                }
              }
              return Promise.all(
                Array.from(mediaContents).map((mediaContent) => {
                  let mSlide = gSlide.cloneNode(false)
                  mSlide.appendChild(mediaContent)
                  return new Promise((resolve, reject) =>
                    resolve(createSlideURL(mSlide, resolution))
                  ).then((uriToUpload) => {
                    let nbMedia = mediaContent.getAttribute("index")
                    const filename =
                      "media_geometry_svg_slide_" +
                      svgSlide.id +
                      "_" +
                      nbMedia +
                      ".png"
                    // fill the header file
                    content["layer_geom_" + nbMedia].url[svgSlide.id] = filename
                    // create the media file and upload it
                    const file = new File(
                      [b64ToUint8Array(uriToUpload)],
                      filename,
                      {
                        type: "image/png",
                      }
                    )
                    win.revokeObjectURL(uriToUpload)
                    return uploadUri(uid, file, folderId, false, true)
                  })
                })
              )
            })
            // ************************************************
            // then export the label only version of the slide
            // ************************************************
            .then(() => {
              const lSlide = svgSlide.node.cloneNode(true)
              // remove all images from the slide
              const images = lSlide.querySelectorAll("image")
              Array.from(images).forEach((node) => {
                node.parentNode.removeChild(node)
              })
              return createSlideURL(lSlide, resolution)
            })
            .then((uriToUpload) => {
              const filename = "labels_svg_slide_" + svgSlide.id + ".png"
              const file = new File([b64ToUint8Array(uriToUpload)], filename, {
                type: "image/png",
              })
              win.revokeObjectURL(uriToUpload)
              // fill the header file
              content["labels"].url[svgSlide.id] = filename
              return uploadUri(uid, file, folderId, false, true)
            })
        )
      })
    )
      // *******************************************************
      // then export the header JSON in the HoloDisplay format
      // ( Yes! HoloDisplay still alive !!! )
      // *******************************************************
      .then(() => {
        // Export the header.JSON descriptor in HoloDisplay format
        const file = new File(
          [
            JSON.stringify(
              {
                information: information,
                content: content,
              },
              null,
              4
            ),
          ],
          "header.json",
          {
            type: "application/json",
          }
        )
        return uploadUri(uid, file, folderId, true, false)
      })
      .then(() => {
        if (globalContext) {
          globalContext.displaySnackbar({
            message: ["Successfully exported slides !"],
          })
        }
        props.firebase.updateExportDate(props.contentId, folderId)
      })
      .catch((error) => {
        console.log("Error while exporting slides : ", error)
      })
  }

  const downloadUri = (uri, name) => {
    var a = document.createElement("a")
    a.style = "display: none"
    a.href = uri
    a.download = name
    a.click()
  }

  const initUploadToFileManager = (props) => {
    globalContext &&
      globalContext.displaySnackbar({
        loading: true,
      })
    let projectId, contentName, contentKey, folderId, deviceId, resolution
    // retrieve the FileManager folder id attached to this firebase project
    // starting from content
    const uid = props.firebase.auth.currentUser.uid
    props.firebase
      .content(props.contentId)
      .once("value")
      .then((snap) => {
        projectId = snap.val().project_id
        contentName = snap.val().name
        contentKey = snap.key
        // retrieve the parent project
        return props.firebase.project(projectId).once("value")
      })
      .then((snap) => {
        // retrieve the project folder id on FileManager
        deviceId = snap.val().device_id
        folderId = snap.val().folder_id
        // and create a new sub-folder to upload the slide files to
        return HMFileIO(uid).createFolder(contentName, folderId, {
          projectId: projectId,
          contentId: contentKey,
          contentName: contentName,
        })
      })
      .then((dir) => {
        folderId = dir.id
        // retrieve the project selected device resolution
        if (deviceId) return deviceResolution(props.firebase, deviceId)
        else return DEFAULT_EXPORT_RESOLUTION
      })
      // and then, export slides to this folder
      .then((resolution) => {
        if (!("width" in resolution) || !("height" in resolution))
          resolution = DEFAULT_EXPORT_RESOLUTION
        exportSlides(uid, folderId, resolution)
      })
  }

  const b64ToUint8Array = (b64Image) => {
    var img = atob(b64Image.split(",")[1])
    var img_buffer = []
    var i = 0
    while (i < img.length) {
      img_buffer.push(img.charCodeAt(i))
      i++
    }
    return new Uint8Array(img_buffer)
  }

  const uploadUri = (uid, fblob, folderId, hide, thumb) => {
    const metadata = hide ? { tags: ["hide"] } : {}
    const thumbnail = thumb ? DEFAULT_EXPORT_THUMBNAIL : null
    return HMFileIO(uid)
      .uploadFileXHR(fblob, folderId, false, metadata, thumbnail)
      .then((res) => {
        if (DEBUG) console.log("Uploaded slide file to FileManager : ", res)
        return res
      })
  }

  return (
    <AuthUserContext.Consumer>
      {() => (
        <div
          className="nav-link navbar-btn-action"
          data-for="rtt_navbar_export_slides"
          data-tip="Create Slideshow"
        >
          <MdPermMedia onClick={() => initUploadToFileManager(props)} />
          <ReactTooltip
            id="rtt_navbar_export_slides"
            effect="solid"
            place="bottom"
          />
        </div>
      )}
    </AuthUserContext.Consumer>
  )
}

const condition = (currentUser) => !!currentUser

export default withAuthorization(condition)(
  compose(withRouter, withFirebase)(ExportSlidesAction)
)
