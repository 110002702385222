import { getWS_URL } from "../Constants/UrlConfig"

export const HMKWebSocket = () => {
  const DEBUG = false
  let triggers = {}
  let ws = null

  const init = (userId) => {
    const wsurl = getWS_URL(userId) + "ws/viewer/" + userId + "/"
    ws = new WebSocket(wsurl)
    if (DEBUG) console.log(`Create HMKWS on url ${wsurl}`)

    ws.onopen = function (e) {
      if (DEBUG) console.log(`HMKWS opened for user ${userId}`)
      //ws.send(JSON.stringify({ type: "check_task_completion" }))
    }

    ws.onmessage = function (e) {
      var data = JSON.parse(e.data)
      if (DEBUG) {
        console.log("HMKWS RECEIVE FROM WS MSG :")
        console.log(data)
      }

      if (data.type in triggers) {
        triggers[data.type](data.params)
      }
      if (data.type === "CLOSE") {
        ws.close()
        ws = null
        triggers = {}
      }
    }

    ws.onclose = function (e) {
      if (DEBUG) console.log(`CLOSE HMKWS for user ${userId}`)
      triggers = {}
    }
  }

  const waitForConnection = (callback, interval) => {
    if (ws.readyState === 1) callback()
    else setTimeout(() => waitForConnection(callback, interval), interval)
  }

  const sendMsg = (type, msg) => {
    waitForConnection(
      () => ws.send(JSON.stringify({ type: type, params: msg })),
      1000
    )
  }

  const on = (command, doHandle) => {
    triggers[command] = doHandle
    if (DEBUG) console.log(`HMKWS Triggers: `, triggers)
  }

  return { init, sendMsg, on }
}
