import React, { useEffect, useRef, useState } from "react"
import { FiPlay, FiTrash2, FiCopy } from "react-icons/fi"
import { TiPencil } from "react-icons/ti"
import ReactTooltip from "react-tooltip"

const DropDown = ({ devices, clickCallback, hide, style }) => {
  const dropdown = useRef(null)
  useEffect(() => {
    if (dropdown && dropdown.current) dropdown.current.focus()
  }, [dropdown])

  return (
    <ul
      ref={dropdown}
      className="card-action-dropdown dropdown-menu"
      style={{ ...style }}
      tabIndex="0"
      onBlur={hide}
    >
      {devices.map((dev) => (
        <li key={dev.key}>
          <div
            onClick={(e) => {
              e.stopPropagation()
              clickCallback(dev.key)
            }}
          >
            {dev.name}
          </div>
        </li>
      ))}
    </ul>
  )
}

const InteractiveActionIcons = ({
  card,
  defaultDevice,
  onProject,
  devices,
  onEditContent,
  onCopyContent,
  onDeleteContent,
}) => {
  const [showDropDown, setShowDropDown] = useState(false)
  const projectTooltip = devices ? "Project " : "Launch Viewer "
  const isMonitor = card.type === "MONITOR" && !("online" in card)
  return (
    <div className="interactive-action-icons">
      {card &&
        card.id &&
        defaultDevice &&
        defaultDevice.name &&
        defaultDevice.key &&
        onProject && (
          <button
            className="home-icon"
            value={card.id}
            onClick={(e) => {
              e.stopPropagation()
              onProject({
                card_id: card.id,
                device_id: defaultDevice.key,
              })
            }}
            data-for={`rtt_project_${card.id}`}
            data-tip={`${projectTooltip} On ${defaultDevice.name}`}
          >
            <FiPlay />
            <ReactTooltip
              id={`rtt_project_${card.id}`}
              effect="solid"
              place="top"
            />
          </button>
        )}
      {card && card.id && devices && (
        <>
          <button
            className="home-icon"
            value={card.id}
            onClick={(e) => {
              e.stopPropagation()
              setShowDropDown(true)
            }}
            data-for={`rtt_project_${card.id}`}
            data-tip={`Project On...`}
          >
            <svg width="1em" height="1em">
              <use href="play_more.svg#svg4"></use>
            </svg>
            <ReactTooltip
              id={`rtt_project_${card.id}`}
              effect="solid"
              place="top"
            />
          </button>
          <div style={{ position: "absolute" }}>
            {card && card.id && showDropDown && (
              <DropDown
                devices={devices}
                clickCallback={(device_key) => {
                  onProject({
                    card_id: card.id,
                    device_id: device_key,
                  })
                  setShowDropDown(false)
                }}
                hide={() => setShowDropDown(false)}
                style={{ marginTop: 10, marginLeft: 40 }}
              />
            )}
          </div>
        </>
      )}
      {}
      {onEditContent && (
        <button
          className="home-icon"
          value={card.id}
          onClick={(e) => {
            e.stopPropagation()
            onEditContent(e)
          }}
          data-for={`rtt_content_edit_params_${card.id}`}
          data-tip="Edit"
        >
          <TiPencil />
          <ReactTooltip
            id={`rtt_content_edit_params_${card.id}`}
            effect="solid"
            place="top"
          />
        </button>
      )}
      {onCopyContent && (
        <button
          className="home-icon"
          value={card.id}
          onClick={(e) => {
            e.stopPropagation()
            onCopyContent(e)
          }}
          data-for={`rtt_content_copy_${card.id}}`}
          data-tip="Duplicate"
        >
          <FiCopy />
          <ReactTooltip
            id={`rtt_content_copy_${card.id}}`}
            effect="solid"
            place="top"
          />
        </button>
      )}
      {onDeleteContent && (
        <button
          className="home-icon"
          value={card.id}
          id={card.name}
          onClick={(e) => {
            e.stopPropagation()
            onDeleteContent(e)
          }}
          data-for={`rtt_content_delete_${card.id}`}
          data-tip="Delete"
        >
          <FiTrash2 />
          <ReactTooltip
            id={`rtt_content_delete_${card.id}`}
            effect="solid"
            place="top"
          />
        </button>
      )}
    </div>
  )
}

export default InteractiveActionIcons
