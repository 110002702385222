import React from "react"
import { compose } from "recompose"
import ReactTooltip from "react-tooltip"
import { withRouter } from "react-router-dom"
import { FiPlay, FiDownload } from "react-icons/fi"
import { AuthUserContext } from "../Authentication/Session/Session"
import { withFirebase } from "../Authentication/Firebase"
import { withAuthorization } from "../Authentication/Session/Session"
import * as ROUTES from "../Constants/Routes"
import Snackbar from "../Snackbar/Snackbar"
import CloseButton from "../Common/CloseButton"
import CheckBoxBtn from "../Common/CheckBoxBtn"
import { extractDevicesData } from "./DeviceMgr"
import { openViewer } from "../Common/HMKViewer"
import {
  processExifData,
  buildDeviceJson,
  getPoseFromDeviceJson,
  updatePoseFromDeviceJson,
  updateCameraFromDeviceJson,
  fetchCameras,
  fetchPoseTemplates,
  downloadPoseTemplate,
} from "./CameraUtils"
import EXIF from "exif-js"

const CalibrationPattern = () => (
  <div className="form-group d-inline-flex col-1">
    <div className="card-action pl-2">
      <FiPlay
        data-for="rtt_navbar_viewer"
        data-tip="Calibration Pattern"
        onClick={() =>
          openViewer(
            "?noSplashImage=true&noCapture=true&realSize=true&mode=CALIBRATION&noKeyInput=true"
          )
        }
      />
      <ReactTooltip id="rtt_navbar_viewer" effect="solid" place="bottom" />
    </div>
  </div>
)

const Device = (props) => (
  <>
    <div className="container-wrapper">
      <div className="container">
        <div className="jumbotron text-center">
          <CloseButton linkTo={ROUTES.DEVICES} />
          <h2 className="text-center">
            {(props.creation ? "Add " : "Edit ") + props.location.category}
          </h2>
          <UserDevice {...props} />
        </div>
      </div>
    </div>
  </>
)

class AddDevice extends React.Component {
  constructor(props) {
    super(props)
    this.fileInput = React.createRef()
    this.state = {
      poseModes: [],
      poseSetups: {},
      cameras: [],
      camerasParams: {},
      group_id: "",
      name: "",
      type: this.props.location.category,
      reference: "",
      installation_site: "",
      comment: "",
      brand: "",
      model: "",
      reference_diagonal: "",
      physical_width: "",
      physical_height: "",
      resolution_x: "",
      resolution_y: "",
      capture_device_id: "",
      camera_rotate_180: "",
      camera_realsize: "",
      device_json: "",
      device_pose: "",
      device_camera: "",
      camera_name: "",
      viewer_args: "",
    }
  }

  onChange = (event) => {
    if (event.target.type === "checkbox") {
      this.setState({
        [event.target.name]: event.target.checked,
      })
    } else {
      this.setState({
        [event.target.name]: event.target.value,
      })
      // reinit custom validity of device_json field
      if (event.target.name === "device_json")
        event.target.setCustomValidity("")
      // update json field if device_pose field changed
      if (event.target.name === "device_pose") {
        this.setState({
          device_json: updatePoseFromDeviceJson(
            this.state.device_json,
            event.target.value,
            this.state.poseSetups
          ),
        })
        if (this.state.camera_name)
          this.setState({
            name: `${this.state.camera_name} ${event.target.value}`,
          })
      } else if (event.target.name === "device_camera") {
        if (event.target.value === "Predefined") {
          return
        } else if (event.target.value === "Use Picture Taken From The Camera") {
          this.fileInput.current.click()
        } else {
          const params = this.state.camerasParams[event.target.value]
          const viewer_args = params.viewer_args
          delete params.viewer_args
          this.setState({
            name: `${event.target.value} ${this.state.device_pose}`,
            camera_name: event.target.value,
            brand: params.brand,
            model: params.model,
            capture_device_id: params.default_capture_device_index,
            camera_rotate_180: params.default_video_rotate_180,
            camera_realsize: params.default_real_size,
            viewer_args: viewer_args,
            device_json: updateCameraFromDeviceJson(
              this.state.device_json,
              event.target.value,
              this.state.camerasParams
            ),
          })
        }
      }
    }
  }

  checkJson = (device_json) => {
    if (device_json.length > 0) {
      const elt = document.getElementById("device_json")
      try {
        JSON.parse(device_json)
        elt.setCustomValidity("")
      } catch (error) {
        console.log("JSON validation error : ", error)
        elt.setCustomValidity("Invalide JSON format!")
        elt.reportValidity()
        return false
      }
    }
    return true
  }

  handleSubmit = (event) => {
    const {
      group_id,
      name,
      type,
      reference,
      installation_site,
      comment,
      brand,
      model,
      physical_width,
      physical_height,
      resolution_x,
      resolution_y,
      capture_device_id,
      camera_rotate_180,
      camera_realsize,
      viewer_args,
    } = this.state
    // check json format
    const currentUser = this.props.firebase.auth.currentUser
    let device_json = this.state.device_json || "{}"
    if (!this.checkJson(device_json) || !currentUser) {
      event.preventDefault()
      return
    }
    if (this.props.creation) {
      const deviceKey = this.props.firebase.device().push().key
      this.props.firebase.device(deviceKey).set({
        name: name,
        type: type,
        reference: reference,
        installation_site: installation_site,
        comment: comment,
        user_id: currentUser.uid,
        group_id: group_id,
        viewer_args: viewer_args,
      })
      if (type === "MONITOR") {
        this.props.firebase.screen(deviceKey).set({
          brand: brand,
          model: model,
          physical_width: physical_width,
          physical_height: physical_height,
          resolution_x: resolution_x,
          resolution_y: resolution_y,
          device_json: device_json,
        })
      } else if (type === "CAMERA") {
        this.props.firebase.camera(deviceKey).set({
          brand: brand,
          model: model,
          capture_device_index: capture_device_id,
          camera_rotate_180: camera_rotate_180,
          camera_realsize: camera_realsize,
          device_json: device_json,
        })
      } else if (type === "PROJECTOR") {
        this.props.firebase.projector(deviceKey).set({
          brand: brand,
          model: model,
          device_json: device_json,
        })
      }
    } else {
      // edition mode
      const deviceId = this.props.match.params.id
      this.props.firebase.device(deviceId).update({
        name: name,
        type: type,
        reference: reference,
        installation_site: installation_site,
        comment: comment,
        viewer_args: viewer_args,
      })
      if (type === "MONITOR") {
        this.props.firebase.screen(deviceId).update({
          brand: brand,
          model: model,
          physical_width: physical_width,
          physical_height: physical_height,
          resolution_x: resolution_x,
          resolution_y: resolution_y,
          device_json: device_json,
        })
      } else if (type === "CAMERA") {
        this.props.firebase.camera(deviceId).update({
          brand: brand,
          model: model,
          capture_device_index: capture_device_id,
          camera_rotate_180: camera_rotate_180,
          camera_realsize: camera_realsize,
          device_json: device_json,
        })
      } else if (type === "PROJECTOR") {
        this.props.firebase.projector(deviceId).update({
          brand: brand,
          model: model,
          device_json: device_json,
        })
      }
    }
    extractDevicesData(
      currentUser.uid,
      this.props.firebase,
      this.props.filterByGroup ? group_id : currentUser.uid,
      this.props.filterByGroup
    )
    const snackbarComponent = document.getElementById("snackbar")
    snackbarComponent.className = "show"
    const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms))
    wait(1000).then(() => this.props.history.push(ROUTES.DEVICES))
    event.preventDefault()
  }

  componentDidMount() {
    if (!this.props.location.category) {
      this.props.history.push(ROUTES.DEVICES)
      return
    }
    window.scrollTo(0, 0)
    const uid = this.props.firebase.auth.currentUser.uid
    const type = this.props.location.category
    const scr_width = window.screen.width
    const scr_height = window.screen.height
    this.setState({
      resolution_x: scr_width,
      resolution_y: scr_height,
    })
    fetchCameras(uid)
      .then((result) => {
        const [cameras, parameters] = result
        if (cameras && cameras.length > 0)
          this.setState({
            cameras: cameras,
            camerasParams: parameters,
            device_camera: cameras[0],
          })
      })
      .then(
        fetchPoseTemplates(uid).then((result) => {
          const [poseModes, poseSetups] = result
          if (poseModes && poseModes.length > 0)
            this.setState({
              poseModes: poseModes,
              poseSetups: poseSetups,
              device_pose: poseModes[0],
            })
          // load edited device data from database
          if (!this.props.creation) {
            const deviceId = this.props.match.params.id
            // TODO this part could be factorized as something like
            // this.setState(snap.val())
            this.props.firebase
              .device(deviceId)
              .once("value")
              .then((snap) => {
                this.setState({
                  name: snap.val().name,
                  type: snap.val().type,
                  reference: snap.val().reference,
                  installation_site: snap.val().installation_site,
                  comment: snap.val().comment,
                  viewer_args: snap.val().viewer_args || "",
                })
              })
            if (type === "MONITOR") {
              this.props.firebase
                .screen(deviceId)
                .once("value")
                .then((snap) => {
                  this.setState({
                    brand: snap.val().brand,
                    model: snap.val().model,
                    physical_width: snap.val().physical_width,
                    physical_height: snap.val().physical_height,
                    resolution_x: snap.val().resolution_x,
                    resolution_y: snap.val().resolution_y,
                    device_json: snap.val().device_json,
                  })
                })
            } else if (type === "CAMERA") {
              this.props.firebase
                .camera(deviceId)
                .once("value")
                .then((snap) => {
                  this.setState({
                    brand: snap.val().brand,
                    model: snap.val().model,
                    capture_device_id: snap.val().capture_device_index,
                    camera_rotate_180: snap.val().camera_rotate_180
                      ? true
                      : false,
                    camera_realsize: snap.val().camera_realsize ? true : false,
                    device_json: snap.val().device_json,
                    device_pose: getPoseFromDeviceJson(snap.val().device_json),
                  })
                })
            } else if (type === "PROJECTOR") {
              this.props.firebase
                .projector(deviceId)
                .once("value")
                .then((snap) => {
                  this.setState({
                    brand: snap.val().brand,
                    model: snap.val().model,
                    device_json: snap.val().device_json,
                  })
                })
            }
          }
        })
      )

    // load user group
    this.props.firebase
      .groups(this.props.firebase.auth.currentUser.uid)
      .once("value", (snap) => {
        if (snap.val()) {
          this.setState({ group_id: snap.val()[0] })
        }
      })
  }

  handleExifFile = (e) => {
    const thisDevice = this
    if (!e.target.files) return
    EXIF.getData(e.target.files[0], function () {
      const exifTags = EXIF.getAllTags(this)
      if (exifTags && "ExifVersion" in exifTags) {
        const exif = processExifData(exifTags)
        thisDevice.setState({
          name: `${exif[1].name} ${thisDevice.state.device_pose}`,
          camera_name: exif[1].name,
          brand: exif[0].Make,
          model: exif[0].Model,
          capture_device_id: -9,
          device_json: JSON.stringify(
            buildDeviceJson(
              exif,
              thisDevice.state.device_pose,
              thisDevice.state.poseSetups
            ),
            null,
            "\t"
          ),
        })
      } else {
        window.alert("No EXIF data in this file.")
      }
    })
  }

  render() {
    const catLabel =
      this.state.type &&
      this.state.type[0] + this.state.type.slice(1).toLowerCase()
    const dlPattern =
      this.state.type === "CAMERA" &&
      this.state.poseSetups[this.state.device_pose] &&
      this.state.poseSetups[this.state.device_pose].estimation_mode ===
        "IMAGE_APRILTAG_FREE"
    return (
      <div>
        <AuthUserContext.Consumer>
          {() => (
            <div>
              <form className="userForm" onSubmit={this.handleSubmit}>
                {this.state.type === "CAMERA" && (
                  <div className="form-row">
                    <div className="form-group offset-lg-3 col-lg-6">
                      <select
                        //defaultValue={itemPos.position}
                        className="form-control"
                        name="device_camera"
                        value={this.state.device_camera}
                        onChange={this.onChange}
                      >
                        {this.state.cameras.map((pos) => {
                          return (
                            <option value={pos} key={pos}>
                              {pos}
                            </option>
                          )
                        })}
                      </select>
                      <input
                        className="form-control"
                        type="file"
                        accept="image/jpeg"
                        id="inputfile"
                        style={{ display: "none" }}
                        onChange={this.handleExifFile}
                        onClick={
                          this.fileInput.current
                            ? (this.fileInput.current.value = null)
                            : null
                        }
                        ref={this.fileInput}
                      />
                    </div>
                  </div>
                )}
                <div className="form-row">
                  <div className="form-group offset-lg-3 col-lg-6">
                    <label className="col-form-label">Name*</label>
                    <input
                      value={this.state.name}
                      type="text"
                      className="form-control"
                      name="name"
                      onChange={this.onChange}
                      required
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group offset-lg-3 col-lg-6">
                    <label className="col-form-label">Reference</label>
                    <input
                      value={this.state.reference}
                      type="text"
                      className="form-control"
                      name="reference"
                      onChange={this.onChange}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group offset-lg-3 col-lg-6">
                    <label className="col-form-label">Installation Site</label>
                    <input
                      value={this.state.installation_site}
                      type="text"
                      className="form-control"
                      name="installation_site"
                      onChange={this.onChange}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group offset-lg-3 col-lg-6">
                    <label className="col-form-label">Comment</label>
                    <input
                      value={this.state.comment}
                      type="text"
                      className="form-control"
                      name="comment"
                      onChange={this.onChange}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group offset-lg-3 col-lg-6">
                    <label className="col-form-label">
                      {catLabel + " Brand"}
                    </label>
                    <input
                      value={this.state.brand}
                      type="text"
                      className="form-control"
                      name="brand"
                      onChange={this.onChange}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group offset-lg-3 col-lg-6">
                    <label className="col-form-label">
                      {catLabel + " Model"}
                    </label>
                    <input
                      value={this.state.model}
                      type="text"
                      className="form-control"
                      name="model"
                      onChange={this.onChange}
                    />
                  </div>
                </div>
                {this.state.type === "MONITOR" ? (
                  <>
                    <div className="form-row">
                      <div className="form-group offset-lg-3 col-lg-6">
                        <label className="col-form-label">
                          Horizontal Resolution (pixels)*
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          name="resolution_x"
                          value={this.state.resolution_x}
                          onChange={this.onChange}
                          min="320"
                          required
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group offset-lg-3 col-lg-6">
                        <label className="col-form-label">
                          Vertical Resolution (pixels)*
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          name="resolution_y"
                          value={this.state.resolution_y}
                          onChange={this.onChange}
                          min="200"
                          required
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group offset-lg-3 col-lg-6">
                        <label className="col-form-label">
                          Real Width For 1000 Pixels (mm)*
                        </label>
                        <input
                          value={this.state.physical_width}
                          type="number"
                          className="form-control"
                          name="physical_width"
                          onChange={this.onChange}
                          min="0"
                          step="0.1"
                          required
                        />
                      </div>
                      <CalibrationPattern />
                    </div>
                    <div className="form-row">
                      <div className="form-group offset-lg-3 col-lg-6">
                        <label className="col-form-label">
                          Real Height For 1000 Pixels (mm)*
                        </label>
                        <input
                          value={this.state.physical_height}
                          type="number"
                          className="form-control"
                          name="physical_height"
                          onChange={this.onChange}
                          min="0"
                          step="0.1"
                          required
                        />
                      </div>
                      <CalibrationPattern />
                    </div>
                  </>
                ) : this.state.type === "CAMERA" ? (
                  <>
                    <div className="form-row">
                      <div className="form-group offset-lg-3 col-lg-6">
                        <label className="col-form-label">
                          Capture Device Index*
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          name="capture_device_id"
                          value={this.state.capture_device_id}
                          onChange={this.onChange}
                          min="-9"
                          max="9"
                          required
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group offset-lg-3 col-lg-6">
                        <label className="col-form-label">
                          Video Rotate 180
                        </label>
                        <CheckBoxBtn
                          id="camera_rotate_180_checkbox"
                          name="camera_rotate_180"
                          checked={this.state.camera_rotate_180}
                          onChange={this.onChange}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group offset-lg-3 col-lg-6">
                        <label className="col-form-label">Real Size</label>
                        <CheckBoxBtn
                          id="camera_realsize_checkbox"
                          name="camera_realsize"
                          checked={this.state.camera_realsize}
                          onChange={this.onChange}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group offset-lg-3 col-lg-6">
                        <label className="col-form-label">Pose Mode</label>
                        <div className="d-flex">
                          <select
                            //defaultValue={itemPos.position}
                            className="form-control"
                            name="device_pose"
                            value={this.state.device_pose}
                            onChange={this.onChange}
                          >
                            {this.state.poseModes.map((pos) => {
                              return (
                                <option value={pos} key={pos}>
                                  {pos}
                                </option>
                              )
                            })}
                          </select>
                          {dlPattern && (
                            <>
                              <FiDownload
                                className="form-control col-1"
                                size="1.5rem"
                                data-tip="Download Pose Pattern"
                                data-for="rtt_pose_template_dl"
                                onClick={() =>
                                  downloadPoseTemplate(
                                    this.props.firebase.auth.currentUser.uid,
                                    this.state.device_pose
                                  )
                                }
                                style={{ cursor: "pointer" }}
                              />
                              <ReactTooltip
                                id="rtt_pose_template_dl"
                                effect="solid"
                                place="bottom"
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (<></>)}
                <div className="form-row">
                  <div className="form-group offset-lg-3 col-lg-6">
                    <label className="col-form-label">Parameters{this.state.type !== "MONITOR" ? "*" : ""}</label>
                    <textarea
                      rows={10}
                      className="form-control"
                      id="device_json"
                      name="device_json"
                      value={this.state.device_json}
                      onChange={this.onChange}
                      required={this.state.type !== "MONITOR"}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group offset-lg-3 col-lg-6">
                    <label className="col-form-label">Viewer Parameters</label>
                    <input
                      value={this.state.viewer_args}
                      type="text"
                      className="form-control"
                      name="viewer_args"
                      onChange={this.onChange}
                    />
                  </div>
                </div>
                <button className="hmk-form-btn btn" type="submit">
                  Ok
                </button>
              </form>
              <Snackbar
                message={
                  this.props.creation
                    ? "The device has been created"
                    : "The device has been updated"
                }
              />
            </div>
          )}
        </AuthUserContext.Consumer>
      </div>
    )
  }
}

const UserDevice = compose(withRouter, withFirebase)(AddDevice)

const condition = (currentUser) => !!currentUser

export default withAuthorization(condition)(Device)
