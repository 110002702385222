import React, { useEffect, useState, useLayoutEffect, useMemo } from "react"
import { FiFile, FiPlus, FiTrash2, FiList, FiGrid } from "react-icons/fi"
import { TiPencil } from "react-icons/ti"
import ReactTooltip from "react-tooltip"
import ContentCard, { ContentActionCard } from "../Components/ContentCard"
import { useCookies } from "react-cookie"

const DeviceDropDown = ({ current, monitors, onChangeDevice }) => {
  const onChange = (choice) => {
    onChangeDevice(choice)
  }

  return (
    <div className="home-icon dropdown">
      <button
        className="home-icon dropdown-toggle"
        id="navbarDropdown"
        data-toggle="dropdown"
        data-for="rtt_project_device_btn"
        data-tip="Select Device"
      >
        {current ? current.name : "Select device"}
      </button>
      <div className="home-icon dropdown-menu" aria-labelledby="navbarDropdown">
        {monitors &&
          monitors.map((monitor, i) => (
            <span
              className="home-icon dropdown-item"
              key={i}
              onClick={() => onChange(monitor)}
            >
              {monitor.name}
            </span>
          ))}
      </div>
      {/**
      <ReactTooltip id="rtt_project_device_btn" effect="solid" place="bottom" />
       */}
    </div>
  )
}

const ProjectCard = (props) => {
  const {
    userRole,
    userId,
    project,
    contents,
    devices,
    onProject,
    onCopyContent,
    onRenameProject,
    onAddContent,
    onDeleteProject,
    onExportCSV,
    onEditContent,
    onCardClick,
    onDeleteContent,
    onEditProjectFiles,
    onChangeDevice,
  } = props

  const [currentDevice, setCurrentDevice] = useState(null)
  const [cookies, setCookies] = useCookies([
    `layout_dir_${project.key}`,
    "layout_dir_global",
  ])

  const useGlobalLayoutDirection = useMemo(() => {
    return (
      cookies["layout_dir_global"] === "row" ||
      cookies["layout_dir_global"] === "column"
    )
  }, [cookies])

  const layoutDirection = useMemo(() => {
    let direction = "row"
    if (useGlobalLayoutDirection) {
      direction = cookies["layout_dir_global"]
    } else if (
      cookies[`layout_dir_${project.key}`] === "row" ||
      cookies[`layout_dir_${project.key}`] === "column"
    ) {
      direction = cookies[`layout_dir_${project.key}`]
    }
    return direction
  }, [cookies, useGlobalLayoutDirection, project.key])

  useEffect(() => {
    if (devices && "device_id" in project) {
      const crntDevice = devices.find(
        (monitor) => monitor.key === project.device_id
      )
      setCurrentDevice(crntDevice)
    }
  }, [devices, project])

  useLayoutEffect(() => {
    // try to position the page to a project element
    // this.props.location.state contains a projectId in some case
    if (props.location) {
      const eltClass = ".restore-" + props.location.state
      const item = document.querySelector(eltClass)
      if (item) {
        item.scrollIntoView()
        window.scrollBy(0, -100)
        props.history.replace({ state: null })
      }
    }
  })

  const onCardAction = (card) => {
    userRole === "admin"
      ? onCardClick(card.id)
      : onProject({
          card_id: card.id,
          device_id: currentDevice.key,
        })
  }

  return (
    <div>
      <nav className={`navbar mt-4 restore-${project.key}`}>
        <div>
          <div
            className="project-name"
            value={project.key}
            //onClick={onRenameProject}
          >
            {project.name}
          </div>
          {userRole === "admin" && (
            <button
              className="home-icon"
              value={project.key}
              onClick={onRenameProject}
              data-for="rtt_project_rename_btn"
              data-tip="Edit"
            >
              <TiPencil size="20px" />
              <ReactTooltip
                id="rtt_project_rename_btn"
                effect="solid"
                place="bottom"
              />
            </button>
          )}
          {userRole === "admin" && (
            <button
              className="home-icon"
              onClick={() => onEditProjectFiles(project.key)}
              data-for="rtt_project_files"
              data-tip="Files"
            >
              <FiFile size="20px" />
              <ReactTooltip
                id="rtt_project_files"
                effect="solid"
                place="bottom"
              />
            </button>
          )}
          {userRole === "admin" && (
            <button
              className="home-icon"
              value={project.key}
              onClick={() => onAddContent(project.key)}
              data-for="rtt_project_add_content"
              data-tip="Add Work Instruction"
            >
              <FiPlus size="25px" />
              <ReactTooltip
                id="rtt_project_add_content"
                effect="solid"
                place="bottom"
              />
            </button>
          )}
          {userRole === "admin" && (
            <>
              {useGlobalLayoutDirection ? (
                <></>
              ) : layoutDirection === "row" ? (
                <button
                  className="home-icon"
                  onClick={() =>
                    setCookies(`layout_dir_${project.key}`, "column")
                  }
                  data-for={`layout_dir_${project.key}_col"`}
                  data-tip="List Layout"
                >
                  <FiList size="25px" />
                  <ReactTooltip
                    id={`layout_dir_${project.key}_col"`}
                    effect="solid"
                    place="bottom"
                  />
                </button>
              ) : (
                <button
                  className="home-icon"
                  onClick={() => setCookies(`layout_dir_${project.key}`, "row")}
                  data-for={`layout_dir_${project.key}_row"`}
                  data-tip="Grid Layout"
                >
                  <FiGrid size="25px" />
                  <ReactTooltip
                    id={`layout_dir_${project.key}_row"`}
                    effect="solid"
                    place="bottom"
                  />
                </button>
              )}
            </>
          )}
          <span className="project-device-label">Device :</span>
          <DeviceDropDown
            current={currentDevice}
            monitors={devices}
            onChangeDevice={(monitor) => {
              onChangeDevice(project, monitor)
              setCurrentDevice(monitor)
            }}
          />
        </div>
        {userRole === "admin" && (
          <div>
            <button
              className="home-icon"
              value={project.key}
              id={project.name}
              onClick={onDeleteProject}
              data-for="rtt_project_delete"
              data-tip="Delete"
            >
              <FiTrash2 size="20px" />
              <ReactTooltip
                id="rtt_project_delete"
                effect="solid"
                place="bottom"
              />
            </button>
          </div>
        )}
      </nav>
      <div className="container">
        <div className={layoutDirection}>
          {userRole === "admin" && layoutDirection === "row" ? (
            <>
              <ContentActionCard
                id={"card-file-manager"}
                direction={layoutDirection}
                caption="Files"
                onClick={() => onEditProjectFiles(project.key)}
              />
              <ContentActionCard
                id={"card-add-content"}
                direction={layoutDirection}
                caption="Add Work Instruction"
                onClick={() => onAddContent(project.key)}
              />
            </>
          ) : (
            <></>
          )}
          {contents
            .filter(
              (content) =>
                content.project_id === project.key && content.name !== undefined
            )
            .map((card) => (
              <ContentCard
                direction={layoutDirection}
                userRole={userRole}
                uid={userId}
                card={card}
                defaultDevice={currentDevice}
                devices={devices}
                onProject={onProject}
                onCopyContent={onCopyContent}
                onExportCSV={onExportCSV}
                onEditContent={onEditContent}
                onDeleteContent={onDeleteContent}
                onCardClick={onCardAction}
                key={card.id}
              />
            ))}
        </div>
      </div>
    </div>
  )
}

export default ProjectCard
