import { CLOG_TOKEN } from "./UrlConfig"

export const LANDING = "/"
export const HOME = "/home"
export const FORGOT_PASSWORD = "/reset-password"
export const PROFILE = "/profile"
export const DEVICE = "/device"
export const EDIT_DEVICE = "/edit_device/:id"
export const DEVICES = "/devices"
export const PROJECT = "/project"
export const CONTENT = "/content/:id"
export const LEGAL_NOTICES = "/legal_notices"
export const RGPD = "/rgpd"
export const CGVU = "/cgvu"
export const METRICS = "/metrics"
export const VERIFICATION = "/verification"
export const EDIT_PROJECT = "/edit_project/:id"
export const EDIT_CONTENT = "/edit_content/:id"
export const EDIT_SLIDES = "/edit_slides/:id"
export const VIEWER_MANAGER = "/viewer_manager"
export const getHomeRoute = (userId) => userId === CLOG_TOKEN ? VIEWER_MANAGER : HOME
