import React, { useContext, useEffect, useState, useMemo, useRef } from "react"
import { GlobalContext } from "../Store/ContentStore"
import { FiList, FiGrid } from "react-icons/fi"
import Footer from "../Common/Footer/Footer"
import ContentCard from "../Components/ContentCard"
import { withAuthorization } from "../Authentication/Session/Session"
import {
  DEVICE_TYPES,
  DEVICES_INITIAL_STATE,
  DeviceTypeIcons,
  deviceListByType,
} from "../Device/DeviceMgr.js"
import Modal from "../Modals/Modal"
import { useCookies } from "react-cookie"
import ReactTooltip from "react-tooltip"

const DEBUG = false

const ViewerManager = (props) => {
  const { webSocket, viewerList, authContext, displaySnackbar, globalRefresh } =
    useContext(GlobalContext)
  const [modalState, setModalState] = useState(null)
  const [devices, setDevices] = useState(DEVICES_INITIAL_STATE)
  const [filteredDeviceList, setFilteredDeviceList] = useState([])
  const [cookies, setCookies] = useCookies([
    "layout_dir_vm",
    "layout_dir_global",
  ])
  const interactingDevices = useRef([])

  const hasBinPackingModule = () => {
    return (
      authContext &&
      authContext.modules &&
      authContext.modules.find((module) => module.name === "binpacking")
    )
  }

  const useGlobalLayoutDirection = useMemo(() => {
    return (
      cookies["layout_dir_global"] === "row" ||
      cookies["layout_dir_global"] === "column"
    )
  }, [cookies])

  const layoutDirection = useMemo(() => {
    let direction = "row"
    if (useGlobalLayoutDirection) {
      direction = cookies["layout_dir_global"]
    } else if (
      cookies["layout_dir_vm"] === "row" ||
      cookies["layout_dir_vm"] === "column"
    ) {
      direction = cookies["layout_dir_vm"]
    }
    return direction
  }, [cookies, useGlobalLayoutDirection])

  useEffect(() => {
    if (webSocket && authContext.currentUser) {
      webSocket.sendMsg("start_viewer_mgr", null)
      const interval = setInterval(() => {
        webSocket.sendMsg("start_viewer_mgr", null)
      }, 3000)
      return () => {
        clearInterval(interval)
      }
    }
  }, [webSocket, authContext])

  useEffect(() => {
    if (!authContext.uid || !authContext.userGroups.length) return
    deviceListByType(
      props.firebase,
      props.filterByGroup ? authContext.userGroups[0] : authContext.uid,
      props.filterByGroup
    ).then((allDevices) => setDevices(allDevices))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.filterByGroup, authContext.userGroups, authContext.uid])

  useEffect(() => {
    let _devices = []
    for (let i = 0; i < DEVICE_TYPES.length; i++) {
      const cat = DEVICE_TYPES[i]
      for (let j = 0; j < devices[cat].length; j++) {
        const viewerInstance = viewerList.find(
          (v) => v.deviceID === devices[cat][j].key
        )
        const online = Boolean(viewerInstance)
        let current_device = {
          ...devices[cat][j],
          deviceID: devices[cat][j].key,
          online: online,
        }
        if (viewerInstance) {
          current_device.folderID = viewerInstance.folderID
        }
        _devices.push(current_device)
      }
      _devices.sort((x, y) => Number(y.online) - Number(x.online))
    }
    setFilteredDeviceList(_devices)
  }, [devices, viewerList])

  const sendWebSocketMsg = (
    device,
    message,
    timeout = 500,
    callFromModal = false
  ) => {
    const deviceID = device.key
    if (!callFromModal && message === "shutdown") {
      setModalState({
        display: true,
        title: `Shutdown Device ${device.name ? device.name : ""}`,
        message: "Are you sure you want to SHUTDOWN this device ?",
        onCancel: () => setModalState(false),
        onSubmit: () => {
          sendWebSocketMsg(device, message, timeout, true)
        },
      })
      return
    }
    if (callFromModal) setModalState(false)
    webSocket.sendMsg(message, {
      deviceID: deviceID,
    })
    if (!interactingDevices.current.includes(deviceID)) {
      interactingDevices.current.push(deviceID)
    }
    globalRefresh()
    setTimeout(() => {
      if (interactingDevices.current.includes(deviceID)) {
        interactingDevices.current = interactingDevices.current.filter(
          (dID) => dID !== deviceID
        )
      }
      displaySnackbar({
        message: "Successfully sent " + message,
      })
      globalRefresh()
    }, timeout)
  }

  return (
    <>
      <div className="container-wrapper">
        <div className="container">
          <nav className="navbar mt-4">
            <div className="project-name">
              <span>VIEWER MANAGER</span>
              {useGlobalLayoutDirection ? (
                <></>
              ) : layoutDirection === "column" ? (
                <button
                  className="home-icon"
                  onClick={() => {
                    setCookies("layout_dir_vm", "row")
                  }}
                  data-for="rtt_vm_layout_direction_row"
                  data-tip="Grid Layout"
                >
                  <FiGrid size="25px" />
                  <ReactTooltip
                    id="rtt_vm_layout_direction_row"
                    effect="solid"
                    place="bottom"
                  />
                </button>
              ) : (
                <button
                  className="home-icon"
                  onClick={() => {
                    setCookies("layout_dir_vm", "column")
                  }}
                  data-for="rtt_vm_layout_direction_col"
                  data-tip="List Layout"
                >
                  <FiList size="25px" />
                  <ReactTooltip
                    id="rtt_vm_layout_direction_col"
                    effect="solid"
                    place="bottom"
                  />
                </button>
              )}
            </div>
          </nav>
          <div className="container">
            <div className={layoutDirection}>
              {filteredDeviceList.map((device) => {
                const processing = interactingDevices.current.includes(
                  device.deviceID
                )
                let deviceActions = []
                if (device.type === "CAMERA")
                  deviceActions = [
                    { name: "SNAP", message: "get_snap", timeout: 500 },
                  ]
                if (device.type === "MONITOR" && hasBinPackingModule)
                  deviceActions = [
                    { name: "REBOOT", message: "reboot", timeout: 5000 },
                    { name: "SHUTDOWN", message: "shutdown", timeout: 5000 },
                    { name: "TEST", message: "test", timeout: 2000 },
                  ]
                return (
                  <ContentCard
                    direction={layoutDirection}
                    key={device.key}
                    userRole={authContext.role}
                    uid={authContext.uid}
                    card={{
                      ...device,
                      deviceActions: deviceActions,
                      name: `${device.name}`,
                      description: `${device.online ? "ONLINE" : "OFFLINE"}`,
                    }}
                    icon={
                      <DeviceTypeIcons
                        type={device.type}
                        sizeInPixels={layoutDirection === "row" ? 100 : 40}
                        color={
                          processing
                            ? "#169FFF"
                            : device.online
                            ? "#4EC9B0"
                            : "#FF6464"
                        }
                      />
                    }
                    viewerOnlineStatus={
                      processing
                        ? "processing"
                        : device.online
                        ? "online"
                        : "offline"
                    }
                    onSnap={
                      processing || !device.online
                        ? undefined
                        : (message, timeout) =>
                            sendWebSocketMsg(device, message, timeout)
                    }
                  />
                )
              })}
            </div>
          </div>
        </div>
      </div>
      <Footer />
      {modalState ? <Modal {...modalState} /> : <></>}
    </>
  )
}

const condition = (currentUser) => !!currentUser

export default withAuthorization(condition)(ViewerManager)
