import React, { Component } from "react"
import { Link } from "react-router-dom"
import validator from "validator"

import { withFirebase } from "../Firebase"
import { withRouter } from "react-router-dom"
import { compose } from "recompose"

import Footer from "../../Common/Footer/Footer"

import * as ROUTES from "../../Constants/Routes"

const ForgotPassword = () => (
  <div>
    <div className="container-wrapper">
      <div className="container">
        <div className="jumbotron text-center auth-tabs">
          <h3>Reset your password</h3>
          <ForgotPasswordForm />
        </div>
      </div>
    </div>
    <Footer />
  </div>
)

const INITIAL_STATE = {
  email: "",
  formErrors: "",
  emailValid: false,
  formValid: false,
  error: null,
}

class ForgotPasswordFormBase extends Component {
  constructor(props) {
    super(props)

    this.state = { ...INITIAL_STATE }
  }

  validateField(fieldName, value) {
    let formErrors = this.state.formErrors
    let emailValid = this.state.emailValid

    switch (fieldName) {
      case "email":
        emailValid = validator.isEmail(value)
        formErrors = emailValid ? "" : "Email is invalid"
        break
      default:
        break
    }
    this.setState(
      {
        formErrors: formErrors,
        emailValid: emailValid,
      },
      this.validateForm
    )
  }

  validateForm() {
    this.setState({ formValid: this.state.emailValid })
  }

  errorClass(error) {
    return error === "" ? "" : "has-error"
  }

  onSubmit = (event) => {
    const { email } = this.state

    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        //this.setState({ ...INITIAL_STATE })
        this.props.history.push(ROUTES.LANDING)
      })
      .catch((error) => {
        this.setState({ error: "Unknown email address" })
      })

    event.preventDefault()
  }

  onChange = (event) => {
    const name = event.target.name
    const value = event.target.value
    this.setState({ [name]: value }, () => {
      this.validateField(name, value)
    })
  }

  render() {
    const { formValid, error } = this.state

    return (
      <form onSubmit={this.onSubmit}>
        <div className="form-group">
          {error && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}
        </div>
        <div className="form-group">
          <div className={`${this.errorClass(this.state.formErrors)}`}>
            <input
              className="form-control"
              name="email"
              value={this.state.email}
              onChange={this.onChange}
              type="text"
              placeholder="Email Address"
            />
            <small className="form-error form-text text-left">
              <p>{this.state.formErrors}</p>
            </small>
          </div>
        </div>
        <div className="form-group">
          <button className="btn" disabled={!formValid} type="submit">
            Reset my password
          </button>
        </div>
      </form>
    )
  }
}

class ForgotPasswordLink extends Component {
  render() {
    return (
      <p>
        <Link
          to={ROUTES.FORGOT_PASSWORD}
          className="forgot-passwd-link"
          onClick={this.props.closeModal}
        >
          Forgot Password?
        </Link>
      </p>
    )
  }
}

export default ForgotPassword

const ForgotPasswordForm = compose(
  withRouter,
  withFirebase
)(ForgotPasswordFormBase)

export { ForgotPasswordForm, ForgotPasswordLink }
