import React, { useContext, useState, useEffect } from "react"
import { AuthUserContext } from "../Authentication/Session/Session"
import { withFirebase } from "../Authentication/Firebase"
import { withRouter } from "react-router-dom"
import { compose } from "recompose"
import { withAuthorization } from "../Authentication/Session/Session"

import { ContentStoreCtx } from "../Store/ContentStore"
import { SEQUENCE_VERSION } from "../Authentication/Firebase/Firebase"

import MasterDetail from "./MasterDetail"

import { _db_slide_template } from "../Store/SlideDBTemplate"

import "./SlideEditor.css"

const DEBUG = false
// this value should move in Content in firebase and initialized from device display
const viewBox = [0, 0, 478.2, 269]

const UserSlideEditor = (props) => {
  const [state, dispatch] = useContext(ContentStoreCtx)
  //const fbCnx = useRef(props.firebase)

  // this one is called at init when mounting component
  useEffect(() => {
    // check data presence or create first slide
    props.firebase
      .content(props.match.params.id)
      .once("value")
      .then((snap) => {
        if (DEBUG) {
          console.log(`Sequence:`)
          console.log(snap.val())
        }
        const snapVal = snap.val()
        const projectId = snapVal.project_id
        const currentProjectRef = props.firebase.project(projectId)
        currentProjectRef.once("value").then((snap) => {
          const snapVal = snap.val()
          dispatch({
            type: "SET_CURRENT_PROJECT",
            payload: snapVal,
          })
        })
        if (snapVal.sequence) {
          if (snapVal.sequence.version !== SEQUENCE_VERSION) {
            alert(
              `SEQUENCE VERSION MISMATCH: ${snapVal.sequence.version} in DB vs ${SEQUENCE_VERSION} in Studio`
            )
          }
          dispatch({
            type: "LOAD_ALL_PROGRESS",
            payload: {
              template: snapVal.sequence.template,
              slides: snapVal.sequence.slides,
              parts: snapVal.sequence.parts,
            },
          })
        } else {
          if (DEBUG)
            console.log(
              "SlideEditor : initialize, No sequence found, create a new one"
            )
          const template = {
            ..._db_slide_template,
            title: snapVal.name,
          }
          dispatch({ type: "LOAD_TEMPLATE", payload: template })
          dispatch({ type: "ADD_SLIDE", payload: 0 })
          //dispatch({ type: "SET_LOADED", payload: true })
        }
      })
    return () => {
      // clean the content store after edition
      dispatch({ type: "RESET", payload: null })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.id])

  // this one will be called at each updates
  useEffect(() => {
    if (state.slideEdited && state.template && !state.viewMode) {
      DEBUG && console.log(`SAVING SLIDE CONTENT TO FB DB: `, state.slides)
      props.firebase.updateSlides(
        props.match.params.id,
        state.template,
        state.slides,
        state.parts
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.slideEdited,
    state.template,
    state.slides,
    state.parts,
    state.viewMode,
    props.match.params.id,
  ])

  return (
    <AuthUserContext.Consumer>
      {() => (
        <>
          {state.slides && state.slides.length > 0 && (
            <MasterDetail
              sequence={state}
              dispatch={dispatch}
              viewBox={viewBox}
            />
          )}
        </>
      )}
    </AuthUserContext.Consumer>
  )
}

const SlideEditor = (props) => <Editor />

const Editor = compose(withRouter, withFirebase)(UserSlideEditor)

const condition = (currentUser) => !!currentUser

export default withAuthorization(condition)(SlideEditor)
