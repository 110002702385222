import React from "react"

import AuthUserContext from "./Context"
import { withFirebase } from "../Firebase"

const withAuthentication = (Component) => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        currentUser: null,
      }
    }

    componentDidMount() {
      this.listener = this.props.firebase.auth.onAuthStateChanged(
        (currentUser) => {
          currentUser
            ? this.setState({ currentUser: currentUser })
            : this.setState({ currentUser: null })
        }
      )
    }

    componentWillUnmount() {
      this.listener && this.listener()
    }

    render() {
      return (
        <AuthUserContext.Provider value={this.state.currentUser}>
          <Component {...this.props} />
        </AuthUserContext.Provider>
      )
    }
  }

  return withFirebase(WithAuthentication)
}

export default withAuthentication
