import { _db_slide } from "./SlideDBTemplate"

const cloneSlide = (slide) => JSON.parse(JSON.stringify(slide))
export const initialState = {
  currentProject: null,
  template: null,
  slides: null,
  parts: null,
  currentId: -1,
  selectedMediaIndex: -1,
  lockedMedia: [],
  slideEdited: false,
}

const DEBUG = false
const TRACE_SLIDE_EDITED = false

const SlideReducer = (state, action) => {
  if (DEBUG) {
    console.log(`Reduce ${action.type} payload is `)
    console.log(action.payload)
  }
  const s = state.slides || []
  let sid = -1
  if (
    action.payload &&
    typeof action.payload === "object" &&
    "seq_id" in action.payload
  )
    if (action.payload.seq_id) {
      sid = s.findIndex((slide) => slide.seq_id === action.payload.seq_id)
    }

  let newState = { ...state }
  switch (action.type) {
    case "VIEW_MODE":
      if (action.payload === newState.viewMode) {
        newState.viewMode = ""
      } else {
        newState.viewMode = action.payload
      }
      return newState
    case "UPDATE_SLIDE":
      TRACE_SLIDE_EDITED && console.log("Reducer triggered : UPDATE SLIDE")
      newState.slides = [
        ...s.slice(0, sid),
        action.payload,
        ...s.slice(sid + 1),
      ]
      if (!newState.slideEdited) newState.slideEdited = true
      return newState
    case "UPDATE_SLIDE_CONTENT":
      TRACE_SLIDE_EDITED &&
        console.log("Reducer triggered : UPDATE_SLIDE_CONTENT")
      const content = action.payload.content
      const c = s[sid].content
      const cidx = c.findIndex((cont) => cont.layer_id === content.layer_id)
      newState.slides = [
        ...s.slice(0, sid),
        {
          ...s[sid],
          content: [...c.slice(0, cidx), { ...content }, ...c.slice(cidx + 1)],
        },
        ...s.slice(sid + 1),
      ]
      newState.selectedMediaIndex = cidx
      if (!newState.slideEdited) newState.slideEdited = true
      return newState
    case "ADD_SLIDE_CONTENT":
      TRACE_SLIDE_EDITED && console.log("Reducer triggered : ADD_SLIDE_CONTENT")
      newState.slides = [
        ...s.slice(0, sid),
        {
          ...s[sid],
          content: [action.payload.content].concat(s[sid].content),
        },
        ...s.slice(sid + 1),
      ]
      newState.selectedMediaIndex = 0
      if (!newState.slideEdited) newState.slideEdited = true
      return newState
    case "REMOVE_SLIDE_CONTENT":
      TRACE_SLIDE_EDITED &&
        console.log("Reducer triggered : REMOVE_SLIDE_CONTENT")
      if (!newState.slideEdited) newState.slideEdited = true
      if (s[sid].content.length > 1) {
        const layer_id = s[sid].content[action.payload.index].layer_id
        const part_picture_id =
          s[sid].content[action.payload.index].part_picture_id
        newState.slides = [
          ...s.slice(0, sid),
          {
            ...s[sid],
            content: s[sid].content.filter(
              (cont, idx) => idx !== action.payload.index
            ),
          },
          ...s.slice(sid + 1),
        ]
        newState.lockedMedia = state.lockedMedia.filter(
          (m) => m !== layer_id + part_picture_id
        )
        if (action.payload.index === state.selectedMediaIndex) {
          newState.selectedMediaIndex = -1
        }
        return newState
      } else {
        // single media content, just clean the part_url
        newState.slides = [
          ...s.slice(0, sid),
          {
            ...s[sid],
            content: [{ ...s[sid].content[0], part_url: "", compo_url: "" }],
          },
          ...s.slice(sid + 1),
        ]
        return newState
      }
    case "LOAD_ALL_PROGRESS":
      newState.slides = action.payload.slides
      newState.template = action.payload.template
      newState.currentId = action.payload.slides.length
        ? action.payload.slides[0].seq_id
        : 0
      if (action.payload.parts) {
        newState.parts = action.payload.parts
      }
      return newState
    case "LOAD_TEMPLATE":
    case "SET_TEMPLATE":
      if (action.type !== "LOAD_TEMPLATE" && !newState.slideEdited) {
        newState.slideEdited = true
      }
      newState.template = action.payload
      return newState
    case "SET_SLIDES":
      TRACE_SLIDE_EDITED && console.log("Reducer triggered : SET_SLIDES")
      newState.slides = action.payload
      newState.currentId =
        action.payload && action.payload.length ? action.payload[0].seq_id : 0
      if (!newState.slideEdited) newState.slideEdited = true
      return newState
    case "SET_PARTS":
      TRACE_SLIDE_EDITED && console.log("Reducer triggered : SET_PARTS")
      newState.parts = action.payload
      if (!newState.slideEdited) newState.slideEdited = true
      return newState
    case "SET_CURRENT_PROJECT":
      newState.currentProject = action.payload
      newState.selectedMediaIndex = 0
      return newState
    case "COPY_SLIDE":
    case "ADD_SLIDE":
      TRACE_SLIDE_EDITED &&
        action.payload !== 0 &&
        console.log("Reducer triggered : ADD_SLIDE")
      // found the next seq_id to use for a new slide
      const next_id =
        s.reduce((total, current) => {
          return current.seq_id > total ? current.seq_id : total
        }, 0) + 1
      if (DEBUG) console.log(`ADD_SLIDE next ID ${next_id}`)
      // build a new slide from the template and setup the right seq_id
      const slide = action.type === "COPY_SLIDE" ? action.payload : _db_slide
      const newSlide = {
        ...cloneSlide(slide),
        seq_id: next_id,
      }
      // case if we insert the slide after the payload
      if (action.payload) {
        const insertId = s.indexOf(action.payload) + 1
        if (DEBUG)
          console.log(`ADD_SLIDE: insert the slide at position ${insertId}`)
        newState.slides = [
          ...s.slice(0, insertId),
          newSlide,
          ...s.slice(insertId),
        ]
        newState.currentId = next_id
        if (!newState.slideEdited) newState.slideEdited = true
        return newState
      } else {
        // else we insert it at the end
        newState.slides = s.concat([newSlide])
        newState.currentId = next_id
        if (action.payload !== 0 && !newState.slideEdited) {
          // it not initial load
          newState.slideEdited = true
        }
        return newState
      }
    case "REMOVE_SLIDE":
      TRACE_SLIDE_EDITED && console.log("Reducer triggered : REMOVE_SLIDE")
      let newCurrentId = state.currentId
      // if the removed slide is selected, then select the next one or the last
      if (newCurrentId === action.payload.seq_id) {
        const deletedPos = s.indexOf(action.payload)
        if (deletedPos === s.length - 1) {
          newCurrentId = s[deletedPos - 1].seq_id
        } else {
          newCurrentId = s[deletedPos + 1].seq_id
        }
      }
      newState.slides = s.filter(
        (slide) => slide.seq_id !== action.payload.seq_id
      )
      newState.currentId = newCurrentId
      if (!newState.slideEdited) newState.slideEdited = true
      return newState
    case "SLIDE_UP": {
      TRACE_SLIDE_EDITED && console.log("Reducer triggered : SLIDE_UP")
      const id = s.indexOf(action.payload)
      const ns = s
        .slice(0, id - 1)
        .concat([s[id]], [s[id - 1]], s.slice(id + 1))
      newState.slides = ns
      if (!newState.slideEdited) newState.slideEdited = true
      return newState
    }
    case "SLIDE_DOWN": {
      TRACE_SLIDE_EDITED && console.log("Reducer triggered : SLIDE_DOWN")
      const id = s.indexOf(action.payload)
      const ns = s.slice(0, id).concat([s[id + 1]], [s[id]], s.slice(id + 2))
      newState.slides = ns
      if (!newState.slideEdited) newState.slideEdited = true
      return newState
    }
    case "SET_CURRENT_ID": {
      newState.currentId = action.payload
      newState.selectedMediaIndex = 0
      newState.lockedMedia = []
      return newState
    }
    case "SET_SELECTED_MEDIA_INDEX": {
      newState.selectedMediaIndex = action.payload
      return newState
    }
    case "NEXT_LAYER":
    case "PREVIOUS_LAYER": {
      sid = s.findIndex((slide) => slide.seq_id === state.currentId)
      const max_index = s[sid].content.length - 1
      if (max_index === -1) {
        return state
      } else if (action.type === "NEXT_LAYER") {
        if (
          newState.selectedMediaIndex >= 0 &&
          newState.selectedMediaIndex < max_index
        ) {
          newState.selectedMediaIndex++
        } else {
          newState.selectedMediaIndex = 0
        }
      } else {
        if (newState.selectedMediaIndex <= 0) {
          newState.selectedMediaIndex = max_index
        } else {
          newState.selectedMediaIndex--
        }
      }
      return newState
    }
    case "TOGGLE_LOCK_MEDIA":
      if (state.lockedMedia.includes(action.payload)) {
        newState.lockedMedia = state.lockedMedia.filter(
          (m) => m !== action.payload
        )
      } else {
        newState.lockedMedia = [...state.lockedMedia, action.payload]
      }
      return newState
    case "RESET":
      return initialState
    default:
      return state
  }
}

export default SlideReducer
