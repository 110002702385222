import React, { useEffect, useState } from "react"

import HMKSVGSlide from "./HMKSVGSlide"

const Detail = ({
  sequence,
  dispatch,
  item, //current slide
  counter,
  viewBox,
  incrementalRefresh,
  activeKeys,
  openModal,
}) => {
  const [state_incrementalRefresh, setState_incrementalRefresh] = useState(0)
  const [refreshSwitch, setRefreshSwitch] = useState(false)
  useEffect(() => {
    if (incrementalRefresh !== state_incrementalRefresh) {
      setState_incrementalRefresh(incrementalRefresh)
      setRefreshSwitch(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incrementalRefresh])
  useEffect(() => {
    if (refreshSwitch) {
      setRefreshSwitch(false)
    }
  }, [refreshSwitch])
  return (
    <div className="detail-wrapper">
      {item && "seq_id" in item ? (
        <div
          className="detail card text-center text-white"
          style={{ aspectRatio: `${viewBox[2]}/${viewBox[3]}` }}
        >
          {!refreshSwitch && (
            <HMKSVGSlide
              // Common
              template={sequence.template}
              item={item}
              counter={counter}
              viewBox={viewBox}
              // Only on Detail.js
              activeKeys={activeKeys}
              lockedMedia={sequence.lockedMedia}
              dispatch={dispatch}
              selected={sequence.selectedMediaIndex}
              setSelected={(index) => {
                dispatch({
                  type: "SET_SELECTED_MEDIA_INDEX",
                  payload: index,
                })
              }}
              openModal={openModal}
            />
          )}
        </div>
      ) : (
        item &&
        "part_id" in item && (
          <div className="detail card text-center text-warning bg-dark">
            <div className="card-header">{item.part_id}</div>
            <div className="card-body">
              <p>
                {item.part_name} ({item.part_pick_name})
              </p>
              <p>
                {item.part_url && (
                  <img src={item.part_url[0]} alt={item.part_url[0]}></img>
                )}
              </p>
            </div>
          </div>
        )
      )}
    </div>
  )
}

export default Detail
