import React, { useState, useEffect, useContext } from "react"
import "./Navigation.css"
import Modal from "react-modal"
import { Link } from "react-router-dom"
import ReactTooltip from "react-tooltip"
import { FiFile, FiList, FiGrid } from "react-icons/fi"
import { GrCatalog } from "react-icons/gr"
import { BsBookmarkStar } from "react-icons/bs"
import SignOutButton from "../Authentication/SignOut/SignOut"
import { withFirebase } from "../Authentication/Firebase"

import * as ROUTES from "../Constants/Routes"
import { SignIn } from "../Authentication/Authentication"

import NewDevice from "../Device/Device"
import Project from "../Project/Project"
import { ViewerManagerIcon } from "../ViewerManager"
import EditSlideContent from "../Content/EditSlideContent"
import EditSlideTemplate from "../Content/EditSlideTemplate"
import ExportSlidesAction from "../Content/ExportSlidesAction"
import { GlobalContext } from "../Store/ContentStore"
import { MdRefresh } from "react-icons/md"
import { CLOG_TOKEN, openFileManager } from "../Constants/UrlConfig"
import { useCookies } from "react-cookie"
import { BinPacking, BinPackingLogs } from "../Modules/BinPacking"
import { DownloadAPK } from "../Modules/MobileApp"
import { getModuleName, hasModuleName } from "../Modules/ModuleChecker"

const styles = {
  content: {
    borderRadius: "10px",
    borderColor: "orange",
    borderWidth: "3px",
    borderStyle: "solid",
    bottom: "auto",
    height: "380px",
    left: "50%",
    position: "fixed",
    right: "auto",
    top: "50%",
    transform: "translate(-50%,-200px)",
    width: "80%",
    maxWidth: "400px",
    transition: "opacity .5s",
    backgroundColor: "#111",
  },
}

const SignInButton = (props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const openModal = () => {
    setModalIsOpen(true)
  }

  const closeModal = () => {
    setModalIsOpen(false)
  }

  return (
    <div>
      <button className="btn hmk-form-btn" type="button" onClick={openModal}>
        Sign in
      </button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={styles}
        closeTimeoutMS={200}
      >
        <SignIn closeModal={closeModal} />
      </Modal>
    </div>
  )
}

const Navigation = (props) => <NavigationLink {...props} />

const NavigationComponent = (props) => {
  const { authContext, globalRefresh } = useContext(GlobalContext)
  const [navTitle, setNavTitle] = useState("HoloMake|Studio")
  const [folderId, setFolderId] = useState("")
  const [projectId, setProjectId] = useState("")
  const [projectName, setProjectName] = useState("")
  const [contentName, setContentName] = useState("")
  const [slideCount, setSlideCount] = useState(1)
  const [cookies, setCookies] = useCookies(["layout_dir_global"])

  const updateSlideCount = (firebase, objectId) =>
    firebase
    .content(objectId)
    .once("value")
    .then((snap) => {
      const sequence = snap.val().sequence
      const slides = sequence ? sequence.slides : null
      setSlideCount(slides ? slides.length : 1)
    })

  useEffect(() => {
    if (props.state !== "editSlide") return
    const slideLabel = slideCount === 1 ? "slide" : "slides"
    setNavTitle(
      `Slide Editor - ${projectName} / ${contentName} (${slideCount} ${slideLabel})`
    )
  }, [props.state, projectName, contentName, slideCount])

  // UPDATE ON PROP STATE CHANGE
  useEffect(() => {
    if (authContext.currentUser) {
      if (props.state === "editSlide" && props.objectId) {
        props.firebase
          .content(props.objectId)
          .once("value")
          .then((snap) => {
            setContentName(snap.val().name)
            const sequence = snap.val().sequence
            const slides = sequence ? sequence.slides : null
            const slideCount = slides ? slides.length : 1
            setSlideCount(slideCount)
            setProjectId(snap.val().project_id)
            props.firebase
              .project(snap.val().project_id)
              .once("value")
              .then((snap) => {
                setProjectName(snap.val().name)
                setFolderId(snap.val().folder_id)
              })
          })
      } else if (props.state === "devices") {
        setNavTitle("Device Manager")
      } else if (props.state === "viewermanager") {
        setNavTitle("Viewer Manager")
      } else {
        setNavTitle("HoloMake|Studio")
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authContext.currentUser, props.state, props.objectId])

  const isEditSlide = props.state === "editSlide"
  const isDeviceManager = props.state === "devices"
  const isViewerManager = props.state === "viewermanager"
  const isCLOGUser = authContext.currentUser?.uid === CLOG_TOKEN
  if (isEditSlide && props.firebase && props.objectId) updateSlideCount(props.firebase, props.objectId)

  return (
    <nav className="navbar fixed-top navbar-expand-lg navbar-dark">
      <div className="container-fluid">
        <div className="navbar-brand">
          <Link
            to={{
              pathname: authContext.currentUser ? ROUTES.getHomeRoute(authContext.currentUser?.uid) : ROUTES.LANDING,
              state: isEditSlide ? projectId : "",
            }}
          >
            <img
              src="/h_logo_animation.svg"
              width="30"
              height="30"
              className="d-inline-block align-top logo"
              alt=""
            />
          </Link>
          {navTitle==="HoloMake|Studio" ? <>HoloMake|<span style={{ color: "orange" }}>Studio</span></> : navTitle}
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div
          className="collapse navbar-collapse navbar-default navbarSupportedContent"
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav ml-auto">
            {isEditSlide ? (
              <>
                {authContext.currentUser &&
                  authContext.currentUser.emailVerified === true && (
                    <>
                      <li className="nav-item">
                        <Link
                          to={{
                            pathname: ROUTES.HOME,
                            state: projectId,
                          }}
                          className="nav-link navbar-btn-action"
                          data-for="rtt_navbar_projects"
                          data-tip="Projects"
                        >
                          <GrCatalog className="gr_icon" />
                          <ReactTooltip
                            id="rtt_navbar_projects"
                            effect="solid"
                            place="bottom"
                          />
                        </Link>
                      </li>
                      <>
                        <li className="nav-item">
                          <div
                            className="nav-link navbar-btn-action"
                            data-for="rtt_navbar_file_manager"
                            data-tip="Files"
                            onClick={async () =>
                              openFileManager(
                                folderId,
                                await props.firebase.doGetIdToken()
                              )
                            }
                          >
                            <FiFile />
                            <ReactTooltip
                              id="rtt_navbar_file_manager"
                              effect="solid"
                              place="bottom"
                            />
                          </div>
                        </li>
                        <li
                          className="nav-item"
                          onClick={() => globalRefresh()}
                        >
                          <div
                            className="nav-link navbar-btn-action"
                            data-for="rtt_navbar_update_geom"
                            data-tip="Refresh"
                          >
                            <MdRefresh />
                            <ReactTooltip
                              id="rtt_navbar_update_geom"
                              effect="solid"
                              place="bottom"
                            />
                          </div>
                        </li>
                        <li className="nav-item">
                          <EditSlideTemplate contentId={props.objectId} />
                        </li>
                        <li className="nav-item">
                          <EditSlideContent contentId={props.objectId} />
                        </li>
                        <li className="nav-item">
                          <ExportSlidesAction contentId={props.objectId} />
                        </li>
                      </>
                    </>
                  )}
              </>
            ) : isViewerManager || isDeviceManager ? (
              <>
                {!isCLOGUser && <li className="nav-item">
                  <Link
                    to={ROUTES.HOME}
                    className="nav-link navbar-btn-action"
                    data-for="rtt_navbar_projects"
                    data-tip="Projects"
                  >
                    <GrCatalog className="gr_icon" />
                    <ReactTooltip
                      id="rtt_navbar_projects"
                      effect="solid"
                      place="bottom"
                    />
                  </Link>
                </li>}
                {isViewerManager ? (
                  <li className="nav-item">
                    {authContext.currentUser &&
                    authContext.role === "admin" &&
                    authContext.currentUser.emailVerified === true ? (
                      <NewDevice />
                    ) : (
                      <></>
                    )}
                  </li>
                ) : (
                  <li className="nav-item">
                    {authContext.currentUser &&
                    authContext.currentUser.emailVerified === true ? (
                      <ViewerManagerIcon />
                    ) : (
                      <></>
                    )}
                  </li>
                )}
              </>
            ) : (
              <>
                <li className="nav-item">
                  {authContext.currentUser &&
                  authContext.role === "admin" &&
                  authContext.currentUser.emailVerified === true ? (
                    <Project />
                  ) : (
                    <></>
                  )}
                </li>
                {/**
                    <li className="nav-item">
                      {currentUser && emailVerified === true ? (
                        <Metrics />
                      ) : <></>}
                    </li>
                    */}
                <li className="nav-item">
                  {authContext.currentUser &&
                  authContext.currentUser.emailVerified === true ? (
                    <ViewerManagerIcon />
                  ) : (
                    <></>
                  )}
                </li>
                <li className="nav-item">
                  {authContext.currentUser &&
                  authContext.role === "admin" &&
                  authContext.currentUser.emailVerified === true ? (
                    <NewDevice />
                  ) : (
                    <></>
                  )}
                </li>
              </>
            )}
            {!isEditSlide &&
            authContext.currentUser &&
            authContext.currentUser.emailVerified === true ? (
              <>
                <li className="nav-item">
                  {cookies["layout_dir_global"] === "column" ? (
                    <div
                      className="nav-link navbar-btn-action"
                      onClick={() => {
                        setCookies("layout_dir_global", "row")
                      }}
                      data-for="global_layout_dir_row"
                      data-tip="Grid Layout (Global)"
                    >
                      <FiGrid size="25px" />
                      <ReactTooltip
                        id="global_layout_dir_row"
                        effect="solid"
                        place="bottom"
                      />
                    </div>
                  ) : cookies["layout_dir_global"] === "row" ? (
                    <div
                      className="nav-link navbar-btn-action"
                      onClick={() => setCookies("layout_dir_global", "custom")}
                      data-for="global_layout_dir_custom"
                      data-tip="Custom Layout (Global)"
                    >
                      <BsBookmarkStar size="25px" />
                      <ReactTooltip
                        id="global_layout_dir_custom"
                        effect="solid"
                        place="bottom"
                      />
                    </div>
                  ) : (
                    <div
                      className="nav-link navbar-btn-action"
                      onClick={() => setCookies("layout_dir_global", "column")}
                      data-for="global_layout_dir_col"
                      data-tip="List Layout (Global)"
                    >
                      <FiList size="25px" />
                      <ReactTooltip
                        id="global_layout_dir_col"
                        effect="solid"
                        place="bottom"
                      />
                    </div>
                  )}
                </li>
                <li className="nav-item">
                  {authContext.currentUser &&
                  authContext.currentUser.emailVerified === true &&
                  authContext.modules &&
                  authContext.modules.length > 0 &&
                  hasModuleName(authContext.modules, "binpacking") && (
                    <BinPacking
                      userId={authContext.currentUser.uid}
                      module={getModuleName(authContext.modules, "binpacking")}
                    />
                  )}
                </li>
                <li className="nav-item">
                  {authContext.currentUser &&
                  authContext.currentUser.emailVerified === true &&
                  authContext.modules &&
                  authContext.modules.length > 0 &&
                  hasModuleName(authContext.modules, "binpacking") && (
                    <BinPackingLogs
                      userId={authContext.currentUser.uid}
                      module={getModuleName(authContext.modules, "binpacking")}
                    />
                  )}
                </li>
                <li className="nav-item">
                  {authContext.currentUser &&
                  authContext.currentUser.emailVerified === true &&
                  authContext.modules &&
                  authContext.modules.length > 0 &&
                  hasModuleName(authContext.modules, "viewer_apk") && (
                    <DownloadAPK
                      userId={authContext.currentUser.uid}
                      module={getModuleName(authContext.modules, "viewer_apk")}
                    />
                  )}
                </li>
              </>
            ) : (
              <></>
            )}
          </ul>
          <Link
            to="/profile"
            className="profile"
            data-for="rtt_navbar_profile"
            data-tip="Edit Profile"
          >
            {authContext.currentUser &&
            authContext.currentUser.emailVerified === true ? (
              <span className="name">{authContext.username}</span>
            ) : (
              <></>
            )}
            <ReactTooltip
              id="rtt_navbar_profile"
              effect="solid"
              place="bottom"
            />
          </Link>
          <form className="form-inline my-2 my-lg-0">
            {authContext.currentUser ? <SignOutButton /> : <SignInButton />}
          </form>
        </div>
      </div>
    </nav>
  )
}

const NavigationLink = withFirebase(NavigationComponent)

export default Navigation
