import { HMFileIO } from "./HMFileIO"
import { VIEWER_URL, VIEWER_DEV_URL } from "../Constants/UrlConfig"

const openViewer = (urlArgs) => {
    if (urlArgs.includes("mobileViewer=APP_ANDROID_UNITY")) {
        // launch unity app on device
        window.open("http://studio.holomake.fr/unityviewer?" + VIEWER_URL + urlArgs, "HoloMake|Overlay")
    } else if (urlArgs.includes("mobileViewer=APP_ANDROID")) {
        // launch unity app on device
        if (urlArgs.includes("branch=dev")) {
            window.open("http://studio.holomake.fr/mobileViewerAppAndroid?" + VIEWER_DEV_URL + urlArgs, "HoloMake|Overlay (dev)")
        } else {
            window.open("http://studio.holomake.fr/mobileViewerAppAndroid?" + VIEWER_URL + urlArgs, "HoloMake|Overlay")
        }
    } else if (urlArgs.includes("branch=dev")) {
        // open a new web tab in browser
        window.open(VIEWER_DEV_URL + urlArgs, "HoloMake|Overlay (dev)")
    } else {
        // open a new web tab in browser
        window.open(VIEWER_URL + urlArgs, "HoloMake|Overlay")
    }
}

const runProjection = async (cardId, deviceId, firebase, devices, userId) => {
    // retrieve the work instruction content data
    const runProjFromDevice = cardId === deviceId
    let returnValue = null
    let export_folder = null

    if (!runProjFromDevice) {
        const snap = await firebase.content(cardId).once("value")
        let projectId,
            folderId,
            export_folder_id,
            last_updated,
            last_exported
        projectId = snap.val().project_id
        last_updated = snap.val().last_updated
        last_exported = snap.val().last_exported
        export_folder_id = snap.val().export_folder_id
        // this case should really never arrived!
        if (!projectId) {
            return "ERROR - NO PROJECT ID"
        }
        // retrieve the project data
        const _snap = await firebase.project(projectId).once("value")
        deviceId = deviceId ? deviceId : _snap.val().device_id
        folderId = _snap.val().folder_id
        if (!folderId) {
            return "ERROR - NO FOLDER ID"
        }
        if (export_folder_id) {
            export_folder = await HMFileIO(userId).loadFolder(export_folder_id)
            if (export_folder.detail === "Not found.") {
                export_folder = null
            }
        }
        if (!export_folder_id || !export_folder) {
            return "Create Slideshow first."
        } else if (last_updated > last_exported) {
            // inform but launch anyway
            returnValue = "Warning: exported slides are outdated. You may re-export it..."
        }
    }

    // Now launch viewer
    const deviceIdArg = "&deviceId=" + deviceId

    // default viewer settings
    let linkToken = ""
    let poseDrawArg = "&poseDraw=false"
    let captureArg = "&noCapture=true"
    let cameraRotateArg = ""
    let cameraRealsizeArg = "&realSize=true"
    let keyInputModeArg = "&keyInputMode=100"
    let optionalArgs = ""

    // find device and update settings related to device
    const dev = devices.find((dev) => dev.key === deviceId)
    if (dev) {
        if (dev.type === "MONITOR") {
            cameraRealsizeArg = "&realSize=true"
        } else if (dev.type === "CAMERA") {
            poseDrawArg = "&poseDraw=true"
            captureArg = "&captureDeviceIndex=" + dev.capture_device_index
            cameraRotateArg = dev.camera_rotate_180
                ? "&videoRotate180=true"
                : "&videoRotate180=false"
            cameraRealsizeArg = dev.camera_realsize
                ? "&realSize=true"
                : "&realSize=false"
            keyInputModeArg = "&keyInputMode=200"
        } else if (dev.type === "PROJECTOR") {
            poseDrawArg = "&poseDraw=true"
            keyInputModeArg = "&keyInputMode=300"
        }
        if (dev.viewer_args) {
            optionalArgs = dev.viewer_args
        }
    }

    let args_url =
        "?useWebSockets=true" +
        "&userId=" +
        userId +
        "&noSplashImage=true" +
        poseDrawArg +
        cameraRotateArg +
        cameraRealsizeArg +
        keyInputModeArg +
        captureArg +
        deviceIdArg
    if (export_folder) {
        args_url += "&folderId=" + export_folder.id + "&projectFolderId=" + export_folder.parent
    }
    args_url += linkToken + optionalArgs
    openViewer(args_url)
    return returnValue
}

export {
    openViewer,
    runProjection,
}
