import React, { Component } from "react"
import SnackbarMessage from "./SnackbarMessage"
import "./Snackbar.css"

export class SnackbarWrapper extends Component {
  constructor(props) {
    super(props)
    this.state = {
      message: null,
    }
  }

  addMessage = async (payload) => {
    const { message } = this.state
    if (message) {
      await (async () => {
        this.setState({ message: null })
      })()
      this.setState({ message: payload })
    } else {
      this.setState({ message: payload })
    }
  }

  removeMessage = () => {
    this.setState({ message: null })
  }

  render() {
    const { message } = this.state
    return (
      <div className="snackbar-message-wrapper">
        {message && (
          <SnackbarMessage
            message={message.message}
            lifetime={3000 + 1000}
            loading={message.loading}
            lifeCycleCallback={this.removeMessage}
          />
        )}
      </div>
    )
  }
}

export default SnackbarWrapper
