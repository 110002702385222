// percent of the content size when adding a new media
const DEFAULT_MEDIA_SCALE = 100
// percent of the content opacity when adding a new media
const DEFAULT_MEDIA_OPACITY = 100

const DEFAULT_SLIDE_DELAY = -1
const DEFAULT_SLIDE_VIDEO = true
const DEFAULT_SLIDE_LINKS = []
const DEFAULT_SLIDE_VIDEO_CLIP = ""
const DEFAULT_SLIDE_AUDIO_CLIP = ""

/**
 * @empty {string} no detection mode
 * @ML Machine Learning Object Detection
 * @OCR Text (OCR)
 * @QR decoded text of QR Code?
 */
const DEFAULT_SLIDE_DETECTION_MODE = "" // "" | "ML" | "OCR" | "QR"
const DEFAULT_SLIDE_DETECTION_MODEL = ""
const DEFAULT_SLIDE_DETECTION_LABELS = ""
const DEFAULT_SLIDE_DETECTION_THRESHOLD_LOW = 60
const DEFAULT_SLIDE_DETECTION_THRESHOLD_MIDDLE = 70
const DEFAULT_SLIDE_DETECTION_THRESHOLD_HIGH = 85

/**
 * @empty {string} No blocking mode
 * @DETECTION Until labels are with a score greater than the threshold
 * @SNAP Until a snapshot is taken and transmitted
 */
const DEFAULT_SLIDE_BLOCKING_MODE = "" // "" | "DETECTION" | "SNAP"
const DEFAULT_SLIDE_BLOCKING_LABELS = ""

const DEFAULT_CHECKLIST = []

const _compositing = [
  "NORMAL", //default blending
  "SCREEN",
  "OVERLAY",
  "DARKEN",
  "LIGHTEN",
  "COLOR-DODGE",
  "COLOR-BURN",
  "HARD-LIGHT",
  "SOFT-LIGHT",
  "DIFFERENCE",
  "EXCLUSION",
  "HUE",
  "SATURATION",
  "COLOR",
  "LUMINOSITY",
  //"INITIAL",
  //"INHERIT",
  //"REVERT",
  //"UNSET",
]

const _positions = [
  "TOP_LEFT",
  "TOP_CENTER",
  "TOP_RIGHT",
  "CENTER_LEFT",
  "CENTER_CENTER",
  "CENTER_RIGHT",
  "BOTTOM_LEFT",
  "BOTTOM_CENTER",
  "BOTTOM_RIGHT",
]

/** A slideshow template, is global */
const _db_slide_template = {
  title: "Title",
  titlePos: { show: true, position: "TOP_RIGHT", offsetX: 0, offsetY: 0 },
  subtitle: "Subtitle",
  subtitlePos: { show: true, position: "TOP_RIGHT", offsetX: 0, offsetY: 30 },
  counterPos: { show: true, position: "TOP_LEFT", offsetX: 0, offsetY: 30 },
  partnamePos: { show: true, position: "TOP_LEFT", offsetX: 0, offsetY: 0 },
  partpicknamePos: {
    show: true,
    position: "BOTTOM_CENTER",
    offsetX: 0,
    offsetY: 0,
  },
  partpickcountPos: {
    show: false,
    position: "TOP_CENTER",
    offsetX: 0,
    offsetY: 5,
  },
  margin: [15, 10],
}

/** is media */
const _db_slide_content = {
  layer_id: 0,
  part_name: "Step Name",
  part_pick_name: "Step description",
  part_pick_count: 1,
  part_url: "",
  part_picture_id: "",
  compo_url: "",
  compositing: "normal",
  opacity: DEFAULT_MEDIA_OPACITY,
  scale: DEFAULT_MEDIA_SCALE,
  angle: 0,
  x: 0,
  y: 0,
}

/** is a slide, a slide can contain many 'contents' (media) */
const _db_slide = {
  seq_id: 0,
  part_picked: [0],
  content: [_db_slide_content],
  counter: { init_min: 1, init_max: 1, display: true },
  options: ["display_desc", "display_layer_desc"],
  delay: DEFAULT_SLIDE_DELAY,
  video: DEFAULT_SLIDE_VIDEO,
  links: DEFAULT_SLIDE_LINKS,
  video_clip: DEFAULT_SLIDE_VIDEO_CLIP,
  audio_clip: DEFAULT_SLIDE_AUDIO_CLIP,

  /** Detection related */
  /** Is a common text input for all detection mode */
  detection_mode: DEFAULT_SLIDE_DETECTION_MODE,
  detection_model: DEFAULT_SLIDE_DETECTION_MODEL,
  detection_labels: DEFAULT_SLIDE_DETECTION_LABELS,
  detection_threshold_low: DEFAULT_SLIDE_DETECTION_THRESHOLD_LOW,
  detection_threshold_middle: DEFAULT_SLIDE_DETECTION_THRESHOLD_MIDDLE,
  detection_threshold_high: DEFAULT_SLIDE_DETECTION_THRESHOLD_HIGH,

  blocking_mode: DEFAULT_SLIDE_BLOCKING_MODE,
  /** If the blocking_mode is "DETECTION", it is mandatory to provide labels to detect. */
  blocking_labels: DEFAULT_SLIDE_BLOCKING_LABELS,

  checklist: DEFAULT_CHECKLIST,
}

export {
  _compositing,
  _positions,
  _db_slide_template,
  _db_slide,
  _db_slide_content,
  DEFAULT_MEDIA_OPACITY,
  DEFAULT_MEDIA_SCALE,
  DEFAULT_SLIDE_DELAY,
  DEFAULT_SLIDE_VIDEO,
  DEFAULT_SLIDE_DETECTION_THRESHOLD_LOW,
  DEFAULT_SLIDE_DETECTION_THRESHOLD_MIDDLE,
  DEFAULT_SLIDE_DETECTION_THRESHOLD_HIGH,
}
