import React, { useState } from "react"
import { CookiesProvider } from "react-cookie"
import CookieConsent from "react-cookie-consent"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"

import "normalize.css/normalize.css"
import "./App.css"

import ForgotPassword from "./Authentication/ForgotPassword/ForgotPassword"
import Home from "./Home/Home"
import Landing from "./Landing/Landing"
import Navigation from "./Navigation/Navigation"
import UserProfile from "./Profile/Profile"
import AddDevice from "./Device/AddDevice"
import Devices from "./Device/Devices"
import LegalNotices from "./Legal_notices/Legal_notices"
import Rgpd from "./Rgpd/Rgpd"
import Cgvu from "./Cgvu/Cgvu"
import Verification from "./Verification/Verification"
import EditProject from "./Project/EditProject"
import EditContent from "./Content/EditContent"
import SlideEditor from "./Content/SlideEditor"
import NoRouteMatch from "./Common/NoRouteMatch/NoRouteMatch"
import MetricsDevice from "./Metrics/MetricsDevice"

import * as ROUTES from "./Constants/Routes"

import { withAuthentication } from "./Authentication/Session/Session"

import ContentStore from "./Store/ContentStore"
import ViewerManager from "./ViewerManager"

const App = (props) => {
  // by default the Studio display data by filtering by Group.
  // special case implemented that switch in filtering by User
  // for the HoloMake group. This should be a temporary hack that could
  // get into some bugs (if refreshing page during addind or editing a device)
  // In those special case, the device list could be the one from the whole group and not from the user.
  const [filterByGroup, setFilterByGroup] = useState(true)
  const [appState, setAppState] = useState("home")
  const [currentObjId, setCurrentObjId] = useState("")
  return (
    <CookiesProvider>
      <ContentStore firebase={props.firebase}>
        <Router>
          <div>
            <Navigation state={appState} objectId={currentObjId} />
            <Switch>
              <Route component={ForgotPassword} path={ROUTES.FORGOT_PASSWORD} />
              <Route
                render={(props) => {
                  setAppState("home")
                  setCurrentObjId("")
                  return (
                    <Home
                      {...props}
                      filterByGroup={filterByGroup}
                      setFilterByGroup={setFilterByGroup}
                    />
                  )
                }}
                path={ROUTES.HOME}
              />
              <Route component={Landing} path={ROUTES.LANDING} exact />
              <Route component={UserProfile} path={ROUTES.PROFILE} />
              <Route
                render={(props) => {
                  setAppState("devices")
                  return (
                    <Devices
                      {...props}
                      filterByGroup={filterByGroup}
                      setFilterByGroup={setFilterByGroup}
                    />
                  )
                }}
                path={ROUTES.DEVICES}
              />
              <Route
                render={(props) => {
                  setAppState("devices")
                  return (
                    <AddDevice
                      {...props}
                      creation={true}
                      filterByGroup={filterByGroup}
                    />
                  )
                }}
                path={ROUTES.DEVICE}
              />
              <Route
                render={(props) => {
                  setAppState("devices")
                  return (
                    <AddDevice
                      {...props}
                      creation={false}
                      filterByGroup={filterByGroup}
                    />
                  )
                }}
                path={ROUTES.EDIT_DEVICE}
              />
              <Route
                render={(props) => {
                  setAppState("createProject")
                  return <EditProject {...props} creation={true} />
                }}
                path={ROUTES.PROJECT}
              />
              <Route
                render={(props) => {
                  setAppState("createContent")
                  setCurrentObjId(props.match.params.id)
                  return <EditContent {...props} creation={true} />
                }}
                path={ROUTES.CONTENT}
              />
              <Route component={LegalNotices} path={ROUTES.LEGAL_NOTICES} />
              <Route component={Rgpd} path={ROUTES.RGPD} />
              <Route component={Cgvu} path={ROUTES.CGVU} />
              <Route component={Verification} path={ROUTES.VERIFICATION} />
              <Route
                render={(props) => {
                  setAppState("editProject")
                  setCurrentObjId("")
                  return <EditProject {...props} creation={false} />
                }}
                path={ROUTES.EDIT_PROJECT}
              />
              <Route
                render={(props) => {
                  setAppState("editContent")
                  setCurrentObjId(props.match.params.id)
                  return <EditContent {...props} creation={false} />
                }}
                path={ROUTES.EDIT_CONTENT}
              />
              <Route
                render={(props) => {
                  setAppState("editSlide")
                  setCurrentObjId(props.match.params.id)
                  return <SlideEditor {...props} />
                }}
                path={ROUTES.EDIT_SLIDES}
              />
              <Route
                render={(props) => {
                  setAppState("viewermanager")
                  return (
                    <ViewerManager
                      {...props}
                      creation={false}
                      filterByGroup={filterByGroup}
                    />
                  )
                }}
                path={ROUTES.VIEWER_MANAGER}
              />
              <Route component={MetricsDevice} path={ROUTES.METRICS} />
              <Route component={NoRouteMatch} />
            </Switch>
            <CookieConsent
              cookieName="USER_CONSENT"
              location="bottom"
              buttonClasses="btn btn-warning"
              contentClasses="col"
            >
              This site uses cookies to enhance the user experience. By using
              HoloMake|Studio, you accept our use of cookies.
            </CookieConsent>
          </div>
        </Router>
      </ContentStore>
    </CookiesProvider>
  )
}

export default withAuthentication(App)
