import React from "react"
import "./Modal.css"
import { MdClose } from "react-icons/md"

export const INITIAL_MODAL_STATE = {
  display: false,
  title: "",
  message: "",
  onSubmit: () => {},
  onCancel: () => {},
}

const Modal = ({
  // expected
  display,
  title,
  message,
  onSubmit,
  onCancel,

  // optional
  over_message,
  under_message,
  overrideWidth,
  overrideHeight,
  children,
}) => {
  if (!display) {
    return <></>
  } else {
    return (
      <div
        className="modal-backdrop"
        id="main_backdrop"
        onClick={(e) => {
          if (onCancel && e.target.id === "main_backdrop") {
            e.stopPropagation()
            onCancel()
          }
        }}
      >
        <div
          className="modal-container"
          stlye={{
            width: overrideWidth ? overrideWidth : "",
            height: overrideHeight ? overrideHeight : "",
          }}
        >
          {title ? <span className="modal-title">{title}</span> : <></>}
          <p className="modal-title"></p>
          {over_message ? (
            <span className="modal-message">{over_message}</span>
          ) : message ? (
            <span className="modal-message">{message}</span>
          ) : (
            <></>
          )}
          {children ? children : <></>}
          {under_message ? (
            <span className="modal-message">{under_message}</span>
          ) : (
            <></>
          )}
          <div className="modal-button-container">
            <button
              className="modal-button"
              onClick={() => {
                onSubmit && onSubmit()
              }}
            >
              OK
            </button>
            <button
              className="modal-button"
              onClick={() => {
                onCancel && onCancel()
              }}
            >
              Cancel
            </button>
          </div>
          <MdClose
            className="modal-close-icon"
            onClick={() => {
              onCancel && onCancel()
            }}
          />
        </div>
      </div>
    )
  }
}

export default Modal
