import React, { useContext } from "react"

import { AuthUserContext } from "../Authentication/Session/Session"
import { withFirebase } from "../Authentication/Firebase"
import { withRouter } from "react-router-dom"
import { compose } from "recompose"
import { withAuthorization } from "../Authentication/Session/Session"

import { ContentStoreCtx } from "../Store/ContentStore"

import { FiEdit } from "react-icons/fi"
import ReactTooltip from "react-tooltip"

const EditSlideTemplate = (props) => {
  const [state, dispatch] = useContext(ContentStoreCtx)

  const editTemplate = () => {
    dispatch({ type: "VIEW_MODE", payload: "template" })
  }

  return (
    <AuthUserContext.Consumer>
      {() => (
        <div
          className="nav-link navbar-btn-action"
          data-for="rtt_navbar_edit_template"
          data-tip="Edit Template (T)"
        >
          <FiEdit onClick={() => editTemplate(props)} />
          <ReactTooltip
            id="rtt_navbar_edit_template"
            effect="solid"
            place="bottom"
          />
        </div>
      )}
    </AuthUserContext.Consumer>
  )
}

const condition = (currentUser) => !!currentUser

export default withAuthorization(condition)(
  compose(withRouter, withFirebase)(EditSlideTemplate)
)
