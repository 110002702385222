import React from "react"

const SnapButton = ({
  isListLayout,
  action,
  onSnap,
  viewerOnlineStatus,
}) => {
  return (
    <button
      className={`snap-button ${
        onSnap ? "snap-button-ready" : "snap-button-delayed"
      }`}
      style={{
        cursor:
          viewerOnlineStatus === "processing"
            ? "wait"
            : viewerOnlineStatus === "offline"
            ? "not-allowed"
            : "pointer",
        width: isListLayout ? "" : "100%",
        marginRight: isListLayout ? "0.25rem" : "",
      }}
      onClick={() => onSnap && onSnap(action.message, action.timeout)}
    >
      {action.name}
    </button>
  )
}

export default SnapButton
