import React, { useEffect, useState, useMemo } from "react"
import { HMFileIO } from "../../Common/HMFileIO"
import { FiPlus, FiFile } from "react-icons/fi"
import ContentCardIcon from "./ContentCardIcon"
import ContentCardText from "./ContentCardText"
import SnapButton from "./SnapButton"
import StatusIndicator from "./StatusIndicator"
import InteractiveActionIcons from "./InteractiveBottomIcons"
import "./ContentCard.css"

const ContentCard = ({
  direction,
  userRole,
  uid,
  card,
  icon,
  devices,
  defaultDevice,
  viewerOnlineStatus,
  onProject,
  onEditContent,
  onCopyContent,
  onDeleteContent,
  onCardClick,
  onSnap,
}) => {
  const [picture, setPicture] = useState(null)
  const [imageValidity, setImageValidity] = useState(false)

  useEffect(() => {
    if (card.picture_id) {
      HMFileIO(uid)
        .loadFile(card.picture_id, true)
        .then((pic) => {
          setPicture(pic)
          setImageValidity(true)
        })
    }
  }, [uid, card.picture_id])

  const isListLayout = useMemo(() => {
    return direction === "column"
  }, [direction])

  if (isListLayout) {
    return (
      <div
        className={`content-list-container ${
          viewerOnlineStatus === "offline"
            ? "content-list-disabled-container"
            : "content-list-item-container"
        }`}
        onClick={() => onCardClick && onCardClick(card)}
      >
        <div className="content-list-left">
          <ContentCardIcon
            uid={uid}
            isListLayout={true}
            picture={picture}
            card={card}
            icon={icon}
            imageValidity={imageValidity}
          />
          <ContentCardText
            uid={uid}
            isListLayout={true}
            card={card}
            viewerOnlineStatus={viewerOnlineStatus}
          />
        </div>
        <div className="content-list-right">
          {userRole === "admin" ? (
            <>
              {card.deviceActions && card.deviceActions.map((action,idx) => (
                <SnapButton
                  key={idx}
                  action={action}
                  isListLayout={true}
                  onSnap={onSnap}
                  viewerOnlineStatus={viewerOnlineStatus}
                />
              ))}
              <InteractiveActionIcons
                isListLayout={true}
                card={card}
                defaultDevice={defaultDevice}
                onProject={onProject}
                devices={devices}
                onEditContent={onEditContent}
                onCopyContent={onCopyContent}
                onDeleteContent={onDeleteContent}
              />
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    )
  }

  return (
    <div className="col-sm-6 col-md-4 card-wrapper mt-4">
      <div
        className={`card h-100 card-content ${
          !onCardClick ? "card-static" : ""
        }`}
      >
        <div
          className="card-body"
          onClick={() => onCardClick && onCardClick(card)}
          style={viewerOnlineStatus === "processing" ? { cursor: "wait" } : {}}
        >
          <ContentCardIcon
            uid={uid}
            picture={picture}
            card={card}
            icon={icon}
            imageValidity={imageValidity}
          />
          <ContentCardText
            uid={uid}
            card={card}
            viewerOnlineStatus={viewerOnlineStatus}
          />
          {userRole === "admin" && card.deviceActions && card.deviceActions.map((action,idx) => (
            <SnapButton
              key={idx}
              action={action}
              onSnap={onSnap}
              viewerOnlineStatus={viewerOnlineStatus}
            />
          ))}
        </div>
        {userRole === "admin" ? (
          <InteractiveActionIcons
            card={card}
            defaultDevice={defaultDevice}
            onProject={onProject}
            devices={devices}
            onEditContent={onEditContent}
            onCopyContent={onCopyContent}
            onDeleteContent={onDeleteContent}
          />
        ) : (
          <></>
        )}
        {viewerOnlineStatus ? (
          <StatusIndicator
            viewerOnlineStatus={viewerOnlineStatus}
            sizeInPixels={24}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}

export default ContentCard

const ContentActionCard = ({ id, direction, onClick, caption }) => {
  const isListLayout = useMemo(() => {
    return direction === "column"
  }, [direction])
  const iconSize = useMemo(() => {
    return isListLayout ? 40 : 100
  }, [isListLayout])
  const Icon = useMemo(() => {
    if (id === "card-add-content") {
      return <FiPlus size={`${iconSize}px`} />
    } else {
      return <FiFile size={`${iconSize}px`} />
    }
  }, [id, iconSize])

  if (isListLayout) {
    return (
      <div
        className="content-list-container content-list-action-container"
        id={id}
        onClick={onClick}
      >
        <div className="content-list-left">
          {Icon}
          <span className="content-list-caption">{caption}</span>
        </div>
      </div>
    )
  }

  return (
    <div className="col-sm-6 col-md-4 card-wrapper mt-4">
      <div
        className="card h-100 card-content"
        id="card-add-content"
        onClick={onClick}
      >
        <div className={"card-body"}>
          <div className="caption text-center">
            {Icon}
            <h3>{caption}</h3>
          </div>
        </div>
      </div>
    </div>
  )
}

export { ContentActionCard }
