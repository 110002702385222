import React from "react";
import { AuthUserContext } from "../Authentication/Session/Session";
import { Link } from "react-router-dom";
import { withAuthorization } from "../Authentication/Session/Session";
import { Chart } from "react-google-charts";
import { GoX } from "react-icons/go";
import "./DisplayMetrics.css";

class DisplayMetrics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: []
    };
  }

  componentDidMount() {
    const id = this.props.id;
    var events = this.props.firebase.metrics(id).orderBy("date", "asc");
    var table = [];
    events
      .get()
      .then(function(allDocs) {
        var current = 0;
        var count = 0;
        var lastDate;
        allDocs.forEach(function(doc) {
          var data = doc.data();
          count++;
          if (data.action.startsWith("start on usecase")) {
            if (lastDate) {
              table[current][1] =
                table[current][1] + " (" + count.toString() + " actions)";
              var last = new Date(
                0,
                0,
                0,
                lastDate.getHours(),
                lastDate.getMinutes(),
                lastDate.getSeconds()
              );
              table[current][3] = last;
              if (table[current][2] > table[current][3]) {
                table.pop();
              } else {
                current++;
              }
            }
            count = 0;
            lastDate = new Date();
            lastDate.setTime(data.date.seconds * 1000);
            var label = data.action.substring(17);
            var dayDate = lastDate.toLocaleDateString(undefined, {
              day: "2-digit",
              month: "2-digit",
              year: "numeric"
            });
            var startDate = new Date(
              0,
              0,
              0,
              lastDate.getHours(),
              lastDate.getMinutes(),
              lastDate.getSeconds()
            );
            table.push([dayDate, label, startDate, lastDate]);
          } else {
            lastDate = new Date();
            lastDate.setTime(data.date.seconds * 1000);
          }
        });
        table.pop();
      })
      .then(() => {
        this.setState({ rows: table });
      });
  }

  render() {
    const rows = this.state.rows.map(row => {
      return [row[0], row[1], new Date(row[2]), new Date(row[3])];
    });
    return (
      <AuthUserContext.Consumer>
        {() => (
          <div id="timeline">
            <div>
              <h2 className="border-top text-secondary pt-3 text-center">
                Metrics
              </h2>
              <Link to="/metrics" onClick={this.props.onClick}>
                <GoX className="closeMetrics" />
              </Link>
            </div>
            <Chart
              chartType="Timeline"
              loader={<div>Loading Chart</div>}
              columns={[
                { type: "string", id: "Date" },
                { type: "string", id: "Name" },
                { type: "date", id: "Start" },
                { type: "date", id: "End" }
              ]}
              rows={rows}
              options={{
                width: 1440,
                height: 750,
                backgroundColor: "#fff",
                fontName: "Source Sans Pro, sans-serif",
                timeline: {
                  rowLabelStyle: {
                    fontName: "Source Sans Pro, sans-serif",
                    fontSize: 24,
                    color: "#000"
                  },
                  barLabelStyle: { fontName: "Source Sans Pro, sans-serif", fontSize: 14 }
                },
                hAxis: {
                  min: new Date(0, 0, 0, 0, 0, 0),
                  max: new Date(0, 0, 0, 23, 59, 59)
                }
              }}
            />
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

const condition = currentUser => !!currentUser;

export default withAuthorization(condition)(DisplayMetrics);
