import React, {
  useState,
  useEffect,
  useMemo,
  useLayoutEffect,
  useContext,
} from "react"

import SlideTemplateEditor from "./SlideTemplateEditor"
import SlideContentEditor from "./SlideContentEditor"
import { checkContentMedia } from "./SlideMediaChecker"
import {
  DEFAULT_MEDIA_SCALE,
  DEFAULT_MEDIA_OPACITY,
} from "../Store/SlideDBTemplate"
import Master from "./Master"
import Detail from "./Detail"
import { checkSlideMedia } from "./SlideMediaChecker"
import { GlobalContext } from "../Store/ContentStore"
import {
  getShortenedLabel,
  isIndex,
  isUrl,
  parseClipboard,
} from "../Common/utils"
import { getUrlDisplayFormat } from "../Common/HMFileIO"
import Modal from "../Modals/Modal"

// eslint-disable-next-line no-unused-vars
const DEBUG = false

const checkVisible = (elm) => {
  var rect = elm.getBoundingClientRect()
  var viewHeight = Math.max(
    document.documentElement.clientHeight,
    window.innerHeight
  )
  return !(rect.bottom < 120 || rect.top - viewHeight >= 0)
}

const MasterDetail = ({ sequence, dispatch, viewBox }) => {
  const globalContext = useContext(GlobalContext)
  const [incrementalRefresh, setIncrementalRefresh] = useState(0)
  const [activeKeys, setActiveKeys] = useState([])
  const [modalState, setModalState] = useState(null)

  const handleClick = (event, itemId) => {
    dispatch({ type: "SET_CURRENT_ID", payload: itemId })
    setIncrementalRefresh((prev) => prev + 1)
  }

  const currentSlide = useMemo(() => {
    return sequence.slides.find((s) => s.seq_id === sequence.currentId)
  }, [sequence])

  const currentSlideIdx = useMemo(() => {
    return sequence.slides.findIndex((s) => s.seq_id === sequence.currentId)
  }, [sequence])

  useEffect(() => {
    let shiftDown = false
    const onPasteLink = async () => {
      let currentSlide = sequence.slides.find(
        (s) => s.seq_id === sequence.currentId
      )
      const text = await navigator.clipboard.readText()
      const as_content = parseClipboard(text)
      const is_only_url = isUrl(text)
      if (as_content.part_url || is_only_url) {
        let mediaReference = { ...currentSlide.content[0] }
        const w = viewBox[2] // *DEFAULT_MEDIA_SIZE_PERCENT/ 100
        const h = viewBox[3] // *DEFAULT_MEDIA_SIZE_PERCENT/ 100
        const x = (viewBox[2] - w) / 2
        const y = (viewBox[3] - h) / 2

        const new_part_url =
          is_only_url || !as_content.part_url ? text : as_content.part_url
        const new_part_picture_id = as_content.part_picture_id
          ? as_content.part_picture_id
          : ""
        const new_compo_url = as_content.compo_url ? as_content.compo_url : ""

        const new_opacity = as_content.opacity
          ? as_content.opacity
          : mediaReference.opacity
          ? mediaReference.opacity
          : DEFAULT_MEDIA_OPACITY
        const new_scale = as_content.scale
          ? as_content.scale
          : mediaReference.scale
          ? mediaReference.scale
          : DEFAULT_MEDIA_SCALE
        const new_compositing = as_content.compositing
          ? as_content.compositing
          : mediaReference.compositing
          ? mediaReference.compositing
          : "NORMAL"

        const new_width = as_content.width ? as_content.width : w
        const new_height = as_content.height ? as_content.height : h
        const new_x = as_content.x ? as_content.x : x
        const new_y = as_content.y ? as_content.y : y
        const new_angle = as_content.angle ? as_content.angle : 0

        const newContent = {
          ...mediaReference,
          part_url: new_part_url,
          part_picture_id: new_part_picture_id,
          compo_url: new_compo_url,
          compositing: new_compositing,
          opacity: new_opacity,
          scale: new_scale,
          width: new_width,
          height: new_height,
          x: new_x,
          y: new_y,
          angle: new_angle,
        }
        const content = await checkContentMedia(newContent)
        if (!currentSlide.content[0].part_url) {
          // update single slide content
          dispatch({
            type: "UPDATE_SLIDE_CONTENT",
            payload: { seq_id: sequence.currentId, content: content },
          })
        } else {
          // add a new content layer, set a new free id
          let layerId =
            currentSlide.content.reduce(
              (result, item) =>
                item.layer_id > result ? item.layer_id : result,
              0
            ) + 1
          content.layer_id = layerId
          dispatch({
            type: "ADD_SLIDE_CONTENT",
            payload: { seq_id: sequence.currentId, content: content },
          })
        }
      } else {
        alert("Text from clipboard isn't a valid URL")
      }
    }
    const onCopyLink = () => {
      if (!isIndex(sequence.selectedMediaIndex)) return
      const currentSlide = sequence.slides.find(
        (s) => s.seq_id === sequence.currentId
      )
      const currentlySelectedMedia =
        currentSlide.content[sequence.selectedMediaIndex]
      const obj = JSON.stringify({ ...currentlySelectedMedia })
      navigator.clipboard.writeText(obj)
      globalContext.displaySnackbar({
        message: [
          "Copied content : ",
          getShortenedLabel(
            getUrlDisplayFormat(currentlySelectedMedia.part_url),
            25
          ),
        ],
      })
    }
    const onKeyDown = (event) => {
      shiftDown = event.shiftKey
      switch (event.key) {
        case "s":
        case "S":
          setActiveKeys((prev) => {
            return prev.includes("s") ? prev : [...prev, "s"]
          })
          break
        case "PageUp":
        case "PageDown":
        case "End":
        case "Home":
          event.preventDefault()
          break
        case "Tab":
          if (!sequence.viewMode) {
            event.preventDefault()
          }
          break
        default:
          return
      }
    }

    const onKeyUp = (event) => {
      let processEvent = false
      let seqId = undefined
      // no keybinding when key target is an editable widget
      if (
        event.target.tagName === "TEXTAREA" ||
        event.target.tagName === "INPUT"
      )
        return
      const ctrlDown = event.ctrlKey || event.metaKey
      const _shiftDown = shiftDown
      if (shiftDown) {
        shiftDown = false
      }
      switch (event.key) {
        // Tab key to switch current selected media on slide
        case "Tab": {
          if (!sequence.viewMode) {
            dispatch({
              type: _shiftDown ? "PREVIOUS_LAYER" : "NEXT_LAYER",
              payload: null,
            })
          }
          break
        }
        // PgUp to go up one slide
        case "PageUp": {
          const idx = sequence.slides.findIndex(
            (s) => s.seq_id === sequence.currentId
          )
          if (idx) {
            seqId = sequence.slides[idx - 1].seq_id
            processEvent = true
          }
          break
        }
        // PgDown to go down one slide
        case "PageDown": {
          const idx = sequence.slides.findIndex(
            (s) => s.seq_id === sequence.currentId
          )
          if (idx < sequence.slides.length - 1) {
            seqId = sequence.slides[idx + 1].seq_id
            processEvent = true
          }
          break
        }
        // End to go to last slide
        case "End": {
          seqId = sequence.slides[sequence.slides.length - 1].seq_id
          processEvent = true
          break
        }
        // Home to go to first slide
        case "Home": {
          seqId = sequence.slides[0].seq_id
          processEvent = true
          break
        }
        case "s":
        case "S":
          setActiveKeys((prev) => prev.filter((k) => k !== "s"))
          break
        // 'e' to open the SlideContentEditor
        case "e":
        case "E": {
          dispatch({
            type: "VIEW_MODE",
            payload: sequence.viewMode === "content" ? "" : "content",
          })
          break
        }
        // 't' to open the SlideTemplateEditor
        case "t":
        case "T": {
          dispatch({
            type: "VIEW_MODE",
            payload: sequence.viewMode === "template" ? "" : "template",
          })
          break
        }
        case "c":
        case "C": {
          if (ctrlDown) {
            onCopyLink()
          }
          break
        }
        // Ctrl+'v' to paste a media on the current slide
        case "v":
        case "V": {
          if (ctrlDown) {
            onPasteLink()
          }
          break
        }
        // TODO Implement delete of the current selected content on current slide
        case "Delete": {
          /*
          if (sequence.slides.length > 1)
            dispatch({ type: "REMOVE_SLIDE", payload: currentSlide })
          */
          break
        }
        default:
      }
      if (processEvent) {
        dispatch({ type: "SET_CURRENT_ID", payload: seqId })
        const el = document.getElementById(`master-slide-${seqId}`)
        if (!checkVisible(el)) {
          el.scrollIntoView(event.keyCode === 33 || event.keyCode === 36)
        }
      }
    }

    // register keypress event handler
    window.addEventListener("keydown", onKeyDown)
    window.addEventListener("keyup", onKeyUp)
    return () => {
      window.removeEventListener("keydown", onKeyDown)
      window.removeEventListener("keyup", onKeyUp)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sequence, dispatch, viewBox])

  const updateGeomCompo = async () => {
    try {
      let cache = JSON.stringify(sequence.slides)
      await Promise.all(
        sequence.slides.map(async (e) => {
          return checkSlideMedia(globalContext.authContext.uid,  e)
        })
      )
      if (JSON.stringify(sequence.slides) !== cache) {
        // geometry change detected
        dispatch({ type: "SET_SLIDES", payload: sequence.slides })
        dispatch({ type: "SET_CURRENT_ID", payload: sequence.currentId })
        refreshDetail()
        globalContext.displaySnackbar({
          message: ["Successfully updated slides"],
        })
      } else {
        globalContext.displaySnackbar({
          message: ["Slides are up to date"],
        })
      }
    } catch (e) {
      globalContext.displaySnackbar({
        message: [JSON.stringify(e).replace(/"/g, "")],
      })
      console.log(JSON.stringify(e))
    }
  }

  const openModal = (params) => {
    if (modalState || !params || !params.onSubmit) return
    setModalState({
      display: true,
      title: params.title,
      message: params.message,
      children: params.children,
      onCancel: () => setModalState(false),
      onSubmit: () => {
        params.onSubmit()
        setModalState(false)
      },
    })
  }

  useLayoutEffect(() => {
    //automatically update geom compo when first load slides || refresh F5
    updateGeomCompo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalContext.incrementalRefresh])

  const refreshDetail = () => {
    //force refresh image after editing opacity|scale
    setIncrementalRefresh(incrementalRefresh + 1)
  }
  return (
    <>
      <div className="master-detail-wrapper">
        <div className="lhs">
          <Master
            sequence={sequence}
            dispatch={dispatch}
            refreshDetail={refreshDetail}
            onClick={handleClick}
            viewBox={viewBox}
          />
        </div>
        <Detail
          dispatch={dispatch}
          sequence={sequence}
          item={currentSlide}
          counter={{
            index: currentSlideIdx + 1,
            total: sequence.slides.length,
          }}
          viewBox={viewBox}
          incrementalRefresh={incrementalRefresh}
          activeKeys={activeKeys}
          openModal={openModal}
        />
      </div>
      {sequence.viewMode === "template" && (
        <SlideTemplateEditor template={sequence.template} dispatch={dispatch} />
      )}
      {sequence.viewMode === "content" && (
        <SlideContentEditor
          sequence={sequence}
          dispatch={dispatch}
          item={currentSlide}
          refreshDetail={refreshDetail}
          viewBox={viewBox}
          selectedMediaIndex={sequence.selectedMediaIndex}
          openModal={openModal}
        />
      )}
      {modalState ? <Modal {...modalState} /> : <></>}
    </>
  )
}

export default MasterDetail
