import React from "react"
import { Link } from "react-router-dom"
import { MdClose } from "react-icons/md"
import * as ROUTES from "../Constants/Routes"

const CloseButton = ({ onClick, itemId, linkTo = ROUTES.HOME }) => (
  <div className="text-right">
    {onClick ? (
      <MdClose className="hmk-btn-close" onClick={onClick} />
    ) : (
      <Link to={{ pathname: linkTo, state: itemId }}>
        <MdClose className="hmk-btn-close" />
      </Link>
    )}
  </div>
)

export default CloseButton
