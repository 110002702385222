import app from "firebase/compat/app"
import "firebase/compat/auth"
import "firebase/compat/database"
import "firebase/compat/storage"
import "firebase/compat/firestore"

const REALTIME_FIREBASE_DB =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_FIREBASE_DATABASE_URL
    : process.env.REACT_APP_FIREBASE_DATABASE_URL_DEV

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: REALTIME_FIREBASE_DB,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
}

export const SEQUENCE_VERSION = "0.1"

class Firebase {
  constructor() {
    app.initializeApp(config)

    this.auth = app.auth()
    this.db = app.database()
    this.st = app.storage()
    this.fr = app.firestore()
    this.TIMESTAMP = app.database.ServerValue.TIMESTAMP
  }

  // Authentication API

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password)

  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email)

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password)

  doGetIdToken = () => this.auth.currentUser.getIdToken()

  doSignOut = () => this.auth.signOut()

  doSendEmailVerification = () => this.auth.currentUser.sendEmailVerification()

  // Realtime Database user API

  user = (uid) => this.db.ref(`users/${uid}/username`)

  users = () => this.db.ref("users")

  groups = (uid) => this.db.ref(`users/${uid}/groups`)

  company = (uid) => this.db.ref(`users/${uid}/company`)

  // Realtime Database devices API

  device = (did) => this.db.ref(`devices/${did}/device`)

  screen = (did) => this.db.ref(`devices/${did}/screen_display`)

  camera = (did) => this.db.ref(`devices/${did}/camera`)

  projector = (did) => this.db.ref(`devices/${did}/projector`)

  devices = (did) =>
    did ? this.db.ref(`devices/${did}`) : this.db.ref("devices")

  // Realtime Database files API

  files = () => this.db.ref("files")

  // Realtime Database projects API

  project = (pid) => this.db.ref(`projects/${pid}`)

  projects = () => this.db.ref("projects")

  // Realtime Database contents API

  content = (cid) => this.db.ref(`contents/${cid}`)

  contents = () => this.db.ref("contents")

  sequence = (cid) => this.db.ref(`contents/${cid}/sequence`)
  template = (cid) => this.db.ref(`contents/${cid}/sequence/template`)
  slides = (cid) => this.db.ref(`contents/${cid}/sequence/slides`)
  orderedSlides = (cid) =>
    this.db.ref(`contents/${cid}/sequence/slides`).orderByChild("seq_id")
  parts = (cid) => this.db.ref(`contents/${cid}/sequence/parts`)
  orderedParts = (cid) =>
    this.db.ref(`contents/${cid}/sequence/parts`).orderByChild("part_id")
  slide = (cid, id) => this.db.ref(`contents/${cid}/sequence/slides/${id}`)
  updateSlides = (cid, template, slides, parts) => {
    const updates = {}
    updates[`contents/${cid}/sequence/version`] = SEQUENCE_VERSION
    if (template) updates[`contents/${cid}/sequence/template`] = template
    if (slides) updates[`contents/${cid}/sequence/slides`] = slides
    if (parts) updates[`contents/${cid}/sequence/parts`] = parts
    updates[`contents/${cid}/last_updated`] = this.TIMESTAMP
    return this.db.ref().update(updates)
  }
  updateExportDate = (cid, export_folder_id) => {
    return this.db.ref(`contents/${cid}`).update({
      export_folder_id: export_folder_id,
      last_exported: this.TIMESTAMP,
    })
  }

  // Storage

  upload = (uid) => this.st.ref(`files/${uid}`)

  // Realtime Database Firestore contents API

  metrics = (did) =>
    this.fr.collection("metrics").doc(`${did}`).collection("events")
}

export default Firebase
