import React, { useEffect, useState, useMemo, useCallback } from "react"
import { HMFileIO } from "../Common/HMFileIO"
import FallbackImg from "./404.svg"

const DEBUG = false
const TEXT_BG_PADDING = 2

export const HMKFont = (
  family = "Calibri",
  size = "14px",
  weight = "bold",
  color = "white"
) => ({
  fontFamily: family,
  fontSize: size,
  fontWeight: weight,
  strokeWidth: "1",
  stroke: "none",
  strokeOpacity: "1.0",
  fill: color,
  fillOpacity: "1.0",
})

export const HMKText = ({
  width,
  height,
  margin,
  font,
  position,
  text,
  dynamic_offsetY,
}) => {
  const [vertAlign, horAlign] = position.position.split("_")
  const [text_bbox, set_text_bbox] = useState({
    width: 0,
    height: 0,
    x: 0,
    y: 0,
  })
  // margins def same as CSS : [top right bottom left]
  const margins =
    margin.length === 1
      ? [margin[0], margin[0], margin[0], margin[0]]
      : margin.length === 2
      ? [margin[0], margin[1], margin[0], margin[1]]
      : margin.length === 3
      ? [margin[0], margin[1], margin[2], margin[1]]
      : margin
  const textAnchor =
    horAlign === "LEFT" ? "start" : horAlign === "RIGHT" ? "end" : "middle"
  let x =
    horAlign === "LEFT"
      ? margins[3]
      : horAlign === "RIGHT"
      ? width - margins[1]
      : width / 2
  x = x + position.offsetX
  let y =
    vertAlign === "TOP"
      ? margins[0]
      : vertAlign === "BOTTOM"
      ? height - margins[2]
      : height / 2
  y = y + position.offsetY
  if (DEBUG) {
    console.log(
      `HMKText for ${text} alignment is ${vertAlign}/${horAlign} coords: [${x}; ${y}]`
    )
  }
  const multilineText = useMemo(() => {
    //Both enter key and backslash will work
    const line_breaks = text
      .split(String.fromCharCode(10))
      .join(",")
      .split("\\n")
      .join(",")
      .split(",")
    return line_breaks.map((t, i) => (
      <tspan key={`${t}${i}`} x={x} dy={i ? `1em` : `0em`}>
        {t}
      </tspan>
    ))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text])

  let fontSize = font.fontSize ? font.fontSize.split("px")[0] : 16

  if (vertAlign === "BOTTOM" && multilineText.length > 1) {
    y -= (multilineText.length * fontSize) / 2
  }
  if (dynamic_offsetY) {
    const additional_offsetY = dynamic_offsetY.text
      .split(String.fromCharCode(10))
      .join(",")
      .split("\\n")
      .join(",")
      .split(",")
    if (additional_offsetY.length > 1) {
      y += (additional_offsetY.length * dynamic_offsetY.fontSize) / 2
    }
  }

  const textSizeRef = useCallback(
    (node) => {
      if (node) {
        set_text_bbox(node.getBBox())
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [width, height, margin, font, position, text, dynamic_offsetY]
  )

  return (
    <>
      {text_bbox.height && text_bbox.width && (
        <rect
          style={{ pointerEvents: "none" }}
          x={
            textAnchor === "start"
              ? x - TEXT_BG_PADDING
              : textAnchor === "end"
              ? x - text_bbox.width - TEXT_BG_PADDING
              : x - text_bbox.width / 2 - TEXT_BG_PADDING
          }
          y={text_bbox.y + TEXT_BG_PADDING}
          width={text_bbox.width + TEXT_BG_PADDING * 2}
          height={text_bbox.height - TEXT_BG_PADDING}
          fill={"rgba(0,0,0,0.5)"}
          rx={4}
        />
      )}
      <text
        ref={textSizeRef}
        x={x}
        y={y}
        alignmentBaseline="middle"
        textAnchor={textAnchor}
        {...font}
        style={{ zIndex: 4, pointerEvents: "none" }}
      >
        {multilineText.length > 1 ? multilineText : text}
      </text>
    </>
  )
}

export const HMKImage = ({
  width,
  height,
  url,
  x,
  y,
  angle,
  opacity,
  scale,
  compositing,
  selected,
  draggable,
  isFromGeometry,
}) => {
  const [imgValidity, setImgValidity] = useState(true)
  const [finalWidth, setFinalWidth] = useState()
  const [finalHeight, setFinalHeight] = useState()
  const [finalBlendMode, setFinalBlendMode] = useState("normal")

  useEffect(() => {
    let mounted = true
    HMFileIO().imageValidityCheck(url).then((status) => {
      !status && mounted && setImgValidity(false)
    })
    return () => {
      mounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url])

  useEffect(() => {
    let _width = width * (scale ? scale * 0.01 : 1)
    let _height = height * (scale ? scale * 0.01 : 1)
    let _blendmode = compositing ? compositing.toLowerCase() : "normal"
    // backward compatibility
    if (_blendmode === "blend" || !imgValidity) _blendmode = "normal"
    setFinalWidth(_width)
    setFinalHeight(_height)
    setFinalBlendMode(_blendmode)
  }, [width, height, imgValidity, compositing, scale])

  return (
    <>
      {finalWidth && finalHeight && (
        <g transform="translate(0 0)" width={width} height={height}>
          <image
            id={isFromGeometry ? "isFromGeometry" : ""}
            className={draggable ? "draggable" : ""}
            href={imgValidity ? url : FallbackImg}
            width={finalWidth}
            height={finalHeight}
            transform={`translate(${x}, ${y}) rotate(${angle})`}
            opacity={opacity ? opacity * 0.01 : 1}
            style={{
              mixBlendMode: finalBlendMode,
            }}
          />
          {selected && (
            <HMKSelectionBox x={x} y={y} w={finalWidth} h={finalHeight} />
          )}
        </g>
      )}
    </>
  )
}

const HMKSelectionBox = ({ x, y, w, h }) => (
  <g stroke="orange" strokeWidth={1} fill="none">
    <rect x={x} y={y} width={w} height={h} />
    <circle className={`resizable-nw`} cx={x} cy={y} r={1} fill="orange" />
    <circle className={`resizable-ne`} cx={x + w} cy={y} r={1} fill="orange" />
    <circle className={`resizable-sw`} cx={x} cy={y + h} r={1} fill="orange" />
    <circle
      className={`resizable-se`}
      cx={x + w}
      cy={y + h}
      r={1}
      fill="orange"
    />
  </g>
)
