export const getCurrentDateTimeFr = () => {
  const currentDate = new Date()
  // Format the date and time in French format
  const formattedDate = currentDate.toLocaleString('fr-FR', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
  })
  // Replace non-alphanumeric characters with underscores
  return formattedDate.replace(/[^\w]/g, '_')
}

/**
 * can handle nested objects recursively
 * @param {object[]} arr object wrapped in an array
 * @returns {string[]}
 */
export const objectToString = (arr) => {
  var mp = new Map()

  function setValue(a, path, val) {
    if (Object(val) !== val) {
      // primitive value
      var pathStr = path.join(".")
      var i = (mp.has(pathStr) ? mp : mp.set(pathStr, mp.size)).get(pathStr)
      a[i] = val
    } else {
      for (var key in val) {
        setValue(a, key === "0" ? path : path.concat(key), val[key])
      }
    }
    return a
  }

  var result = arr.map((obj) => setValue([], [], obj))
  return [[...mp.keys()], ...result]
}

/**
 *
 * @param {string[]} arr recommended to use the result of the function "objectToString"
 * @returns string (csv format)
 */
export const stringToCsv = (arr) => {
  return arr
    .map((row) =>
      row.map((val) => (isNaN(val) ? JSON.stringify(val) : +val)).join(`,`)
    )
    .join("\n")
}

/**
 * @param {string} csv recommended to use the result of the function "objectToString"
 * @param {*} filename name of the downloaded file
 */
export const downloadCSV = (csv, filename) => {
  const exportedFilename = `${filename}_${getCurrentDateTimeFr()}.csv` || "export.csv"
  let blob = new Blob([csv], { type: "text/csv;charset=utf-8;" })
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, exportedFilename)
  } else {
    let link = document.createElement("a")
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      let url = URL.createObjectURL(blob)
      link.setAttribute("href", url)
      link.setAttribute("download", exportedFilename)
      link.style.visibility = "hidden"
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
}

/**
 * DOES NOT handle nested objects recursively, recommended to use only flat object
 * @param {object[]} arr object wrapped in an array
 * @returns {string} csv format
 */
export const jsonToCSV = (json_data) => {
  const json_array = json_data
  let csv_string = ""
  const titles = Object.keys(json_array[0])
  titles.forEach((title, index) => {
    csv_string += index !== titles.length - 1 ? `${title},` : `${title}\r\n`
  })
  json_array.forEach((content, index) => {
    let row = ""
    for (let title in content) {
      row += row === "" ? `'${content[title]}'` : `,'${content[title]}'`
    }
    csv_string += index !== json_array.length - 1 ? `${row}\r\n` : `${row}`
  })
  return csv_string
}

export const parseClipboard = (stringified) => {
  try {
    return JSON.parse(stringified)
  } catch (e) {
    return {}
  }
}

export const isUrl = (str) => {
  try {
    new URL(str)
  } catch (_) {
    return false
  }
  return true
}

export const isIndex = (variable) => {
  return typeof variable === "number" && variable >= 0
}

export const getShortenedLabel = (label, size) => {
  let computeShortenLabel = label ? label.length : 0 > size
  let shortenedLabel = computeShortenLabel
    ? label.slice(0, size / 2) + ".." + label.slice(-size / 2)
    : label
  return shortenedLabel
}

/**
 * move elements inside an array by index.
 * @param {any[]} arr immutable array
 * @param {number} from index
 * @param {number} to index
 * @returns a new array with its elements moved
 */
export const moveInArray = (arr, from, to) => {
  return arr.reduce((prev, current, idx, self) => {
    if (from === to) {
      prev.push(current)
    }
    if (idx === from) {
      return prev
    }
    if (from < to) {
      prev.push(current)
    }
    if (idx === to) {
      prev.push(self[from])
    }
    if (from > to) {
      prev.push(current)
    }
    return prev
  }, [])
}
