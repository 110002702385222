import React from "react"
import { withRouter } from "react-router-dom"
import { compose } from "recompose"

import { AuthUserContext } from "../Authentication/Session/Session"
import { withFirebase } from "../Authentication/Firebase"
import { withAuthorization } from "../Authentication/Session/Session"

import * as ROUTES from "../Constants/Routes"
import { HMFileIO } from "../Common/HMFileIO"

import Snackbar from "../Snackbar/Snackbar"
import CloseButton from "../Common/CloseButton"
import { extractDevicesData } from "../Device/DeviceMgr"

const EditProject = (props) => (
  <div>
    <div className="container-wrapper">
      <div className="container">
        <div className="jumbotron text-center">
          <CloseButton
            itemId={!props.creation ? props.match.params.id : null}
          />
          <h2 className="text-center">
            {props.creation ? "Add Project" : "Edit Project"}
          </h2>
          <Edit {...props} />
        </div>
      </div>
    </div>
  </div>
)

class EditUserProject extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      group_id: "",
      name: "",
      description: "",
      folderId: "",
      deviceId: "",
    }
  }

  onChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleSubmit = (event) => {
    const { group_id, name, description, folderId } = this.state
    let projectId = null
    const currentUser = this.props.firebase.auth.currentUser
    if (this.props.creation) {
      if (currentUser) {
        projectId = this.props.firebase.project().push().key
        const userId = currentUser.uid
        // create a folder on hmk server and save its ID in fb db
        HMFileIO(userId)
          .createRootFolder(projectId, {
            projectId: projectId,
            projectName: name,
          })
          .then((dir) => {
            this.props.firebase.project(projectId).set({
              name: name,
              description: description,
              folder_id: dir.id,
              user_id: userId,
              group_id: group_id,
              created: this.props.firebase.TIMESTAMP,
              last_updated: this.props.firebase.TIMESTAMP,
            })
            extractDevicesData(
              userId,
              this.props.firebase,
              this.props.filterByGroup ? group_id : userId,
              this.props.filterByGroup,
              dir.id
            )
          })
      }
    } else {
      projectId = this.props.match.params.id
      HMFileIO(currentUser.uid)
        .updateFolderProjectMetadata(folderId, { projectName: name })
        .then((dir) => {
          this.props.firebase.project(projectId).update({
            name: name,
            description: description,
            last_updated: this.props.firebase.TIMESTAMP,
          })
        })
    }
    const snackbarComponent = document.getElementById("snackbar")
    snackbarComponent.className = "show"
    const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms))
    wait(1000).then(() =>
      this.props.history.push(
        {
          pathname: ROUTES.getHomeRoute(currentUser.uid),
          state: projectId
        }
      )
    )
    event.preventDefault()
  }

  componentDidMount() {
    if (!this.props.creation) {
      const projectId = this.props.match.params.id
      this.props.firebase
        .project(projectId)
        .once("value")
        .then((snap) => {
          this.setState({
            name: snap.val().name,
            description: snap.val().description,
            folderId: snap.val().folder_id,
            deviceId: snap.val().device_id,
          })
        })
    }
    // load user group
    this.props.firebase
      .groups(this.props.firebase.auth.currentUser.uid)
      .once("value", (snap) => {
        if (snap.val()) {
          this.setState({ group_id: snap.val()[0] })
        }
      })
  }

  render() {
    return (
      <AuthUserContext.Consumer>
        {() => (
          <div>
            <form className="userForm" onSubmit={this.handleSubmit}>
              <div className="form-group">
                <label className="offset-lg-3 col-lg-6 col-form-label">
                  Name*
                </label>
                <div className="offset-lg-3 col-lg-6">
                  <input
                    defaultValue={this.state.name}
                    type="text"
                    className="form-control"
                    name="name"
                    onChange={this.onChange}
                    required
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="offset-lg-3 col-lg-6 col-form-label">
                  Description
                </label>
                <div className="offset-lg-3 col-lg-6">
                  <textarea
                    defaultValue={this.state.description}
                    className="form-control"
                    name="description"
                    rows={5}
                    onChange={this.onChange}
                  />
                </div>
              </div>
              <button className="btn hmk-form-btn" type="submit">
                Ok
              </button>
            </form>
            <Snackbar
              message={
                this.props.creation
                  ? "The project has been created"
                  : "The project has been updated"
              }
            />
          </div>
        )}
      </AuthUserContext.Consumer>
    )
  }
}

const Edit = compose(withRouter, withFirebase)(EditUserProject)

const condition = (currentUser) => !!currentUser

export default withAuthorization(condition)(EditProject)
