import React, { useMemo, useState, useEffect } from "react"
import { AiFillTool } from "react-icons/ai"
import { HMFileIO } from "../../Common/HMFileIO"

const ContentCardIcon = ({ uid, isListLayout, card, icon }) => {
  const [picture, setPicture] = useState(null)
  const [imageValidity, setImageValidity] = useState(false)

  useEffect(() => {
    if (card.picture_id) {
      HMFileIO(uid)
        .loadFile(card.picture_id, true)
        .then((pic) => {
          setPicture(pic)
          setImageValidity(true)
        })
    }
  }, [uid, card.picture_id])

  const pictureStyle = useMemo(() => {
    let style = {}
    if (picture && picture["metadata"] && picture["metadata"]["rotation"]) {
      // Rotation Style
      style.transform =
        "rotate(" + parseInt(picture["metadata"]["rotation"]) * 90 + "deg)"
    }
    return style
  }, [picture])

  return (
    <>
      {picture ? (
        <img
          className={
            isListLayout ? "content-list-icon" : "image d-block img-fluid"
          }
          src={picture.datafile}
          alt={picture.name}
          style={pictureStyle}
        />
      ) : imageValidity && card.image ? (
        <img
          className={
            isListLayout ? "content-list-icon" : "image d-block img-fluid"
          }
          src={card.image}
          alt={card.name}
        />
      ) : icon ? (
        <div className={isListLayout ? "" : "text-center"}>{icon}</div>
      ) : (
        <div className={isListLayout ? "" : "text-center"}>
          <AiFillTool
            size={`${isListLayout ? 40 : 100}px`}
            style={{ color: "#ffffff" }}
          />
        </div>
      )}
    </>
  )
}

export default ContentCardIcon
