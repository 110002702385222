import React, { useContext } from "react"

import { AuthUserContext } from "../Authentication/Session/Session"
import { withFirebase } from "../Authentication/Firebase"
import { withRouter } from "react-router-dom"
import { compose } from "recompose"
import { withAuthorization } from "../Authentication/Session/Session"

import { ContentStoreCtx } from "../Store/ContentStore"

import { FiEdit2 } from "react-icons/fi"
import ReactTooltip from "react-tooltip"

const EditSlideContent = (props) => {
  const [, dispatch] = useContext(ContentStoreCtx)

  const editContent = () => {
    dispatch({ type: "VIEW_MODE", payload: "content" })
  }

  return (
    <AuthUserContext.Consumer>
      {() => (
        <div
          className="nav-link navbar-btn-action"
          data-for="rtt_navbar_edit_content"
          data-tip="Edit Slide (E)"
        >
          <FiEdit2 onClick={() => editContent(props)} />
          <ReactTooltip
            id="rtt_navbar_edit_content"
            effect="solid"
            place="bottom"
          />
        </div>
      )}
    </AuthUserContext.Consumer>
  )
}

const condition = (currentUser) => !!currentUser

export default withAuthorization(condition)(
  compose(withRouter, withFirebase)(EditSlideContent)
)
