import React from "react"
import { HiOutlineStatusOffline, HiOutlineStatusOnline } from "react-icons/hi"
import { MdOutlineOnlinePrediction } from "react-icons/md"

const StatusIndicator = ({ viewerOnlineStatus, sizeInPixels }) => {
  return (
    <div className="snap-widget-container">
      {viewerOnlineStatus === "offline" ? (
        <HiOutlineStatusOffline
          size={`${sizeInPixels}px`}
          style={{ color: "#FF6464" }}
        />
      ) : viewerOnlineStatus === "online" ? (
        <HiOutlineStatusOnline
          size={`${sizeInPixels}px`}
          style={{ color: "#4EC9B0" }}
        />
      ) : viewerOnlineStatus === "processing" ? (
        <MdOutlineOnlinePrediction
          size={`${sizeInPixels}px`}
          style={{ color: "#169FFF" }}
        />
      ) : (
        <></>
      )}
    </div>
  )
}

export default StatusIndicator
