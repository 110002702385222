import { HMFileIO } from "../Common/HMFileIO"

const DEBUG = false

const checkSlideMedia = (uid, item) =>
  HMFileIO(uid)
    .checkPictures(item.content.map((c) => c.part_url))
    .then((picDatas) =>
      Promise.all(
        picDatas.map(
          (picData, id) =>
            new Promise(async (resolve, reject) => {
              if (picData) {
                item.content[id].part_picture_id = picData.id
                // manage rotation information from hmk db server
                if (
                  !item.content[id].angle &&
                  picData.metadata &&
                  picData.metadata.rotation
                ) {
                  item.content[id].angle =
                    parseInt(picData.metadata.rotation) * 90
                }
                // manage geom_compo information from hmk db
                if (picData.metadata && picData.metadata.geom_compo_id) {
                  const geomCompo = await HMFileIO(uid)
                    .loadFile(picData.metadata.geom_compo_id)
                    .then((file) => file)
                  DEBUG &&
                    console.log(
                      `On picture ${picData.name} got a geom compo named ${geomCompo.name}`
                    )
                  item.content[id].compo_url = geomCompo.datafile
                  resolve(item)
                } else resolve(item)
              } else resolve(item)
            })
        )
      )
    )

const checkContentMedia = (uid, content) =>
  HMFileIO(uid)
    .checkPicture(content.part_url)
    .then((picData) => {
      if (picData) {
        content.part_picture_id = picData.id
        // manage rotation information from hmk db server
        if (!content.angle && picData.metadata && picData.metadata.rotation) {
          content.angle = parseInt(picData.metadata.rotation) * 90
        }
        // manage geom_compo information from hmk db
        if (picData.metadata && picData.metadata.geom_compo_id) {
          return HMFileIO(uid)
            .loadFile(picData.metadata.geom_compo_id)
            .then((file) => {
              content.compo_url = file.datafile
              return content
            })
        }
      }
      return content
    })

export { checkSlideMedia, checkContentMedia }
