const DEV_URL = "http://127.0.0.1:8000/"
const WS_DEV_URL = "ws://127.0.0.1:8000/" //WS_PROD_URL //"ws://127.0.0.1:8000/"

const SSL_VM_URL = "https://server.holomake.fr/"
const WSS_PROD_URL = "wss://server.holomake.fr/"

const SSL_CLOGS_URL = "https://clogs.holomake.fr/"
const WSS_CLOGS_URL = "wss://clogs.holomake.fr/"

const DEV_EDITOR_URL = "http://localhost:3001/?project="
const PROD_EDITOR_URL = "https://editor.holomake.fr/?project="

const DEV_VIEWER_URL = "http://localhost:3002"
//const PROD_VIEWER_URL = "https://www.holomake.fr/webapp/"
const PROD_VIEWER_URL = "https://webview.holomake.fr/"
// setup custom viewer url for dev branch version
const PROD2_VIEWER_URL = "https://webcameraoverlayviewer-git-dev-holo-make.vercel.app/"

const SERVER_URL = process.env.NODE_ENV === "production" ? SSL_VM_URL : DEV_URL
const EDITOR_URL =
  process.env.NODE_ENV === "production" ? PROD_EDITOR_URL : DEV_EDITOR_URL
const VIEWER_URL =
  process.env.NODE_ENV === "production" ? PROD_VIEWER_URL : DEV_VIEWER_URL
const VIEWER_DEV_URL = PROD2_VIEWER_URL

const CLOG_TOKEN = "y6zDsYuzaUXZxUWKmB28c4zNlRs1"

const openFileManager = (projectId, authToken, args = undefined) => {
  let url = EDITOR_URL + projectId
  if (authToken) url += "&token=" + authToken
  if (args && args.length > 0) url += args
  window.open(url, EDITOR_URL + projectId)
}
  
const getWS_URL = (uid) => process.env.NODE_ENV !== "production" ? WS_DEV_URL : uid === CLOG_TOKEN ? WSS_CLOGS_URL : WSS_PROD_URL

const getSERVER_URL = (uid) => process.env.NODE_ENV !== "production" ? DEV_URL : uid === CLOG_TOKEN ? SSL_CLOGS_URL : SERVER_URL

const getUPLOAD_URL = (uid) => getSERVER_URL(uid) + "upload/"

const getDIRECTORY_URL = (uid) => getSERVER_URL(uid) + "directory/"
  
const getCLOG_URL = (uid) => getSERVER_URL(uid) + "binpacking/logs/"

const getPOSE_TEMPLATES_URL = (uid) => getSERVER_URL(uid) + "media/targets/"
  
const getCAMERAS_URL = (uid) => getSERVER_URL(uid) + "media/cameras/"

export {
  getWS_URL,
  getSERVER_URL,
  getUPLOAD_URL,
  getDIRECTORY_URL,
  getPOSE_TEMPLATES_URL,
  getCAMERAS_URL,
  VIEWER_URL,
  VIEWER_DEV_URL,
  getCLOG_URL,
  CLOG_TOKEN,
  openFileManager,
}
