import React, {
  createContext,
  useReducer,
  useState,
  useRef,
  useEffect,
} from "react"
import { HMKWebSocket } from "../Common/WebSocket"
import SlideReducer, { initialState } from "./SlideReducer"
import SnackbarWrapper from "../Snackbar/SnackbarWrapper"

const DEBUG = false

const createWS = (userId) => {
  const ws = HMKWebSocket()
  ws.init(userId)
  return ws
}

const initialAuthContext = {
  currentUser: null,
  userGroups: [],
  uid: "",
  role: "",
  username: "",
}

const ContentStore = ({ children, firebase }) => {
  const [state, dispatch] = useReducer(SlideReducer, initialState)
  const [incrementalRefresh, setIncrementalRefresh] = useState(0)
  const [authContext, setAuthContext] = useState(initialAuthContext)
  const [webSocket, setWebSocket] = useState(null)
  const [viewerList, setViewerList] = useState([])
  const snackbarRef = useRef(null)
  const viewerListCache = useRef([])

  // THIS EFFECT ONLY FOR WEB SOCKET TEST PURPOSE
  useEffect(() => {
    if (webSocket && authContext.uid) {
      DEBUG && console.debug("WebSocket changed for user ", authContext.uid)
      const disconnectViewerList = (param) => {
        /**
         * @param {deviceID:string} param
         */
        viewerListCache.current = viewerListCache.current.filter(
          (v) => v.deviceID !== param.deviceID
        )
        setViewerList(viewerListCache.current)
      }
      const upsertViewerList = (param) => {
        /**
         * @param {deviceID:string} param
         */
        const dup_idx = viewerListCache.current.findIndex(
          (v) => v.deviceID === param.deviceID
        )
        if (dup_idx < 0) {
          viewerListCache.current.push(param)
        } else {
          viewerListCache.current[dup_idx] = param
        }
        setViewerList(viewerListCache.current)
      }
      const cleanupViewerList = () => {
        viewerListCache.current = viewerListCache.current.filter((v) => {
          const time_diff = Date.now() - v.timestamp
          return time_diff < 5000
        })
      }
      const updateViewerList = (param) => {
        /**
         * @param {deviceID:string, folderID: string, projectFolderID: string} param
         */
        upsertViewerList(param)
        cleanupViewerList()
        setViewerList(viewerListCache.current)
      }
      webSocket.on("viewer_reg", updateViewerList)
      webSocket.on("device_connect", upsertViewerList)
      webSocket.on("device_disconnect", disconnectViewerList)
    }
  }, [webSocket, authContext.uid])

  useEffect(() => {
    const initializeAuthContext = async (currentUser) => {
      if (!currentUser) {
        setAuthContext(initialAuthContext)
        return
      }
      const group_snapshot = await firebase
      .groups(currentUser.uid)
      .once("value")
      const user_metadata_snapshot = await firebase
      .user(currentUser.uid)
      .once("value")
      // retrieve company data
      const user_company = await firebase.company(currentUser.uid).once("value")
      const groups = group_snapshot.val()
      const metadata = user_metadata_snapshot.val()
      const company = user_company.val()
      let authContext = {
        currentUser: currentUser,
        userGroups: groups,
        uid: currentUser.uid,
        username: metadata?.username,
        role: metadata?.role,
        modules: company?.modules,
      }
      DEBUG && console.log(authContext)
      setAuthContext(authContext)
    }
    const listener = firebase.auth.onAuthStateChanged((currentUser) => {
      initializeAuthContext(currentUser)
      if (currentUser) {
        setWebSocket(createWS(currentUser.uid))
      } else if (webSocket) {
        webSocket.sendMsg("CLOSE", "CLOSE")
        setWebSocket(null)
      }
    })
    return () => listener()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (authContext.currentUser && !authContext.currentUser.emailVerified) {
      const interval = setInterval(() => {
        const start = Date.now()
        authContext.currentUser.reload().then(() => {
          DEBUG && console.log("reloaded user in ", Date.now() - start, "ms")
          setIncrementalRefresh((prev) => prev + 1)
        })
      }, 3000)
      return () => {
        clearInterval(interval)
      }
    } else {
      DEBUG && console.log("already verified email")
    }
  }, [authContext.emailVerified, authContext.currentUser])

  const displaySnackbar = (payload) => {
    if (snackbarRef.current) {
      snackbarRef.current.addMessage(payload)
    } else {
      DEBUG && console.log("uncaught error : snackbarRef is missing")
    }
  }

  return (
    <GlobalContext.Provider
      value={{
        authContext: authContext,
        webSocket: webSocket,
        viewerList: viewerList,
        globalRefresh: () => setIncrementalRefresh((prev) => prev + 1),
        incrementalRefresh: incrementalRefresh,
        displaySnackbar: displaySnackbar,
      }}
    >
      <ContentStoreCtx.Provider value={[state, dispatch]}>
        {children}
      </ContentStoreCtx.Provider>
      <SnackbarWrapper ref={snackbarRef} />
    </GlobalContext.Provider>
  )
}

export const ContentStoreCtx = createContext(initialState)
export const GlobalContext = createContext(null)
export default ContentStore
