import React from "react"
import { withFirebase } from "../Authentication/Firebase"
import { Link, withRouter } from "react-router-dom"
import { compose } from "recompose"
import * as ROUTES from "../Constants/Routes"
import { GoX } from "react-icons/go"
import "./Verification.css"

const VerificationEmail = () => (
  <div>
    <div className="container-wrapper">
      <div className="container">
        <div className="jumbotron bg-dark text-center">
          <Link to="/">
            <GoX className="closeIcon" />
          </Link>
          <UserEmail />
        </div>
      </div>
    </div>
  </div>
)

class Verification extends React.Component {
  onClick = () => {
    this.props.firebase.doSendEmailVerification().then(() => {
      this.props.history.push(ROUTES.LANDING)
    })
  }

  render() {
    return (
      <div>
        <p>
          Email confirmation sent : please check your email (Spam folder
          included) for a confirmation email.
        </p>
        <p>If you didn't receive the email please click on the button below.</p>
        <button className="btn btn-primary" onClick={this.onClick}>
          Send confirmation email
        </button>
      </div>
    )
  }
}

const UserEmail = compose(
  withRouter,
  withFirebase
)(Verification)

export default VerificationEmail
