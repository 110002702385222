import React, { useEffect, useState, useContext } from "react"

import * as ROUTES from "../Constants/Routes"
import { HMFileIO } from "../Common/HMFileIO"
import Footer from "../Common/Footer/Footer"
import { AuthUserContext } from "../Authentication/Session/Session"
import { withAuthorization } from "../Authentication/Session/Session"
import { openFileManager } from "../Constants/UrlConfig"
import "./Home.css"
import ProjectCard from "./ProjectCard"
import { deviceFullList } from "../Device/DeviceMgr"
import { objectToString, stringToCsv, downloadCSV } from "../Common/utils"
import { GlobalContext } from "../Store/ContentStore"
import { runProjection } from "../Common/HMKViewer"
import Modal from "../Modals/Modal"

const DEBUG = false

const Home = (props) => {
  const [user_id, setUserID] = useState("")
  const [userRole, setUserRole] = useState("")
  const [userGroups, setUserGroups] = useState([])
  const [devices, setDevices] = useState([])
  const [projects, setProjects] = useState([])
  const [contents, setContents] = useState([])
  const [modalState, setModalState] = useState(null)
  const { displaySnackbar } = useContext(GlobalContext)

  /**
   * @param {string|string[]} message
   */
  const fireNotification = (message) => {
    if (!displaySnackbar) return
    displaySnackbar({ message: message })
  }

  useEffect(() => {
    const listener = props.firebase.auth.onAuthStateChanged((currentUser) => {
      DEBUG && console.log("HOME user login: ", currentUser.uid)
      setUserID(currentUser.uid)
      props.firebase.user(currentUser.uid).once("value", (snapshot) => {
        if (snapshot.val()) {
          setUserRole(snapshot.val().role)
        }
      })
      // special case, group "HoloMake" has key "-MvzkhJ-HT7p8e26eVEJ"
      // for this group, force filterByGroup = false
      props.firebase.groups(currentUser.uid).once("value", (snapshot) => {
        const groups = snapshot.val()
        if (groups) {
          if (groups.length > 0 && groups[0] === "-MvzkhJ-HT7p8e26eVEJ")
            props.setFilterByGroup(false)
          setUserGroups(groups)
        }
      })
    })
    return () => listener()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (props.filterByGroup && userGroups.length < 1) return
    // retrieve user projects
    const user_projects = props.firebase
      .projects()
      .orderByChild(props.filterByGroup ? "group_id" : "user_id")
      .equalTo(props.filterByGroup ? userGroups[0] : user_id)
    user_projects.on("value", (snapshot) => {
      if (snapshot) {
        let allProjects = []
        snapshot.forEach((snap) => {
          allProjects.unshift({
            key: snap.key,
            name: snap.val().name,
            folder_id: snap.val().folder_id,
            device_id: snap.val().device_id,
          })
        })
        setProjects(allProjects)
        DEBUG && console.log({ origin: "Home.js", allProjects: allProjects })
      }
    })
    // retrieve user contents
    const contents = props.firebase
      .contents()
      .orderByChild(props.filterByGroup ? "group_id" : "user_id")
      .equalTo(props.filterByGroup ? userGroups[0] : user_id)
    contents.on("value", (snapshot) => {
      if (snapshot) {
        let user_contents = []
        snapshot.forEach((snap) => {
          user_contents.unshift({
            id: snap.key,
            name: snap.val().name,
            description: snap.val().description,
            image: snap.val().image,
            picture_id: snap.val().picture_id,
            usecase: snap.val().usecase,
            project_id: snap.val().project_id,
            link: snap.val().link,
          })
        })
        setContents(user_contents)
        DEBUG &&
          console.log({ origin: "Home.js", user_contents: user_contents })
      }
    })
    // retrieve user devices
    if (user_id && userGroups) {
      deviceFullList(
        props.firebase,
        props.filterByGroup ? userGroups[0] : user_id,
        props.filterByGroup
      ).then((allDevices) => {
        // sort devices by type
        allDevices.sort((a, b) => {
          if (a.type === b.type) return 0
          if (a.type === "MONITOR" || b.type === "PROJECTOR") return -1
          if (a.type === "PROJECTOR" || b.type === "MONITOR") return 1
          return 0
        })
        setDevices(allDevices)
        DEBUG && console.log({ origin: "Home.js", allDevices: allDevices })
      })
    }
    return () => {
      user_projects.off()
      contents.off()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.filterByGroup, userGroups, user_id])

  const addContent = (projectId) => {
    const url = ROUTES.CONTENT.replace(/:id/g, projectId)
    props.history.push(url)
  }

  const setCurrentDevice = async (project, device) => {
    await HMFileIO(user_id).updateFolderProjectMetadata(project.folder_id, {
      deviceId: device.key,
    })
    await props.firebase.project(project.key).update({ device_id: device.key })
  }

  const renameProject = (event) => {
    const projectId = event.currentTarget.value
    const url = ROUTES.EDIT_PROJECT.replace(/:id/g, projectId)
    props.history.push(url)
  }

  const deleteProject = (event) => {
    if (!user_id) {
      DEBUG && console.log("no user ID !")
      return
    }
    const projectId = event.currentTarget.value
    const projectName = event.currentTarget.id
    if (!projectId) {
      DEBUG && console.log("no project ID !")
    }
    const trigger_delete_project = async (pid) => {
      if (!pid) {
        DEBUG && console.log("no project ID !")
        return
      }
      const project_ref = props.firebase.project(pid)
      if (project_ref) {
        try {
          await project_ref.remove()
          const snapshot = await props.firebase
            .contents()
            .orderByChild(props.filterByGroup ? "group_id" : "user_id")
            .equalTo(props.filterByGroup ? userGroups[0] : user_id)
            .once("value")
          await snapshot.forEach((snap) => {
            if (snap.val().project_id === pid) {
              const key_content = snap.key
              props.firebase.content(key_content).remove()
            }
          })
          fireNotification("Successfully deleted project")
        } catch (error) {
          fireNotification(JSON.stringify(error))
        } finally {
          setModalState(false)
        }
      } else {
        DEBUG && console.log("project data couldn't be fetched")
      }
    }
    setModalState({
      display: true,
      title: `Deleting Project ${projectName ? ": " + projectName : ""}`,
      message:
        "Are you sure you want to delete this project and all contents ?",
      onCancel: () => setModalState(false),
      onSubmit: () => {
        trigger_delete_project(projectId)
      },
    })
  }

  const deleteContent = (event) => {
    if (!user_id) return
    const contentId = event.currentTarget.value
    const contentName = event.currentTarget.id
    if (!contentId) {
      DEBUG && console.log("no content ID !")
    }
    const trigger_delete_content = async (cid) => {
      if (!cid) {
        DEBUG && console.log("no content ID !")
        return
      }
      const content_ref = props.firebase.content(cid)
      if (content_ref) {
        try {
          await content_ref.remove()
          fireNotification("Successfully deleted work instruction")
        } catch (error) {
          fireNotification(JSON.stringify(error))
        } finally {
          setModalState(false)
        }
      } else {
        DEBUG && console.log("content couldn't be fetched")
      }
    }
    setModalState({
      display: true,
      title: `Deleting Content ${contentName ? ": " + contentName : ""}`,
      message: "Are you sure you want to delete this work instruction ?",
      onCancel: () => setModalState(false),
      onSubmit: () => {
        trigger_delete_content(contentId)
      },
    })
  }

  const onExportCSV = async (event) => {
    const contentId = event.currentTarget.value
    try {
      const obj = await props.firebase.content(contentId).once("value")
      if (obj.exists && obj.val) {
        const data = obj.val()
        let filename = data.name
        let sequence = data.sequence
        let table = [
          {
            project_id: data.project_id,
            user_id: data.user_id,
            name: data.name,
            picture_id: data.picture_id,
            comment: data.comment,
            description: data.description,
            created: data.created,
            last_updated: data.last_updated,
            image: data.image,
          },
        ]
        if (sequence) {
          table[0]["sequence"] = {
            version: data.sequence.version,
            template: data.sequence.template,
            slides: data.sequence.slides,
          }
        }
        downloadCSV(stringToCsv(objectToString(table)), filename)
      }
    } catch (e) {
      console.log(JSON.stringify(e))
    }
  }

  const editContent = (event) => {
    const contentId = event.currentTarget.value
    const url = ROUTES.EDIT_CONTENT.replace(/:id/g, contentId)
    props.history.push(url)
  }

  const editSlides = (cardId) => {
    const url = ROUTES.EDIT_SLIDES.replace(/:id/g, cardId)
    DEBUG && console.log(`SlideEditor : call URL ${url}`)
    props.history.push(url)
  }

  const editProjectFiles = async (projectKey) => {
    const openHMKEditor = async (folderId) => {
      openFileManager(folderId, await props.firebase.doGetIdToken())
    }
    DEBUG && console.log(`Edit Project Files for project:`)
    const project = projects.find((p) => p.key === projectKey)
    if (!project.key || !project.name) {
      DEBUG && console.log("error - no project key or name")
      return
    }
    if (!project.folder_id) {
      // if folder doesn't exists, create it here
      // create a folder on hmk server and save its ID in fb db
      const dir = await HMFileIO(user_id).createRootFolder(project.key, {
        projectId: project.key,
        projectName: project.name,
      })
      props.firebase.project(project.key).update({
        folder_id: dir.id,
      })
      openHMKEditor(dir.id)
    } else {
      openHMKEditor(project.folder_id)
    }
  }

  const copyWI = async (event) => {
    if (!user_id) {
      return
    }
    const contentId = event.currentTarget.value
    const newContent = await props.firebase.contents().push()
    const snap = await props.firebase.content(contentId).once("value")
    let payload = { ...snap.val() }
    payload.name = "Copy of " + payload.name
    await newContent.set(payload)
    fireNotification(["Successfully copied work instruction :", payload.name])
  }

  const projectWI = async ({ card_id, device_id }) => {
    const result = await runProjection(card_id, device_id, props.firebase, devices, user_id)
    if (result !== null) {
      fireNotification(result)
    }
  }

  return (
    <AuthUserContext.Consumer>
      {() => (
        <>
          <div className="container-wrapper">
            <div className="container">
              {projects.map((project) => (
                <ProjectCard
                  {...props}
                  userRole={userRole}
                  userId={user_id}
                  project={project}
                  contents={contents}
                  devices={devices}
                  onProject={projectWI}
                  onCopyContent={copyWI}
                  onRenameProject={renameProject}
                  onAddContent={addContent}
                  onDeleteProject={deleteProject}
                  onExportCSV={onExportCSV}
                  onEditContent={editContent}
                  onCardClick={editSlides}
                  onDeleteContent={deleteContent}
                  onEditProjectFiles={editProjectFiles}
                  onChangeDevice={setCurrentDevice}
                  key={project.key}
                />
              ))}
            </div>
          </div>
          <Footer />
          {modalState ? <Modal {...modalState} /> : <></>}
        </>
      )}
    </AuthUserContext.Consumer>
  )
}

const condition = (currentUser) => !!currentUser

export default withAuthorization(condition)(Home)
