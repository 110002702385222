import React from "react"
import { Link } from "react-router-dom"

const Footer = () => {
  return (
    <footer className="footer text-center">
      <div className="container">
        <span>
          &copy; {new Date().getFullYear()} HoloMake SAS. All rights reserved -{" "}
          <Link to="/legal_notices" className="legalNotices" target="_blank">
            Mentions Légales
          </Link>{" "}
          -{" "}
          <Link to="/cgvu" className="legalNotices" target="_blank">
            CGVU
          </Link>
        </span>
      </div>
    </footer>
  )
}

export default Footer
