import React from "react"

import { Link } from "react-router-dom"

import { GoDeviceDesktop } from "react-icons/go"
import ReactTooltip from "react-tooltip"

import { DEVICES } from "../Constants/Routes"

const NewDevice = () => {
  return (
    <Link
      to={DEVICES}
      className="nav-link navbar-btn-action"
      data-for="rtt_navbar_device"
      data-tip="Devices"
    >
      <GoDeviceDesktop />
      <ReactTooltip id="rtt_navbar_device" effect="solid" place="bottom" />
    </Link>
  )
}

export default NewDevice
