import React from "react"
import { withFirebase } from "../Firebase"
import { withRouter } from "react-router-dom"
import { compose } from "recompose"
import * as ROUTES from "../../Constants/Routes"

class SignOutButton extends React.Component {
  onClick = () => {
    this.props.firebase
      .doSignOut()
      .then(() => this.props.history.push(ROUTES.LANDING))
  }

  render() {
    return (
      <div>
        <button
          className="btn hmk-form-btn"
          type="button"
          onClick={this.onClick}
        >
          Sign Out
        </button>
      </div>
    )
  }
}

export default compose(withFirebase, withRouter)(SignOutButton)
