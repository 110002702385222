import { getPOSE_TEMPLATES_URL, getCAMERAS_URL } from "../Constants/UrlConfig"

const DEBUG = false

const CAMERA_PARAMETERS = {}
const POSE_SETUPS = {
  CAMERA_FRAME_POSITION_ORIENTATION: {
    id: "CAMERA_FRAME_POSITION_ORIENTATION",
    estimation_mode: "CAMERA_FRAME_POSITION_ORIENTATION",
    mode_camera_frame_position_orientation: {
      location_meters: [0.0, 0.0, -0.2],
      quaternion_xyzw: [-0.5, 0.0, 0.0, 0.866],
    },
  },
}

const POSE_RESULT = {
  error_residual: 9999.0,
  opengl_mvm: [
    1.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0,
    1.0,
  ],
  pre_translation_vector_m: [0.0, 0.0, 0.0],
  object_location: [0.0, 0.0, 0.0],
  quaternion_xyzw: [0.0, 0.0, 0.0, 1.0],
}

const downloadPoseTemplate = (uid, mode) => {
  const filename = mode + ".pdf"
  const poseTemplateUrl = getPOSE_TEMPLATES_URL(uid) + filename
  var xhr = new XMLHttpRequest()
  xhr.open("GET", poseTemplateUrl, true)
  xhr.responseType = "blob"
  xhr.onload = function () {
    var urlCreator = window.URL || window.webkitURL
    var imageUrl = urlCreator.createObjectURL(this.response)
    var tag = document.createElement("a")
    tag.href = imageUrl
    tag.download = filename
    tag.click()
  }
  xhr.send()
  return poseTemplateUrl
}

/**
 * Fetch CAMERAS predefined devices from HMK data server.
 * @returns an array with list of cameras and list of camera instrinsic parameters
 */
const fetchCameras = async (uid) => {
  let cameras = []
  let parameters = {}
  try {
    const camerasUrl = getCAMERAS_URL(uid) + "cameras.json"
    const response = await fetch(camerasUrl)
    cameras = await response.json()
    DEBUG && console.log("Fetched cameras = ", cameras)
  } catch (e) {
    console.error("Error fetching Camera list from server: ", e)
    return [[], {}]
  }
  const cameraList = []
  cameras = cameras.cameras
  await Promise.all(
    cameras.map(async (camera) => {
      try {
        const cameraName = Object.keys(camera)[0]
        const paramUrl = getCAMERAS_URL(uid) + cameraName + ".json"
        const response = await fetch(paramUrl)
        if (response.ok) {
          const param = await response.json()
          DEBUG && console.log("Fetched parameters = ", param)
          parameters[cameraName] = { ...camera[cameraName], ...param }
          cameraList.push(cameraName)
        } else {
          // fallback to local definition ?
          if (cameraName in CAMERA_PARAMETERS) {
            DEBUG &&
              console.log(
                "Fetched parameters = ",
                CAMERA_PARAMETERS[cameraName]
              )
            parameters[cameraName] = {
              ...cameras[cameraName],
              ...CAMERA_PARAMETERS[cameraName],
            }
            cameraList.push(cameraName)
          }
        }
      } catch (e) {
        console.error(`Error fetching Camera ${camera} from server: ${e}`)
        return [[], {}]
      }
    })
  )
  // very special case added manually
  cameraList.unshift("Use Picture Taken From The Camera")
  cameraList.unshift("Predefined")

  return [cameraList, parameters]
}

const updateCameraFromDeviceJson = (deviceJson, camera, params) => {
  let device = {}
  if (deviceJson.length > 0) {
    try {
      let deviceObj = JSON.parse(deviceJson)
      device = {
        ...deviceObj,
        ...params[camera],
      }
    } catch (error) {
      console.log("JSON validation error : ", error)
    }
  } else {
    device = {
      utc_time: new Date().toDateString(),
      ...params[camera],
    }
  }
  return JSON.stringify(device, null, "\t")
}

/**
 * Fetch POSE_ESTIMATION templates from HMK data server.
 * @returns an array with list of pose modes and list of template setup description
 */
const fetchPoseTemplates = async (uid) => {
  let poseModes = []
  let poseSetups = {}
  try {
    const poseModesUrl = getPOSE_TEMPLATES_URL(uid) + "targets.json"
    const response = await fetch(poseModesUrl)
    poseModes = await response.json()
    DEBUG && console.log("Fetched pose mode = ", poseModes)
  } catch (e) {
    console.error("Error fetching PoseMode list from server: ", e)
    return [[], {}]
  }
  let poseOnError = []
  poseModes = poseModes.targets
  await Promise.all(
    poseModes.map(async (mode) => {
      try {
        const setupModeUrl = getPOSE_TEMPLATES_URL(uid) + mode + "_estimation_mode.json"
        const response = await fetch(setupModeUrl)
        if (response.ok) {
          const setup = await response.json()
          setup.id = mode
          DEBUG && console.log("Fetched setup = ", setup)
          poseSetups[mode] = setup
        } else {
          // fallback to local definition ?
          if (mode in POSE_SETUPS) {
            DEBUG && console.log("Fetched setup = ", POSE_SETUPS[mode])
            poseSetups[mode] = POSE_SETUPS[mode]
          } else {
            poseOnError.push(mode)
          }
        }
      } catch (e) {
        console.error(`Error fetching PoseMode ${mode} from server: ${e}`)
        return [[], {}]
      }
    })
  )
  // remove faulty pose templates
  poseModes = poseModes.filter((mode) => !poseOnError.includes(mode))
  // very special case added manually
  poseModes.unshift("CAMERA_FRAME_POSITION_ORIENTATION")
  poseSetups["CAMERA_FRAME_POSITION_ORIENTATION"] =
    POSE_SETUPS["CAMERA_FRAME_POSITION_ORIENTATION"]

  return [poseModes, poseSetups]
}

const getPoseFromDeviceJson = (deviceJson) => {
  let pose = undefined
  if (deviceJson.length > 0) {
    try {
      let deviceObj = JSON.parse(deviceJson)
      pose = deviceObj.pose_setup.id
    } catch (error) {
      console.log("JSON validation error : ", error)
    }
  }
  return pose
}

const updatePoseFromDeviceJson = (deviceJson, pose, setups) => {
  let device = {}
  if (deviceJson.length > 0) {
    try {
      let deviceObj = JSON.parse(deviceJson)
      device = {
        ...deviceObj,
        pose_setup: { ...setups[pose] },
        pose_result: { ...POSE_RESULT },
      }
    } catch (error) {
      console.log("JSON validation error : ", error)
    }
  } else {
    device = {
      utc_time: new Date().toDateString(),
      pose_setup: { ...setups[pose] },
      pose_result: { ...POSE_RESULT },
    }
  }
  return JSON.stringify(device, null, "\t")
}

const buildDeviceJson = (exif, pose, setups) => {
  return exif.length === 3
    ? {
        utc_time: new Date().toDateString(),
        camera_v: exif[1],
        camera_b: exif[2],
        pose_setup: { ...setups[pose] },
        pose_result: { ...POSE_RESULT },
      }
    : {
        utc_time: new Date().toDateString(),
        pose_setup: { ...setups[pose] },
      }
}

/**
 * Process EXIF data coming from a JPG picture.
 * Cf JS code from project https://github.com/exif-js/exif-js
 * All EXIF field code are in exif.js file.
 * @param {*} tags
 * @returns [tags, camera_v json, camera_b json]
 */
const processExifData = (tags) => {
  // EXIF Version
  // 36864 0x9000 EXIF version
  const version = tags.ExifVersion

  // DEVICE ID
  // 271 0x10f TiffTags Make
  const brand = tags.Make
  // 272 0x110 TiffTags Model
  const model = tags.Model
  // build a device name
  let name = model.toLowerCase().startsWith(brand.toLowerCase())
    ? model
    : brand[0].toUpperCase() + brand.slice(1) + " " + model

  // DEVICE FOCAL
  // 41989 0xA405 Equivalent focal length assuming 35mm film camera (in mm)
  // 37386 0x920a Focal length of the lens in mm
  // use 35mm focal value by default, use standard focal otherwise
  const focalLength =
    "FocalLengthIn35mmFilm" in tags
      ? tags.FocalLengthIn35mmFilm
      : tags.FocalLength.valueOf()

  // IMAGE CONFIGURATION
  // 40962 0xa002 Valid width of meaningful image
  const width = tags.PixelXDimension
  // 40963 0xa003 Valid height of meaningful image
  const height = tags.PixelYDimension

  // update name with resolution and focal
  name = name + ` ${width}x${height} F${focalLength}`

  // FOCAL PLANE
  let focalPlaneWidth = 36.0 // because we use focal length for 36mm (35)
  let focalPlaneHeight = (focalPlaneWidth * height) / width
  if ("FocalPlaneXResolution" in tags) {
    // 41486 0xa20e Number of pixels in width direction
    focalPlaneWidth = tags.FocalPlaneXResolution.valueOf()
    // 41487 0xa20f Number of pixels in height direction
    focalPlaneHeight = tags.FocalPlaneYResolution.valueOf()
    // 41488 0xa210 Unit for measuring FocalPlane
    const focalPlaneUnit = tags.FocalPlaneResolutionUnit
    if (focalPlaneUnit === 2) {
      // inches
      focalPlaneWidth = (25.4 * width) / focalPlaneWidth
      focalPlaneHeight = (25.4 * height) / focalPlaneHeight
    } else if (focalPlaneUnit === 3) {
      // centimeters
      focalPlaneWidth = (10 * width) / focalPlaneWidth
      focalPlaneHeight = (10 * height) / focalPlaneHeight
    }
  }

  // CAMERA COMPUTATION
  const px = (focalLength * width) / focalPlaneWidth
  const py = (focalLength * height) / focalPlaneHeight

  const kud = 0.0
  const kdu = 0.0
  const camera_v = {
    name: name,
    type: "PERSP",
    image_width: width,
    image_height: height,
    sensor_width: focalPlaneWidth,
    px: px,
    py: py,
    u0: width / 2,
    v0: height / 2,
    kud: kud,
    kdu: kdu,
  }

  const shiftX = 0.0
  const shiftY = 0.0
  const camera_b = {
    name: name,
    type: "PERSP",
    render_width: width,
    render_height: height,
    sensor_width: focalPlaneWidth,
    lens: focalLength,
    shift_x: shiftX,
    shift_y: shiftY,
  }

  return [tags, camera_v, camera_b]
}

export {
  downloadPoseTemplate,
  fetchCameras,
  fetchPoseTemplates,
  processExifData,
  buildDeviceJson,
  getPoseFromDeviceJson,
  updatePoseFromDeviceJson,
  updateCameraFromDeviceJson,
}
