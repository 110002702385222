import React from 'react';

import Footer from '../Footer/Footer';

const NoRouteMatch = () => {
    return (
        <div>
            <div className="container-wrapper">
                <div className="container">
                    <div className="jumbotron bg-dark text-center">
                        <h2>404</h2>
                        <p className="lead">The resource you requested was not found.</p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default NoRouteMatch;