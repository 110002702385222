import { instanceOf } from "prop-types"
import React, { Component } from "react"
import { withCookies, Cookies } from "react-cookie"
import { Tabs, Tab } from "react-bootstrap-tabs"
import { withRouter } from "react-router-dom"
import { compose } from "recompose"
import validator from "validator"

import { ForgotPasswordLink } from "./ForgotPassword/ForgotPassword"
import { withFirebase } from "./Firebase"

import * as ROUTES from "../Constants/Routes"

class Authentication extends Component {
  render() {
    return (
      <div className="auth-panel text-center">
        <Tabs className="auth-tabs">
          <Tab label="Register">
            <SignUpForm />
          </Tab>
          <Tab label="Login">
            <SignInForm />
            <ForgotPasswordLink closeModal={this.props.closeModal} />
          </Tab>
        </Tabs>
      </div>
    )
  }
}

class SignIn extends Component {
  render() {
    return (
      <div className="auth-panel text-center">
        <Tabs className="auth-tabs">
          <Tab label="Login">
            <SignInForm />
            <ForgotPasswordLink closeModal={this.props.closeModal} />
          </Tab>
          {/**
          <Tab label="Register">
            <SignUpForm />
          </Tab>
          */}
        </Tabs>
      </div>
    )
  }
}

const SIGN_IN_INITIAL_STATE = {
  email: "",
  password: "",
  formErrors: { email: "", password: "" },
  emailValid: false,
  passwordValid: false,
  formValid: false,
  error: null,
  rememberMe: false,
}

class SignInFormBase extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  }

  constructor(props) {
    super(props)
    this.state = { ...SIGN_IN_INITIAL_STATE }
  }

  componentDidMount() {
    const { cookies } = this.props
    this.setState({
      email: cookies.get("email") || "",
      rememberMe: cookies.get("rememberMe") === "true" || false,
    })
  }

  validateField(fieldName, value) {
    let formErrors = this.state.formErrors
    let emailValid = this.state.emailValid
    let passwordValid = this.state.passwordValid

    switch (fieldName) {
      case "email":
        emailValid = validator.isEmail(value)
        formErrors.email = emailValid ? "" : "Email is invalid."
        break
      case "password":
        passwordValid = value.length >= 6
        formErrors.password = passwordValid ? "" : "Password is too short."
        break
      default:
        break
    }
    this.setState(
      {
        formErrors: formErrors,
        emailValid: emailValid,
        passwordValid: passwordValid,
      },
      this.validateForm
    )
  }

  validateForm() {
    this.setState({
      formValid:
        (this.state.emailValid || this.state.email !== "") &&
        this.state.passwordValid,
    })
  }

  errorClass(error) {
    return error === "" ? "" : "has-error"
  }

  onSubmit = (event) => {
    const { email, password, rememberMe } = this.state
    const { cookies } = this.props

    cookies.set("rememberMe", rememberMe, { path: "/" })
    if (rememberMe) {
      cookies.set("email", email, { path: "/" })
    } else {
      cookies.set("email", "", { path: "/" })
    }

    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then((data) => {
        this.props.history.push(ROUTES.getHomeRoute(data.user.uid))
        this.props.firebase.doGetIdToken()
      })
      .then((token) => {
        localStorage.setItem("hmk_fb_auth_token", token)
      })
      .catch((error) => {
        this.setState({ error: "Invalid Credentials" })
      })
    event.preventDefault()
  }

  onChange = (event) => {
    const name = event.target.name
    const value = event.target.value
    this.setState({ [name]: value }, () => {
      this.validateField(name, value)
    })
  }

  onChangeRememberMe = (event) => {
    this.setState({
      rememberMe: !this.state.rememberMe,
    })
  }

  render() {
    const { email, password, formValid, error, rememberMe } = this.state

    return (
      <form onSubmit={this.onSubmit} className="authentication-form">
        <div className="form-group">
          {error && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}
        </div>
        <div className="form-group">
          <div className={`${this.errorClass(this.state.formErrors.email)}`}>
            <input
              className="form-control"
              name="email"
              value={email}
              onChange={this.onChange}
              type="email"
              placeholder="Email address"
            />
            <small className="form-error form-text text-left">
              <p>{this.state.formErrors.email}</p>
            </small>
          </div>
        </div>
        <div className="form-group">
          <div className={`${this.errorClass(this.state.formErrors.password)}`}>
            <input
              className="form-control"
              name="password"
              value={password}
              onChange={this.onChange}
              type="password"
              placeholder="Password"
            />
            <small className="form-error form-text text-left">
              <p>{this.state.formErrors.password}</p>
            </small>
          </div>
        </div>
        <div className="form-group">
          <input
            className="form-check-input"
            name="rememberMe"
            onChange={this.onChangeRememberMe}
            type="checkbox"
            checked={rememberMe}
          />
          Remember me
        </div>
        <div className="form-group">
          <button className="btn" disabled={!formValid} type="submit">
            Log in
          </button>
        </div>
      </form>
    )
  }
}

const SIGN_UP_INITIAL_STATE = {
  username: "",
  company: "",
  email: "",
  passwordOne: "",
  passwordTwo: "",
  formErrors: { username: "", email: "", passwordOne: "", passwordTwo: "" },
  usernameValid: false,
  emailValid: false,
  passwordOneValid: false,
  passwordTwoValid: false,
  formValid: false,
  isMounted: false,
  error: null,
  role: "user",
}

class SignUpFormBase extends Component {
  constructor(props) {
    super(props)
    this.state = { ...SIGN_UP_INITIAL_STATE }
  }

  validateField(fieldName, value) {
    let formErrors = this.state.formErrors
    let usernameValid = this.state.usernameValid
    let emailValid = this.state.emailValid
    let passwordOneValid = this.state.passwordOneValid
    let passwordTwoValid = this.state.passwordTwoValid

    switch (fieldName) {
      case "username":
        usernameValid = value.length >= 2
        formErrors.username = usernameValid ? "" : "Full name is too short."
        break
      case "email":
        emailValid = validator.isEmail(value)
        formErrors.email = emailValid ? "" : "Email is invalid."
        break
      case "passwordOne":
        passwordOneValid = value.length >= 6
        formErrors.passwordOne = passwordOneValid
          ? ""
          : "Password is too short."
        break
      case "passwordTwo":
        passwordTwoValid = value === this.state.passwordOne
        formErrors.passwordTwo = passwordTwoValid
          ? ""
          : "The passwords don't match."
        break
      default:
        break
    }
    this.setState(
      {
        formErrors: formErrors,
        usernameValid: usernameValid,
        emailValid: emailValid,
        passwordOneValid: passwordOneValid,
        passwordTwoValid: passwordTwoValid,
      },
      this.validateForm
    )
  }

  validateForm() {
    this.setState({
      formValid:
        this.state.usernameValid &&
        this.state.emailValid &&
        this.state.passwordOneValid &&
        this.state.passwordTwoValid,
    })
  }

  errorClass(error) {
    return error === "" ? "" : "has-error"
  }

  onSubmit = (event) => {
    const { username, company, email, passwordOne, role } = this.state
    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then((authUser) => {
        this.props.firebase.user(authUser.user.uid).set({
          username,
          email,
          role,
        })
        const companyKey = this.props.firebase
          .company(authUser.user.uid)
          .push().key
        this.props.firebase.company(authUser.user.uid).set({
          id: companyKey,
          name: company,
          holomake: "",
        })
        if (company === "HoloMake") {
          this.props.firebase.company(authUser.user.uid).update({
            holomake: true,
          })
          this.props.firebase.user(authUser.user.uid).update({
            role: "admin",
          })
        } else if (company === null || company !== "HoloMake") {
          this.props.firebase.company(authUser.user.uid).update({
            holomake: false,
          })
          this.props.firebase.user(authUser.user.uid).update({
            role: "user",
          })
        }
      })
      .then(() => {
        this.props.firebase.doSendEmailVerification().then(() => {
          this.props.history.push(ROUTES.VERIFICATION)
        })
        // })
      })
      .catch((error) => {
        this.setState({ error })
      })
    event.preventDefault()
  }

  onChange = (event) => {
    const name = event.target.name
    const value = event.target.value
    this.setState({ [name]: value }, () => {
      this.validateField(name, value)
    })
  }

  render() {
    const {
      username,
      company,
      email,
      passwordOne,
      passwordTwo,
      formValid,
      error,
    } = this.state

    return (
      <form onSubmit={this.onSubmit} className="authentication-form">
        <div className="form-group">
          {error && (
            <div className="alert alert-danger" role="alert">
              {error.message}
            </div>
          )}
        </div>
        <div className="form-group">
          <div className="form-group">
            <div
              className={`${this.errorClass(this.state.formErrors.username)}`}
            >
              <input
                className="form-control"
                name="username"
                value={username}
                onChange={this.onChange}
                type="text"
                placeholder="Full name"
              />
              <small className="form-error form-text text-left">
                <p>{this.state.formErrors.username}</p>
              </small>
            </div>
          </div>
          <div className="form-group">
            <div>
              <input
                className="form-control"
                name="company"
                value={company}
                onChange={this.onChange}
                type="text"
                placeholder="Company"
                required
              />
            </div>
          </div>
          <div className="form-group">
            <div className={`${this.errorClass(this.state.formErrors.email)}`}>
              <input
                className="form-control"
                name="email"
                value={email}
                onChange={this.onChange}
                type="email"
                placeholder="Email"
              />
              <small className="form-error form-text text-left">
                <p>{this.state.formErrors.email}</p>
              </small>
            </div>
          </div>
          <div className="form-group">
            <div
              className={`${this.errorClass(
                this.state.formErrors.passwordOne
              )}`}
            >
              <input
                className="form-control"
                name="passwordOne"
                value={passwordOne}
                onChange={this.onChange}
                type="password"
                placeholder="Password"
              />
              <small className="form-error form-text text-left">
                <p>{this.state.formErrors.passwordOne}</p>
              </small>
            </div>
          </div>
          <div className="form-group">
            <div
              className={`${this.errorClass(
                this.state.formErrors.passwordTwo
              )}`}
            >
              <input
                className="form-control"
                name="passwordTwo"
                value={passwordTwo}
                onChange={this.onChange}
                type="password"
                placeholder="Confirm password"
              />
              <small className="form-error form-text text-left">
                <p>{this.state.formErrors.passwordTwo}</p>
              </small>
            </div>
          </div>
        </div>
        <div className="form-group">
          <button className="btn" disabled={!formValid} type="submit">
            Register now
          </button>
        </div>
      </form>
    )
  }
}

const SignInForm = compose(
  withRouter,
  withFirebase,
  withCookies
)(SignInFormBase)
const SignUpForm = compose(withRouter, withFirebase)(SignUpFormBase)

export { Authentication, SignIn }
