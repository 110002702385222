import React, { useState, useEffect, useContext, useMemo } from "react"
import { FiPlus, FiList, FiGrid } from "react-icons/fi"
import ReactTooltip from "react-tooltip"

import { HMFileIO } from "../Common/HMFileIO"
import { runProjection } from "../Common/HMKViewer"
import { DEVICE, EDIT_DEVICE } from "../Constants/Routes"
import Footer from "../Common/Footer/Footer"
import { withAuthorization } from "../Authentication/Session/Session"
import ContentCard, { ContentActionCard } from "../Components/ContentCard"
import {
  DEVICE_TYPES,
  DEVICES_INITIAL_STATE,
  DeviceTypeIcons,
  extractDevicesData,
  deviceListByType,
  getLayoutDirKeysPerDeviceTypes,
} from "./DeviceMgr"
import "../Home/Home.css"
import { GlobalContext } from "../Store/ContentStore"
import Modal from "../Modals/Modal"
import { useCookies } from "react-cookie"

const DEBUG = false

const Devices = (props) => {
  const { authContext, displaySnackbar } = useContext(GlobalContext)
  const [modalState, setModalState] = useState(null)
  const [devices, setDevices] = useState(DEVICES_INITIAL_STATE)
  const [cookies, setCookies] = useCookies([
    ...getLayoutDirKeysPerDeviceTypes(),
    "layout_dir_global",
  ])

  const useGlobalLayoutDirection = useMemo(() => {
    return (
      cookies["layout_dir_global"] === "row" ||
      cookies["layout_dir_global"] === "column"
    )
  }, [cookies])

  const layoutDirections = useMemo(() => {
    let directions = {}
    if (useGlobalLayoutDirection) {
      for (let i = 0; i < DEVICE_TYPES.length; i++) {
        directions[`layout_dir_${DEVICE_TYPES[i]}`] =
          cookies["layout_dir_global"]
      }
    } else {
      for (let i = 0; i < DEVICE_TYPES.length; i++) {
        if (
          cookies[`layout_dir_${DEVICE_TYPES[i]}`] === "row" ||
          cookies[`layout_dir_${DEVICE_TYPES[i]}`] === "column"
        ) {
          directions[`layout_dir_${DEVICE_TYPES[i]}`] =
            cookies[`layout_dir_${DEVICE_TYPES[i]}`]
        } else {
          directions[`layout_dir_${DEVICE_TYPES[i]}`] = "row"
        }
      }
    }
    return directions
  }, [cookies, useGlobalLayoutDirection])

  /**
   * @param {string|string[]} message
   */
  const fireNotification = (message) => {
    if (!displaySnackbar) return
    displaySnackbar({ message: message })
  }

  useEffect(() => {
    if (
      authContext.userGroups.length > 0 &&
      authContext.userGroups[0] === "-MvzkhJ-HT7p8e26eVEJ"
    ) {
      props.setFilterByGroup(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authContext])

  useEffect(() => {
    if (!authContext.uid || !authContext.userGroups.length) return
    deviceListByType(
      props.firebase,
      props.filterByGroup ? authContext.userGroups[0] : authContext.uid,
      props.filterByGroup
    ).then((allDevices) => setDevices(allDevices))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.filterByGroup, authContext.userGroups, authContext.uid])

  const onAddDevice = (cat) => {
    props.history.push({ pathname: DEVICE, category: cat })
  }

  const onEditDevice = (id, cat) => {
    const url = EDIT_DEVICE.replace(/:id/g, id)
    props.history.push({ pathname: url, category: cat })
  }

  const onCopyDevice = async (id, cat) => {
    if (!authContext.uid || !authContext.userGroups.length) {
      return
    }
    try {
      // new device key
      const newDevice = await props.firebase.devices().push()
      // duplicate the device 'id'
      const snap = await props.firebase.devices(id).once("value")
      const newName = "Copy of " + snap.val().device.name
      newDevice.set(snap.val())
      await props.firebase.device(newDevice.key).update({ name: newName })

      // update Device data everywhere
      extractDevicesData(
        authContext.uid,
        props.firebase,
        props.filterByGroup ? authContext.userGroups[0] : authContext.uid,
        props.filterByGroup
      )
      fireNotification("Successfully copied device")
    } catch (e) {
      console.log(e)
    } finally {
      props.history.push({ pathname: DEVICE })
    }
  }

  const onDeleteDevice = async (id, deviceName) => {
    const key = id
    if (!authContext.uid || !key) return
    const trigger_delete_device = async (did) => {
      if (!did || !props.firebase) return
      const device_ref = props.firebase.devices(did)
      if (device_ref) {
        try {
          await device_ref.remove()
          // go through all projects and check current device
          const snapshot = await props.firebase
            .projects()
            .orderByChild(props.filterByGroup ? "group_id" : "user_id")
            .equalTo(
              props.filterByGroup ? authContext.userGroups[0] : authContext.uid
            )
            .once("value")
          snapshot.forEach((snap) => {
            if (snap.val().device_id === did) {
              HMFileIO(authContext.uid)
                .updateFolderProjectMetadata(snap.val().folder_id, {
                  deviceId: "",
                })
                .then(() => {
                  props.firebase.project(snap.key).update({
                    device_id: "",
                  })
                })
            }
          })
          extractDevicesData(
            authContext.uid,
            props.firebase,
            props.filterByGroup ? authContext.userGroups[0] : authContext.uid,
            props.filterByGroup
          )
          fireNotification("Successfully deleted device")
        } catch (error) {
          fireNotification(JSON.stringify(error))
        } finally {
          setModalState(false)
          props.history.push({ pathname: DEVICE })
        }
      } else {
        DEBUG && console.log("device data couldn't be fetched")
      }
    }
    setModalState({
      display: true,
      title: `Deleting Device ${deviceName ? ": " + deviceName : ""}`,
      message: "Are you sure you want to delete this device ?",
      onCancel: () => setModalState(false),
      onSubmit: () => {
        trigger_delete_device(key)
      },
    })
  }

  const onLaunchViewer = async ({ card_id, device_id }) => {
    const deviceList = [].concat.apply([], Object.values(devices))
    const result = await runProjection(card_id, device_id, props.firebase, deviceList, authContext.uid)
    if (result !== null) {
      fireNotification(result)
    }
  }

  return (
    <div>
      <div className="container-wrapper">
        {DEVICE_TYPES.map((cat) => (
          <div className="container" key={cat}>
            <nav className="navbar mt-4">
              <div>
                <div className="project-name">{cat + "S"}</div>
                <button
                  onClick={() => onAddDevice(cat)}
                  className="home-icon"
                  data-for="rtt_devices_add_device"
                  data-tip={"Add " + cat[0] + cat.slice(1).toLowerCase()}
                >
                  <FiPlus size="25px" />
                  <ReactTooltip
                    id="rtt_devices_add_device"
                    effect="solid"
                    place="bottom"
                  />
                </button>
                <>
                  {useGlobalLayoutDirection ? (
                    <></>
                  ) : layoutDirections[`layout_dir_${cat}`] === "row" ? (
                    <button
                      className="home-icon"
                      onClick={() => setCookies(`layout_dir_${cat}`, "column")}
                      data-for={`layout_dir_${cat}_col`}
                      data-tip="List Layout"
                    >
                      <FiList size="25px" />
                      <ReactTooltip
                        id={`layout_dir_${cat}_col`}
                        effect="solid"
                        place="bottom"
                      />
                    </button>
                  ) : (
                    <button
                      className="home-icon"
                      onClick={() => setCookies(`layout_dir_${cat}`, "row")}
                      data-for={`layout_dir_${cat}_row`}
                      data-tip="Grid Layout"
                    >
                      <FiGrid size="25px" />
                      <ReactTooltip
                        id={`layout_dir_${cat}_row`}
                        effect="solid"
                        place="bottom"
                      />
                    </button>
                  )}
                </>
              </div>
            </nav>
            <div className="container">
              <div className={layoutDirections[`layout_dir_${cat}`]}>
                {layoutDirections[`layout_dir_${cat}`] === "row" ? (
                  <ContentActionCard
                    direction={layoutDirections[`layout_dir_${cat}`]}
                    id="card-add-content"
                    caption={"Add " + cat}
                    onClick={() => onAddDevice(cat)}
                  />
                ) : (
                  <></>
                )}
                {devices[cat].map((device) => (
                  <ContentCard
                    direction={layoutDirections[`layout_dir_${cat}`]}
                    userRole={authContext.role}
                    uid={authContext.uid}
                    key={device.key}
                    card={device}
                    icon={
                      <DeviceTypeIcons
                        type={cat}
                        sizeInPixels={
                          layoutDirections[`layout_dir_${cat}`] === "row"
                            ? 100
                            : 40
                        }
                        color={"#ffffff"}
                      />
                    }
                    defaultDevice={device}
                    onProject={onLaunchViewer}
                    onEditContent={() => onEditDevice(device.key, cat)}
                    onCardClick={() => onEditDevice(device.key, cat)}
                    onCopyContent={() => onCopyDevice(device.key, cat)}
                    onDeleteContent={() =>
                      onDeleteDevice(device.key, device.name)
                    }
                    />
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
      <Footer />
      {modalState ? <Modal {...modalState} /> : <></>}
    </div>
  )
}

const condition = (currentUser) => !!currentUser

export default withAuthorization(condition)(Devices)
