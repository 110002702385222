import React from "react"
import { AuthUserContext } from "../Authentication/Session/Session"
import { withAuthorization } from "../Authentication/Session/Session"
import DisplayMetrics from "./DisplayMetrics"
import CloseButton from "../Common/CloseButton"
import { deviceList } from "../Device/DeviceMgr"

class MetricsDevice extends React.Component {
  constructor() {
    super()
    this.state = {
      allDevices: [],
      key: "",
      display: false,
    }
  }

  onChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  onClick = () => {
    this.setState({ display: true })
  }

  closeDisplayMetrics = () => {
    this.setState({ display: false })
  }

  componentDidMount() {
    this.listener = this.props.firebase.auth.onAuthStateChanged(
      (currentUser) => {
        deviceList(this.props.firebase, currentUser.uid, false).then(
          (allDevices) => this.setState({ allDevices: allDevices })
        )
      }
    )
  }

  componentWillUnmount() {
    this.listener()
  }

  render() {
    if (this.state.display === false) {
      return (
        <AuthUserContext.Consumer>
          {() => (
            // <div>
            <div className="container-wrapper">
              <div className="container">
                <div className="jumbotron text-center">
                  <CloseButton />
                  <h2 className="text-center">Devices*</h2>
                  <div className="form-group offset-sm-4 col-sm-4 offset-md-3 col-md-6 col-lg-6 offset-xl-4 col-xl-4">
                    <select
                      className="form-control"
                      name="key"
                      onChange={this.onChange}
                      required
                    >
                      <option>Please select a device</option>
                      {this.state.allDevices.map((device) => {
                        return (
                          <option value={device.key} key={device.key}>
                            {device.name}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                  <button
                    className="hmk-form-btn btn mt-2"
                    onClick={this.onClick}
                  >
                    Display metrics{" "}
                  </button>
                </div>
              </div>
              <div />
            </div>
          )}
        </AuthUserContext.Consumer>
      )
    } else if (this.state.display === true) {
      return (
        <AuthUserContext.Consumer>
          {() => (
            <div>
              <DisplayMetrics
                id={this.state.key}
                onClick={this.closeDisplayMetrics}
              />
            </div>
          )}
        </AuthUserContext.Consumer>
      )
    }
  }
}

const condition = (currentUser) => !!currentUser

export default withAuthorization(condition)(MetricsDevice)
