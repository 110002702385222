import React, { useLayoutEffect, useEffect, useState } from "react"
import "./Snackbar.css"

let lifeCycle = 0

const SnackbarMessage = (props) => {
  const [render, setRender] = useState(true)
  useLayoutEffect(() => {
    animate(lifeCycle)
    return () => {
      lifeCycle++
      setRender(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.message, props.loading])

  useEffect(() => {
    !render && setRender(true)
  }, [render])

  const animate = async (currentCycle) => {
    if (!props.loading) {
      await new Promise((resolve) =>
        setTimeout(resolve, props.lifetime || 2000)
      )
      if (lifeCycle !== currentCycle) {
        return
      }
      props.lifeCycleCallback && props.lifeCycleCallback()
    }
  }

  const Message = () => {
    if (typeof props.message === "string") {
      return <span>{props.message}</span>
    } else if (typeof props.message === "object") {
      return props.message.map((e, i) => (
        <React.Fragment key={i}>
          <span>{e}</span>
        </React.Fragment>
      ))
    } else {
      return <></>
    }
  }

  const renderItem = (
    <div
      id={props.id}
      className={`snackbar-message ${
        props.loading ? "animation-float" : "animation-floatUpwards"
      }`}
      style={{
        animationDuration: `${props.lifetime}ms` || "2000ms",
      }}
    >
      {props.loading ? loader : props.message && <Message />}
    </div>
  )
  return <>{render && renderItem}</>
}

const loader = (
  <svg
    version="1.1"
    id="loader-1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="40px"
    height="40px"
    viewBox="0 0 50 50"
    xmlSpace="preserve"
  >
    <path
      fill="orange"
      d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
    >
      <animateTransform
        attributeType="xml"
        attributeName="transform"
        type="rotate"
        from="0 25 25"
        to="360 25 25"
        dur="0.6s"
        repeatCount="indefinite"
      />
    </path>
  </svg>
)

export default SnackbarMessage
