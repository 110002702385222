import React, { Component } from "react"
import Modal from "react-modal"

import Footer from "../Common/Footer/Footer"
import * as ROUTES from "../Constants/Routes"

import { SignIn } from "../Authentication/Authentication"

import { withFirebase } from "../Authentication/Firebase"

const styles = {
  content: {
    borderRadius: "10px",
    borderColor: "orange",
    borderWidth: "3px",
    borderStyle: "solid",
    bottom: "auto",
    position: "absolute",
    right: "auto",
    maxHeight: "calc(100vh - 136px)",
    left: "50%",
    top: "106px",
    transform: "translateX(-50%)",
    width: "80%",
    maxWidth: "400px",
    backgroundColor: "#111",
  },
}

Modal.setAppElement(document.getElementById("app"))

class Landing extends Component {
  constructor() {
    super()

    this.state = {
      modalIsOpen: false,
    }

    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  openModal() {
    this.setState({ modalIsOpen: true })
  }

  closeModal() {
    this.setState({ modalIsOpen: false })
  }

  componentDidMount() {
    this.listener = this.props.firebase.auth.onAuthStateChanged(
      (currentUser) => {
        if (currentUser && currentUser.emailVerified === true)
          this.props.history.push(ROUTES.getHomeRoute(currentUser.uid))
      }
    )
  }

  componentWillUnmount() {
    this.listener && this.listener()
  }

  render() {
    return (
      <div>
        <div className="container-wrapper">
          <div className="container">
            <div className="jumbotron text-center">
              <h2>HoloMake|<span style={{ color: "orange" }}>Studio</span></h2>
              <p className="claim">
                Augmented Operations
              </p>
              <button
                className="btn btn-light btn-lg btn-signup"
                onClick={this.openModal}
              >
                Sign In
              </button>
              <Modal
                isOpen={this.state.modalIsOpen}
                onRequestClose={this.closeModal}
                style={styles}
                closeTimeoutMS={200}
              >
                <SignIn />
              </Modal>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}

export default withFirebase(Landing)
