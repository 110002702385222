import React from "react"
import { Link } from "react-router-dom"
import { HiOutlineStatusOnline } from "react-icons/hi"
import ReactTooltip from "react-tooltip"
import { VIEWER_MANAGER } from "../Constants/Routes"

const ViewerManagerIcon = () => {
  return (
    <Link
      to={VIEWER_MANAGER}
      className="nav-link navbar-btn-action"
      data-for="rtt_navbar_viewer_manager"
      data-tip="Viewer Manager"
    >
      <HiOutlineStatusOnline />
      <ReactTooltip
        id="rtt_navbar_viewer_manager"
        effect="solid"
        place="bottom"
      />
    </Link>
  )
}

export default ViewerManagerIcon
