import React from "react"
import { withRouter } from "react-router-dom"
import { compose } from "recompose"

import AuthUserContext from "./Context"
import { withFirebase } from "../Firebase"
import * as ROUTES from "../../Constants/Routes"

const withAuthorization = (condition) => (Component) => {
  class WithAuthorization extends React.Component {
    componentDidMount() {
      this.listener = this.props.firebase.auth.onAuthStateChanged(
        (authUser) => {
          if (!condition(authUser && authUser.emailVerified)) {
            this.props.history.push(ROUTES.LANDING)
          }
        }
      )
    }

    componentWillUnmount() {
      this.listener && this.listener()
    }

    render() {
      return (
        <AuthUserContext.Consumer>
          {(currentUser) =>
            condition(currentUser) ? <Component {...this.props} /> : null
          }
        </AuthUserContext.Consumer>
      )
    }
  }

  return compose(withRouter, withFirebase)(WithAuthorization)
}

export default withAuthorization
