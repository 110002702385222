import React from "react"

import * as ROUTES from "../Constants/Routes"
import { AuthUserContext } from "../Authentication/Session/Session"
import { withFirebase } from "../Authentication/Firebase"
import { withRouter } from "react-router-dom"
import { compose } from "recompose"
import { withAuthorization } from "../Authentication/Session/Session"
import CloseButton from "../Common/CloseButton"

const UserProfile = (props) => (
  <div>
    <div className="container-wrapper">
      <div className="container">
        <div className="jumbotron text-center">
          <CloseButton />
          <h2 className="text-center">Profile</h2>
          <UserCompany {...props} />
        </div>
      </div>
    </div>
  </div>
)

const UserModules = ({ modules }) => (
  <div className="form-group divProfile">
    <label className="offset-sm-4 col-sm-2 offset-md-3 offset-xl-4 col-form-label">
      Modules
    </label>
    <div className="offset-sm-4 col-sm-4 offset-md-3 col-md-6 col-lg-6 offset-xl-4 col-xl-4">
      <input
        type="text"
        className="form-control"
        name="module_name"
        defaultValue={modules[0].name + " - " + modules[0].expiry_date}
        readOnly={true}
      />
    </div>
  </div>
)

class Profile extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      company: "",
      username: "",
      email: "",
      modules: [],
    }
  }

  onChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  componentDidMount() {
    this.listener = this.props.firebase.auth.onAuthStateChanged(
      (currentUser) => {
        // retrieve user data
        this.props.firebase.user(currentUser.uid).once("value", (snapshot) => {
          this.setState({
            username: snapshot.val().username,
            email: snapshot.val().email,
          })
        })
        // retrieve company data
        this.props.firebase
          .company(currentUser.uid)
          .once("value", (snapshot) => {
            this.setState({
              company: snapshot.val().name,
              modules: snapshot.val().modules,
            })
          })
      }
    )
  }

  componentWillUnmount() {
    this.listener()
  }

  handleSubmit = (event) => {
    this.props.history.push(ROUTES.getHomeRoute(this.props.firebase.auth.currentUser.uid))
    event.preventDefault()
  }

  render() {
    return (
      <div>
        <AuthUserContext.Consumer>
          {() => (
            <div>
              <form onSubmit={this.handleSubmit} className="userForm">
                <div className="form-group divProfile">
                  <label className="offset-sm-4 col-sm-2 offset-md-3 offset-xl-4 col-form-label">
                    Name
                  </label>
                  <div className="offset-sm-4 col-sm-4 offset-md-3 col-md-6 col-lg-6 offset-xl-4 col-xl-4">
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      defaultValue={this.state.username}
                    />
                  </div>
                </div>
                <div className="form-group divProfile">
                  <label className="offset-sm-4 col-sm-2 offset-md-3 offset-xl-4 col-form-label">
                    Email
                  </label>
                  <div className="offset-sm-4 col-sm-4 offset-md-3 col-md-6 col-lg-6 offset-xl-4 col-xl-4">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      defaultValue={this.state.email}
                    />
                  </div>
                </div>
                <div className="form-group divProfile">
                  <label className="offset-sm-4 col-sm-2 offset-md-3 offset-xl-4 col-form-label">
                    Company
                  </label>
                  <div className="offset-sm-4 col-sm-4 offset-md-3 col-md-6 col-lg-6 offset-xl-4 col-xl-4">
                    <input
                      type="text"
                      className="form-control"
                      name="company"
                      defaultValue={this.state.company}
                    />
                  </div>
                </div>
                {this.state.modules && this.state.modules.length > 0 ? (
                  <UserModules modules={this.state.modules}></UserModules>
                ) : (
                  <></>
                )}
                <button className="btn hmk-form-btn" type="submit">
                  Ok
                </button>
              </form>
            </div>
          )}
        </AuthUserContext.Consumer>
      </div>
    )
  }
}

const UserCompany = compose(withRouter, withFirebase)(Profile)

const condition = (currentUser) => !!currentUser

export default withAuthorization(condition)(UserProfile)
