import {
  getUPLOAD_URL,
  getDIRECTORY_URL,
} from "../Constants/UrlConfig"

const DEBUG = false

export const getUrlDisplayFormat = (url) => {
  if (!url || !url.length) return url
  // remove the first part of the url 'til the file
  let result = url.split("/").pop()
  // remove the last part with hashed url arguments '#'
  result = result.split("#")[0]
  return result
}

export const HMFileIO = (uid) => {
  const USER = "holomake"

  const loadFolder = (key) => {
    const url = getDIRECTORY_URL(uid) + key
    if (DEBUG) console.log(`Will fetch folder ${url}`)
    return fetch(url)
      .then((resp) => resp.json())
      .catch((err) => console.log(`ERROR LOADING DIRECTORY : ${err.message}`))
  }

  const createFolder = (name, parentId, metadata = {}) => {
    const formData = new FormData()
    formData.append("owner", USER)
    if (parentId)
      formData.append("parent", parentId)
    formData.append("name", name)
    formData.append("metadata", JSON.stringify(metadata))
    // send a multipart/form-data
    return fetch(`${getDIRECTORY_URL(uid)}`, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((dir) => {
        if (DEBUG) console.log("directory created: ", dir)
        return {
          id: dir["id"],
          src: dir["name"],
          parent: dir["parent"],
        }
      })
      .catch((err) => console.log(`ERROR CREATING FOLDER : ${err.message}`))
  }

  const createRootFolder = (name, metadata = {}) => {
    return createFolder(name, null, metadata)
  }

  const updateFolderProjectMetadata = (folderId, metadata) => {
    return loadFolder(folderId)
      .then((dir) => {
        const new_md = { ...dir.metadata, ...metadata }
        const formData = new FormData()
        formData.append("metadata", JSON.stringify(new_md))
        // send a multipart/form-data
        const url = getDIRECTORY_URL(uid) + folderId + "/"
        return fetch(`${url}`, {
          method: "PATCH",
          body: formData,
        })
      })
      .then((res) => res.json())
      .then((dir) => {
        if (DEBUG) console.log(`directory ${JSON.stringify(dir)}`)
        return {
          id: dir["id"],
          src: dir["name"],
          parent: dir["parent"],
        }
      })
      .catch((err) => console.log(`ERROR UPDATING FOLDER : ${err.message}`))
  }

  const loadFileFromUrl = (fileUrl) => {
    const url = new URL(getUPLOAD_URL(uid) + "from_url/")
    const formData = new FormData()
    formData.append("url", fileUrl)
    return fetch(`${url}`, {
      method: "POST",
      body: formData,
    })
      .then((resp) => resp.json())
      .catch((err) => console.log(`ERROR LOADING FILE : ${err.message}`))
  }

  const loadFile = (key, silent=false) => {
    const url = getUPLOAD_URL(uid) + key
    return fetch(url)
      .then((resp) => resp.json())
      .catch((err) => silent ? "" : console.log(`ERROR LOADING FILE : ${err.message}`))
  }

  /**
   * checks if the image url is valid and sets state=> imgurl:string|"error"
   * Promise is required; simple URL class method cannot determine if the server can respond with the corresponding image.
   */
  const imageValidityCheck = async (url) => {
    try {
      let test = await fetch(url)
      return test.status === 200 ? true : false
    } catch (e) {
      return false
    }
  }

  const checkPictures = (images) => {
    return Promise.all(images.map((i) => checkPicture(i)))
  }

  const checkPicture = (image) => {
    return new Promise((resolve, reject) => {
      if (!image) {
        resolve(null)
      } else {
        loadFileFromUrl(image)
          .then((data) => {
            if (data.error) {
              reject(data.error)
            } else {
              if (DEBUG) console.log(`Found file: `, data)
              resolve(data)
            }
          })
          .catch((err) => reject(err))
      }
    })
  }

  const uploadFileXHR = (
    file,
    parentId,
    overwrite = false,
    metadata = {},
    thumbnail = null
  ) => {
    return new Promise((resolve, reject) => {
      const req = new XMLHttpRequest()

      req.upload.addEventListener("progress", (event) => {
        if (event.lengthComputable) {
          const copy = {} // { ...this.state.uploadProgress }
          copy[file.name] = {
            state: "pending",
            percentage: (event.loaded / event.total) * 100,
          }
          //this.setState({ uploadProgress: copy })
          if (DEBUG)
            console.log(`XHR: Upload Progress: ${JSON.stringify(copy)}`)
        }
      })

      req.addEventListener("loadend", (event) => {
        const copy = {} //{ ...this.state.uploadProgress }
        copy[file.name] = { state: "done", percentage: 100 }
        //this.setState({ uploadProgress: copy })
        const resp = JSON.parse(req.responseText)
        if (DEBUG)
          console.log(
            `XHR: Loaded done: ${JSON.stringify(
              copy
            )} and response is ${JSON.stringify(resp)}`
          )
        // TODO check the req.response object value !?
        const response = {
          id: resp["id"],
          src: resp["datafile"],
        }
        resolve(response)
      })

      req.addEventListener("error", (event) => {
        const copy = {} //{ ...this.state.uploadProgress }
        copy[file.name] = { state: "error", percentage: 0 }
        //this.setState({ uploadProgress: copy })
        console.log(`XHR: Load error: ${JSON.stringify(copy)}`)
        reject(req.responseText)
      })

      if (DEBUG) console.log("XHR: Will try to upload file " + file.name)
      const formData = new FormData()
      formData.append("datafile", file)
      formData.append("owner", USER)
      formData.append("parent", parentId)
      formData.append("overwrite", overwrite)
      formData.append("newname", overwrite)
      if (thumbnail) formData.append("thumbnail", JSON.stringify(thumbnail))
      formData.append("md", JSON.stringify(metadata))
      req.open("POST", getUPLOAD_URL(uid))
      req.send(formData)
    })
  }

  const deleteFolder = (key) => {
    const url = getDIRECTORY_URL(uid) + key
    // send a multipart/form-data
    return fetch(url, {
      method: "DELETE",
    })
      .then((res) => {
        if (DEBUG) console.log(`directory deleted : ${JSON.stringify(res)}`)
        return res
      })
      .catch((err) => console.log(`ERROR DELETING FOLDER : ${err.message}`))
  }

  const deleteFile = (key) => {
    const url = getUPLOAD_URL(uid) + key
    // send a multipart/form-data
    return fetch(url, {
      method: "DELETE",
    })
      .then((res) => {
        if (DEBUG) console.log(`file deleted : ${JSON.stringify(res)}`)
        return res
      })
      .catch((err) => console.log(`ERROR DELETING FILE : ${err.message}`))
  }

  return {
    loadFolder,
    createFolder,
    createRootFolder,
    updateFolderProjectMetadata,
    deleteFolder,
    loadFile,
    loadFileFromUrl,
    imageValidityCheck,
    checkPicture,
    checkPictures,
    uploadFileXHR,
    deleteFile,
  }
}
