import React, { useContext } from "react"

import { GrDocumentCsv } from "react-icons/gr"
import { FiPackage } from "react-icons/fi"
import ReactTooltip from "react-tooltip"
import { GlobalContext } from "../Store/ContentStore"
import { getCLOG_URL, CLOG_TOKEN } from "../Constants/UrlConfig"
import { downloadCSV } from "../Common/utils"

const BinPacking = ({ userId, module }) => {
  return (
    <a
      href={`${module.link}?auth=${userId}`}
      target="blank"
      className="nav-link navbar-btn-action"
      data-for="rtt_navbar_binpacking"
      data-tip={module.description}
    >
      <FiPackage />
      <ReactTooltip id="rtt_navbar_binpacking" effect="solid" place="bottom" />
    </a>
  )
}

const onExportCSV = async (uid, fireNotification) => {
  fireNotification("Preparing download of Logs as CSV file.")
  const url = `${getCLOG_URL(uid)}`
  const result = await fetch(url, {
    method: "GET",
    headers: {
      "Authorization": "Token " + CLOG_TOKEN,
    },
  })
  if (result.ok) {
    const blob = await result.blob()
    downloadCSV(blob, "logs")
  }
  else {
    console.log("Error during fetching LOGS data : ", result)
  }
}

const BinPackingLogs = ({ userId, module }) => {
  const { displaySnackbar } = useContext(GlobalContext)
  const fireNotification = (message) => {
    if (!displaySnackbar) return
    displaySnackbar({ message: message })
  }
  return (
    <div
      className="nav-link navbar-btn-action"
      data-for="rtt_navbar_binpackinglogs"
      data-tip="Download Logs As CSV"
      onClick={() => onExportCSV(userId, fireNotification)}
    >
      <GrDocumentCsv />
      <ReactTooltip id="rtt_navbar_binpackinglogs" effect="solid" place="bottom" />
    </div>
  )
}

export { BinPacking, BinPackingLogs }
