import React from "react"

const Rgpd = () => {
  return (
    <div>
      <div className="container-wrapper">
        <div className="container">
          <div className="jumbotron bg-dark text-center">
            <h2>DECLARATION DE CONFIDENTIALITE</h2>
            <h5 className="text-left mt-5">
              ARTICLE 1 : RENSEIGNEMENTS PERSONNELS RECUEILLIS
            </h5>
            <p className="text-justify">
              Lorsque vous naviguez sur notre site internet, nous sommes
              susceptible de recueillir des renseignements personnels que vous
              nous fournissez, tels que votre nom, votre adresse et votre
              adresse e-mail.
              <br />
              <br />
              Lorsque vous naviguez sur notre site internet, nous recevons
              également automatiquement l'adresse de protocole Internet (adresse
              IP) de votre ordinateur, qui nous permet d'obtenir plus de détails
              au sujet du navigateur et du système d'exploitation que vous
              utilisez.
              <br />
              <br />
              Marketing par e-mail : Avec votre permission, nous pourrions vous
              envoyer des e-mails au sujet de nos offres, de nos nouveaux
              produits et d'autres mises à jour.
            </p>
            <h5 className="text-left mt-5">ARTICLE 2 - CONSENTEMENT</h5>
            <p className="text-justify">
              Comment obtenez-vous mon consentement? <br />
              <br />
              Lorsque vous nous fournissez vos renseignements personnels pour
              une demande d'information, nous présumons que vous consentez à ce
              que nous recueillions vos renseignements et à ce que nous les
              utilisions à cette fin uniquement.
              <br />
              <br />
              Si nous vous demandons de nous fournir vos renseignements
              personnels pour une autre raison, à des fins de marketing par
              exemple, nous vous demanderons directement votre consentement
              explicite, ou nous vous donnerons la possibilité de refuser.
              Comment puis-je retirer mon consentement?
              <br />
              <br />
              Si après nous avoir donné votre consentement, vous changez d"avis
              et ne consentez plus à ce que nous puissions vous contacter,
              recueillir vos renseignements ou les divulguer, vous pouvez nous
              en aviser en nous contactant à info@holomake.fr ou par courrier à:
              HoloMake 24 Boulevard Verd de Saint Julien, MEUDON, 92190, France
            </p>
            <br />
            <h5 className="text-left">ARTICLE 3 - DIVULGATION</h5>
            <p className="text-justify">
              Nous pouvons divulguer vos renseignements personnels si la loi
              nous oblige à le faire ou si vous violez nos Conditions Générales
              de Vente et d'Utilisation.
              <br />
            </p>
            <br />
            <h5 className="text-left">
              ARTICLE 4 - ONLINE.NET, OVH Cloud et GOOGLE Inc
            </h5>
            <p className="text-justify">
              Notre site est hébergé en partie chez Online.net, chez OVH Cloud
              et chez Google Inc. Ils nous fournissent les plate-formes de
              services cloud en ligne qui nous permettent de vous proposer nos
              services et produits.
              <br />
              <br />
              Vos données sont stockées pour partie dans le système de stockage
              de données et les bases de données de Google Inc. (profile
              utilisateur) et pour partie dans des bases de données HoloMake
              hebergées chez OVH Cloud (données métiers et techniques). Vos
              données sont conservées sur des serveurs sécurisés protégés par
              des pare-feus.
            </p>
            <br />
            <h5 className="text-left">
              ARTICLE 5 - SERVICES FOURNIS PAR DES TIERS
            </h5>
            <p className="text-justify">
              De manière générale, les fournisseurs tiers que nous utilisons
              vont uniquement recueillir, utiliser et divulguer vos
              renseignements dans la mesure du nécessaire pour pouvoir réaliser
              les services qu'ils nous fournissent.
              <br />
              <br />
              Cependant, certains tiers fournisseurs de services, comme les
              passerelles de paiement et autres processeurs de transactions de
              paiement, possèdent leurs propres politiques de confidentialité
              quant aux renseignements que nous sommes tenus de leur fournir
              pour vos transactions d'achat.
              <br />
              <br />
              En ce qui concerne ces fournisseurs, nous vous recommandons de
              lire attentivement leurs politiques de confidentialité pour que
              vous puissiez comprendre la manière dont ils traiteront vos
              renseignements personnels.
              <br />
              <br />
              Il ne faut pas oublier que certains fournisseurs peuvent être
              situés ou avoir des installations situées dans une juridiction
              différente de la vôtre ou de la nôtre. Donc si vous décidez de
              poursuivre une transaction qui requiert les services d'un
              fournisseur tiers, vos renseignements pourraient alors être régis
              par les lois de la juridiction dans laquelle ce fournisseur se
              situe ou celles de la juridiction dans laquelle ses installations
              sont situées.
              <br />
              <br />
              A titre d'exemple, si vous êtes situés au Canada et que votre
              transaction est traitée par une passerelle de paiement située aux
              Etats-Unis, les renseignements vous appartenant qui ont été
              utilisés pour conclure la transaction pourraient être divulgués en
              vertu de la législation des Etats-Unis, y compris le Patriot Act.
              <br />
              <br />
              Une fois que vous quittez notre site ou que vous êtes redirigés
              vers le site web ou l'application d'un tiers, vous n'êtes plus
              régi par la présente Politique de Confidentialité ni par les
              Conditions Générales de Vente et d'Utilisation de notre site web.
            </p>
            <br />
            <h5 className="text-left">Liens</h5>
            <p className="text-justify">
              Vous pourriez être amené à quitter notre site web en cliquant sur
              certains liens présents sur notre site. Nous n'assumons aucune
              responsabilité quant aux pratiques de confidentialité exercées par
              ces autres sites et vous recommandons de lire attentivement leurs
              politiques de confidentialité.
              <br />
            </p>
            <br />
            <h5 className="text-left">ARTICLE 6 - SECURITE</h5>
            <p className="text-justify">
              Pour protéger vos données personnelles, nous prenons des
              précautions raisonnables et suivons les meilleures pratiques de
              l'industrie pour nous assurer qu'elles ne soient pas perdues,
              détournées, consultées, divulguées, modifiées ou détruites de
              manière inappropriée.
              <br />
            </p>
            <br />
            <h5 className="text-left">FICHIERS TEMOINS (COOKIES)</h5>
            <p className="text-justify">
              Voici une liste de fichiers témoins que nous utilisons. Nous les
              avons énumérés ici pour que vous ayez la possibilité de choisir si
              vous souhaitez les autoriser ou non.
              <br />
              <br />
              _session_id, identificateur unique de session, nous permet de
              stocker les informations relatives à votre session (référent, page
              de renvoi, etc.).
              <br />
              <br />
              _secure_session_id, identificateur unique de session
              <br />
            </p>
            <br />
            <h5 className="text-left">ARTICLE 7 - ÂGE DE CONSENTEMENT</h5>
            <p className="text-justify">
              En utilisant ce site, vous déclarez que vous avez au moins l'âge
              de la majorité dans votre état ou province de résidence, et que
              vous nous avez donné votre consentement pour permettre à toute
              personne d'âge mineur à votre charge d'utiliser ce site web.
              <br />
            </p>
            <br />
            <h5 className="text-left">
              ARTICLE 8 - MODIFICATIONS APPORTEES A LA PRESENTE POLITIQUE DE
              CONFIDENTIALITE
            </h5>
            <p className="text-justify">
              Nous nous réservons le droit de modifier la présente politique de
              confidentialité à tout moment, donc veuillez s'il vous plait la
              consulter fréquemment. Les changements et les clarifications
              prendront effet immédiatement après leur publication sur le site
              web. Si nous apportons des changements au contenu de cette
              politique, nous vous aviserons ici qu'elle a été mise à jour, pour
              que vous sachiez quels renseignements nous recueillons, la manière
              dont nous les utilisons, et dans quelles circonstances nous les
              divulguons, s'il y a lieu de le faire.
              <br />
              <br />
              Si notre site fait l'objet d'une acquisition par ou d'une fusion
              avec une autre entreprise, vos renseignements pourraient être
              transférés aux nouveaux propriétaires pour que nous puissions
              continuer à vous proposer nos services et nos produits.
            </p>
            <br />
            <h5 className="text-left">QUESTIONS ET COORDONNEES</h5>
            <p className="text-justify">
              Si vous souhaitez: accéder , corriger, modifier ou supprimer toute
              information personnelle que nous avons à votre sujet, déposer une
              plainte, ou si vous souhaitez simplement avoir plus
              d'informations, contactez notre agent responsable des normes de
              confidentialité à info@holomake.fr ou par courrier à HoloMake
              <br />
            </p>
            <br />
            <h5 className="text-left">Agent des Normes de ConfidentialitE</h5>
            <p className="text-justify">
              24 Boulevard Verd de Saint Julien, MEUDON, 92190, France
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Rgpd
