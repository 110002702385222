import React, { useEffect, useMemo, useState } from "react"
import { FiLink, FiFile } from "react-icons/fi"
import { MdClose } from "react-icons/md"
import { AuthUserContext } from "../Authentication/Session/Session"
import { withFirebase } from "../Authentication/Firebase"
import { withRouter } from "react-router-dom"
import { compose } from "recompose"
import { withAuthorization } from "../Authentication/Session/Session"
import * as ROUTES from "../Constants/Routes"
import Snackbar from "../Snackbar/Snackbar"
import CloseButton from "../Common/CloseButton"
import { HMFileIO, getUrlDisplayFormat } from "../Common/HMFileIO"
import { openFileManager } from "../Constants/UrlConfig"
import { isUrl } from "../Common/utils"

import ReactTooltip from "react-tooltip"

const EditContent = (props) => {
  const [projectId, setProjectId] = useState(null)

  useEffect(() => {
    if (!props.creation) {
      const contentId = props.match.params.id
      props.firebase
        .content(contentId)
        .once("value")
        .then((snap) => {
          setProjectId(snap.val().project_id)
        })
    }
  }, [props])

  return (
    <div>
      <div className="container-wrapper">
        <div className="container">
          <div className="jumbotron text-center">
            <CloseButton
              itemId={props.creation ? props.match.params.id : projectId}
            />
            <h2 className="text-center">
              {props.creation
                ? "ADD WORK INSTRUCTION"
                : "EDIT WORK INSTRUCTION"}
            </h2>
            <Content {...props} />
          </div>
        </div>
      </div>
    </div>
  )
}

const EditUserContent = (props) => {
  const [state, setState] = useState({
    group_id: "",
    projectId: "",
    description: "",
    name: "",
    comment: "",
    image: "",
    imageValidity: true,
  })
  const [clipboardValidity, setClipboardValidity] = useState(false)
  const [clipboard, setClipboard] = useState("")

  const onChange = (event) => {
    const name = event.target.name
    const value = event.target.value
    if (!name || !value) return
    setState((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const alertFail = () => {
    alert("Text from clipboard isn't a valid URL")
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    const { group_id, description, name, comment, image, imageValidity } = state
    if (!imageValidity) {
      alertFail()
      return
    }
    // look for image descriptor from DB
    const uid = props.firebase.auth.currentUser.uid
    let projectId = null
    try {
      const picData = await HMFileIO(uid).checkPicture(image)
      const pictureId = picData ? picData.id : ""
      if (props.creation) {
        projectId = props.match.params.id
        const currentUser = props.firebase.auth.currentUser
        if (currentUser) {
          const contentKey = props.firebase.content().push().key
          props.firebase.content(contentKey).set({
            project_id: projectId,
            user_id: currentUser.uid,
            group_id: group_id,
            name: name,
            description: description,
            comment: comment ? comment : "",
            image: image,
            picture_id: pictureId,
            created: props.firebase.TIMESTAMP,
            last_updated: props.firebase.TIMESTAMP,
          })
        }
      } else {
        projectId = state.projectId
        const contentId = props.match.params.id
        props.firebase.content(contentId).update({
          name: name,
          description: description,
          comment: comment ? comment : "",
          image: image,
          picture_id: pictureId,
          last_updated: props.firebase.TIMESTAMP,
        })
      }
      const snackbarComponent = document.getElementById("snackbar")
      snackbarComponent.className = "show"
      const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms))
      await wait(1000)
    } catch (e) {
      console.log(e)
    } finally {
      props.history.push({
        pathname: ROUTES.getHomeRoute(props.firebase.auth.currentUser.uid),
        state: projectId
      })
    }
  }

  const checkClipboardValidity = async () => {
    try {
      const text = await navigator.clipboard.readText()
      if (!isUrl(text)) {
        return false
      }
      const uid = props.firebase.auth.currentUser.uid
      const validity = await HMFileIO(uid).imageValidityCheck(text)
      if (!validity) {
        return false
      }
      return text
    } catch (e) {
      //DOM is not focused
      return false
    }
  }
  const refreshClipboard = () => {
    navigator.clipboard
      .readText()
      .then((text) => {
        if (text !== clipboard) {
          setClipboard(text)
        }
      })
      .catch((e) => {
        //DOM is not focused
      })
  }

  const onPasteLink = async () => {
    checkClipboardValidity().then((clipboardText) => {
      if (clipboardText) {
        setState((prev) => ({ ...prev, image: clipboardText }))
      } else {
        alertFail()
      }
    })
  }
  const onClearLink = () => {
    setState((prev) => ({ ...prev, image: "", imageValidity: true }))
  }
  const onOpenFileManager = () => {
    let projectId = undefined
    if (props.creation) {
      projectId = props.match.params.id
    } else {
      projectId = state.projectId
    }
    props.firebase.project(projectId).once("value", async (snap) => {
      const folderId = snap.val().folder_id
      openFileManager(folderId, await props.firebase.doGetIdToken())
    })
  }

  useEffect(() => {
    // load user group
    const uid = props.firebase.auth.currentUser.uid
    props.firebase
      .groups(uid)
      .once("value", (snap) => {
        if (snap.val())
          setState((prev) => ({ ...prev, group_id: snap.val()[0] }))
      })
    // load existing content data
    if (!props.creation) {
      const contentId = props.match.params.id
      props.firebase
        .content(contentId)
        .once("value")
        .then((snap) => {
          const data = snap.val()
          const part_url = data.image
          if (part_url) {
            HMFileIO(uid).imageValidityCheck(part_url).then((status) =>
              setState((prev) => ({ ...prev, imageValidity: status }))
            )
          }
          setState((prev) => ({
            ...prev,
            projectId: data.project_id,
            description: data.description,
            name: data.name,
            comment: data.comment,
            image: part_url,
          }))
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // check if clipboard text is valid every second
    const interval = setInterval(() => {
      refreshClipboard()
    }, 1000)
    return () => {
      clearInterval(interval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clipboard])

  useEffect(() => {
    checkClipboardValidity().then((clipboardText) => {
      setClipboardValidity(Boolean(clipboardText))
    })
  }, [clipboard])

  const inputValue = useMemo(() => {
    return getUrlDisplayFormat(state.image)
  }, [state.image])

  return (
    <AuthUserContext.Consumer>
      {() => (
        <div>
          <form className="userForm" onSubmit={handleSubmit}>
            <div className="form-row">
              <div className="form-group offset-lg-3 col-lg-6">
                <label className="col-form-label">Name*</label>
                <input
                  defaultValue={state.name}
                  type="text"
                  className="form-control"
                  name="name"
                  onChange={onChange}
                  required
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group offset-lg-3 col-lg-6">
                <label className="col-form-label">Description</label>
                <input
                  defaultValue={state.description}
                  type="text"
                  className="form-control"
                  name="description"
                  onChange={onChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group offset-lg-3 col-lg-6">
                <label className="col-form-label">Comment</label>
                <textarea
                  defaultValue={state.comment}
                  className="form-control"
                  name="comment"
                  rows={3}
                  onChange={onChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group offset-lg-3 col-lg-6">
                <label className="col-form-label">Cover Image</label>
                <div className="form-media-link-wrapper">
                  <div
                    className="form-media-link"
                    data-tip
                    data-for="form_media_link"
                  >
                    <input
                      value={inputValue}
                      readOnly={true}
                      type="text"
                      style={{
                        color: state.imageValidity ? "inherit" : "#FA8289",
                      }}
                      name="image"
                      onChange={onChange}
                      onClick={(e) => {
                        e.stopPropagation()
                        state.image
                          ? onClearLink()
                          : clipboardValidity
                          ? onPasteLink()
                          : onOpenFileManager()
                      }}
                    />
                    {state.image ? (
                      <MdClose className="input-deco-button" />
                    ) : clipboardValidity ? (
                      <FiLink className="input-deco-button" />
                    ) : (
                      <></>
                    )}
                    <ReactTooltip id="form_media_link" scrollHide={false}>
                      <span>
                        {state.image
                          ? "Clear Link"
                          : clipboardValidity
                          ? "Paste Link"
                          : "Copy Image Link From File Manager"}
                      </span>
                    </ReactTooltip>
                  </div>
                  <div className="form-group d-inline-flex col-1">
                    <div className="card-action pl-2">
                      <FiFile
                        size="1.5rem"
                        data-tip="Files"
                        data-for="rtt_card_action_open_fm"
                        onClick={() => onOpenFileManager()}
                      />
                      <ReactTooltip
                        id="rtt_card_action_open_fm"
                        effect="solid"
                        place="bottom"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button className="btn hmk-form-btn" type="submit">
              Ok
            </button>
          </form>
          <Snackbar
            message={
              props.creation
                ? "The content has been created"
                : "The content has been modified"
            }
          />
        </div>
      )}
    </AuthUserContext.Consumer>
  )
}

const Content = compose(withRouter, withFirebase)(EditUserContent)

const condition = (currentUser) => !!currentUser

export default withAuthorization(condition)(EditContent)
