import React, { useEffect, useState, useMemo } from "react"
import { HMFileIO } from "../../Common/HMFileIO"

const ContentCardText = ({ uid, isListLayout, card, viewerOnlineStatus }) => {
  const [folderName, setFolderName] = useState("")

  useEffect(() => {
    if (card.folderID && viewerOnlineStatus === "online" && !folderName) {
      HMFileIO(uid)
        .loadFolder(card.folderID)
        .then((res) => setFolderName(res.name))
    }
  }, [uid, card.folderID, viewerOnlineStatus, folderName])

  const descriptionStyle = useMemo(() => {
    let style = {}
    if (viewerOnlineStatus) {
      style.color =
        viewerOnlineStatus === "offline"
          ? "#FF6464"
          : viewerOnlineStatus === "processing"
          ? "#169FFF"
          : "#4EC9B0"
    }
    return style
  }, [viewerOnlineStatus])

  if (isListLayout) {
    return (
      <div className="content-list-caption">
        <div className="content-list-caption-left">
          <span style={{ lineHeight: "1.1rem" }}>{card.name}</span>
          {folderName ? (
            <span style={{ fontSize: "1rem", color: "#cccccc" }}>
              Folder : {folderName}
            </span>
          ) : (
            <></>
          )}
        </div>
        <div className="content-list-caption-right">
          {card.description ? (
            <span style={descriptionStyle}>{card.description}</span>
          ) : (
            <></>
          )}
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="caption text-center">
        <span>{card.name}</span>
        {folderName ? (
          <span style={{ fontSize: "1rem", color: "#cccccc" }}>
            Folder : {folderName}
          </span>
        ) : (
          <></>
        )}
        {card.description ? (
          <p style={descriptionStyle}>{card.description}</p>
        ) : (
          <></>
        )}
      </div>
    </>
  )
}

export default ContentCardText
