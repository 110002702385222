import React from "react"

import { MdOutlineSecurityUpdate } from "react-icons/md"
import ReactTooltip from "react-tooltip"

const DownloadAPK = ({ userId, module }) => {
  return (
    <a
      href={`${module.link}`}
      target="blank"
      className="nav-link navbar-btn-action"
      data-for="rtt_navbar_viewer_apk"
      data-tip={module.description}
    >
      <MdOutlineSecurityUpdate />
      <ReactTooltip id="rtt_navbar_viewer_apk" effect="solid" place="bottom" />
    </a>
  )
}

export { DownloadAPK }
