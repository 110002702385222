// utc_time : date of creation (optional)
import React from "react"
import { HMFileIO } from "../Common/HMFileIO"
import { FiMonitor, FiCamera } from "react-icons/fi"
import { RiProjector2Line } from "react-icons/ri"

const DEBUG = false

export const DEVICE_TYPES = ["MONITOR", "CAMERA", "PROJECTOR"]

export const getLayoutDirKeysPerDeviceTypes = () => {
  return DEVICE_TYPES.map((d) => `layout_dir_${d}`)
}

export const DEVICES_INITIAL_STATE = Object.assign(
  {},
  ...DEVICE_TYPES.map((devType) => ({
    [devType]: [],
  }))
)

export const DeviceTypeIcons = ({ type, sizeInPixels, color }) => {
  let style = {
    size: `${sizeInPixels}px`,
    style: { color: color },
  }
  switch (type) {
    case "MONITOR":
      return <FiMonitor {...style} />
    case "CAMERA":
      return <FiCamera {...style} />
    case "PROJECTOR":
      return <RiProjector2Line {...style} />
    default:
      return <></>
  }
}

// CAMERA/PROJECTOR/SCREEN DISPLAY DEFINITIONS
// camera_v for Camera in ViSP format
// camera_b for Camera in Blender format
// name = device name
// image_width = render_width = resolution w
// image_height = render_height = resolution h
// type = 'ORTHO' for screen display
// sensor_width = -1 set for undefined
// px = scale X in px/m -> 1000 / (0.001 * physical width for 1000 pixels in mm)
// py = scale Y in px/m -> 1000 / (0.001 * physical height for 1000 pixels in mm)
// u0 = image_width / 2 (for screen display)
// v0 = image_height / 2 (for screen display)
// lens = -1 set for undefined
// shift_x = 0.0 (default)
// shift_y = 0.0 (default)

// POSE ESTIMATION PARAMETERS
// estimation_mode = 'NONE' for screen display
// points_coordinates_pixels_col_row_top = 8 values list :
// [w/2-500, h/2+500, w/2+500, h/2+500, w/2+500, h/2-500, w/2-500, h/2-500]
// points_coordinates_meters = 12 values list :
// [-1000/(2*px), -1000/(2*py), 0.0, 1000/(2*px), -1000/(2*py), 0.0, 1000/(2*px), 1000/(2*py), 0.0, -1000/(2*px), 1000/(2*py), 0.0 ]
// error_residual = 0.0
// opengl_mvm = 4x4 identity matrix by default :
// [ 1.0, 0.0, 0.0, 0.0,
//   0.0, 1.0, 0.0, 0.0,
//   0.0, 0.0, 1.0, 0.0,
//   0.0, 0.0, 0.0, 1.0 ]
// pre_translation_vector_m = 3D vector to [0 0 0 ] by default
// object_location = 3D vector to [0 0 0 ] by default
// quaternion_xyzw = 4D identity quaternion by default: [0 0 0 1]
const deviceTemplate = {
  utc_time: "",
  name: "",
  key: "",
}

const monitorTemplate = {
  ...deviceTemplate,
  pose: {
    camera_v: {
      name: "ORTHO_ACER_ASPIRE_SW",
      image_width: 1920,
      image_height: 1080,
      type: "ORTHO",
      sensor_width: -1.0,
      px: 5571.0306406685236768802228412256,
      py: 5571.0306406685236768802228412256,
      u0: 960.0,
      v0: 540.0,
    },
    camera_b: {
      name: "ORTHO_ACER_ASPIRE_SW",
      render_width: 1920,
      render_height: 1080,
      type: "ORTHO",
      sensor_width: -1.0,
      lens: -1.0,
      shift_x: 0.0,
      shift_y: 0.0,
    },
    estimation_mode: "NONE",
    points_coordinates_pixels_col_row_top: [
      460.0, 1040.0, 1460.0, 1040.0, 1460.0, 40.0, 460.0, 40.0,
    ],
    points_coordinates_meters: [
      -0.08975, -0.08975, 0.0, 0.08975, -0.08975, 0.0, 0.08975, 0.08975, 0.0,
      -0.08975, 0.08975, 0.0,
    ],
    error_residual: 0.0,
    opengl_mvm: [
      1.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0,
      1.0,
    ],
    pre_translation_vector_m: [0.0, 0.0, 0.0],
    object_location: [0.0, 0.0, 0.0],
    quaternion_xyzw: [0.0, 0.0, 0.0, 1.0],
  },
}

export const deviceList = (fb_cnx, uid, filterByGroup) =>
  deviceListByType(fb_cnx, uid, filterByGroup).then((allDevices) =>
    DEVICE_TYPES.reduce((result, type) => result.concat(allDevices[type]), [])
  )

// this method return a Promise, so then on it to get result
export const deviceListByType = (fb_cnx, uid, filterByGroup) => {
  let allDevices = Object.assign(
    {},
    ...DEVICE_TYPES.map((devType) => ({
      [devType]: [],
    }))
  )
  return fb_cnx
    .devices()
    .orderByChild(filterByGroup ? "device/group_id" : "device/user_id")
    .equalTo(uid)
    .once("value", (snapshot) => {
      snapshot.forEach((snap) => {
        let type = snap.child("device").child("type").val()
        if (!DEVICE_TYPES.includes(type)) type = DEVICE_TYPES[0]
        allDevices[type].push({
          id: snap.key,
          key: snap.key,
          name: snap.child("device").child("name").val(),
          type: type,
          localisation: snap.child("device").child("installation_site").val(),
          viewer_args: snap.child("device").child("viewer_args").val(),
        })
      })
    })
    .then(() => {
      return allDevices
    })
    .catch((e) => {
      console.log(e)
    })
}

// retrieve the device resolution, whatever device type
export const deviceResolution = (fb_cnx, device_id) => {
  return fb_cnx
    .devices(device_id)
    .once("value")
    .then((snap) => {
      const type = snap.child("device").child("type").val()
      // case for MONITOR
      if (type === DEVICE_TYPES[0]) {
        return {
          width: snap.child("screen_display").val().resolution_x,
          height: snap.child("screen_display").val().resolution_y,
        }
      } else if (type === DEVICE_TYPES[1]) {
        const pose = JSON.parse(snap.child("camera").val().device_json)
        if (pose && "camera_v" in pose)
          return {
            width: pose.camera_v.image_width,
            height: pose.camera_v.image_height,
          }
        else return {}
      } else if (type === DEVICE_TYPES[2]) {
        const pose = JSON.parse(snap.child("projector").val().device_json)
        if (pose && "camera_v" in pose)
          return {
            width: pose.camera_v.image_width,
            height: pose.camera_v.image_height,
          }
        else return {}
      } else {
        return {}
      }
    })
}

export const deviceFullList = (fb_cnx, uid, filterByGroup) => {
  let allDevices = []
  return fb_cnx
    .devices()
    .orderByChild(filterByGroup ? "device/group_id" : "device/user_id")
    .equalTo(uid)
    .once("value", (snapshot) => {
      snapshot.forEach((snap) => {
        const key = snap.key
        let type = snap.child("device").child("type").val()
        if (!DEVICE_TYPES.includes(type)) type = DEVICE_TYPES[0]
        const name = snap.child("device").child("name").val()
        const viewer_args = snap.child("device").child("viewer_args").val()
        var device = {}
        if (type === DEVICE_TYPES[0]) {
          const screen = snap.child("screen_display")
          const width = parseFloat(screen.child("physical_width").val())
          const height = parseFloat(screen.child("physical_height").val())
          const res_x = parseInt(screen.child("resolution_x").val())
          const res_y = parseInt(screen.child("resolution_y").val())
          const px = 1000 / (0.001 * width)
          const py = 1000 / (0.001 * height)
          device = JSON.parse(JSON.stringify(monitorTemplate))
          device.pose.camera_v.name = name
          device.pose.camera_v.image_width = res_x
          device.pose.camera_v.image_height = res_y
          device.pose.camera_v.px = px
          device.pose.camera_v.py = py
          device.pose.camera_v.u0 = res_x / 2.0
          device.pose.camera_v.v0 = res_y / 2.0
          device.pose.camera_b.name = name
          device.pose.camera_b.render_width = res_x
          device.pose.camera_b.render_height = res_y
          device.pose.points_coordinates_pixels_col_row_top = [
            res_x / 2 - 500,
            res_y / 2 + 500,
            res_x / 2 + 500,
            res_y / 2 + 500,
            res_x / 2 + 500,
            res_y / 2 - 500,
            res_x / 2 - 500,
            res_y / 2 - 500,
          ]
          device.pose.points_coordinates_meters = [
            -1000 / (2 * px),
            -1000 / (2 * py),
            0.0,
            1000 / (2 * px),
            -1000 / (2 * py),
            0.0,
            1000 / (2 * px),
            1000 / (2 * py),
            0.0,
            -1000 / (2 * px),
            1000 / (2 * py),
            0.0,
          ]
          const params = JSON.parse(screen.child("device_json").val())
          device = { ...device, params: params }
        } else if (type === DEVICE_TYPES[1]) {
          // case CAMERA device
          const camera = snap.child("camera")
          const capture_id = parseInt(
            camera.child("capture_device_index").val()
          )
          let camera_rotate = camera.child("camera_rotate_180").val()
          if (!camera_rotate) camera_rotate = false
          let camera_realsize = camera.child("camera_realsize").val()
          if (!camera_realsize) camera_realsize = false
          let pose = JSON.parse(camera.child("device_json").val())
          // flatten pose_setup and pose_result data
          pose = { ...pose, ...pose.pose_setup, ...pose.pose_result }
          delete pose.pose_setup
          delete pose.pose_result
          device = JSON.parse(JSON.stringify(deviceTemplate))
          device.capture_device_index = capture_id
          device.camera_rotate_180 = camera_rotate
          device.camera_realsize = camera_realsize
          device = { ...device, pose: pose }
        } else if (type === DEVICE_TYPES[2]) {
          // case PROJECTOR device
          const projector = snap.child("projector")
          const pose = JSON.parse(projector.child("device_json").val())
          device = JSON.parse(JSON.stringify(deviceTemplate))
          device = { ...device, pose: pose }
        }
        device.name = name
        device.key = key
        device.type = type
        device.viewer_args = viewer_args
        allDevices.push({ ...device })
      })
    })
    .then(() => allDevices)
}

export const extractDevicesData = (
  userId,
  fb_cnx,
  uid,
  filterByGroup,
  folderid = null
) => {
  deviceFullList(fb_cnx, uid, filterByGroup)
    .then((allDevices) => {
      const file = new File([JSON.stringify(allDevices)], "devices.json", {
        type: "application/json",
      })
      if (folderid) {
        // upload this to specified project
        HMFileIO(userId)
          .uploadFileXHR(file, folderid, true, { tags: ["hide"] })
          .then((res) => {
            DEBUG && console.log("Uploaded Device file to FileManager : ", res)
          })
      } else {
        // else upload this to all user project folder
        const projects = fb_cnx
          .projects()
          .orderByChild(filterByGroup ? "group_id" : "user_id")
          .equalTo(uid)
        if (projects) {
          projects.once("value", (snapshot) => {
            snapshot.forEach((snap) => {
              const prjName = snap.val().name
              const prjFolderId = snap.val().folder_id
              if (prjFolderId) {
                HMFileIO(userId)
                  .uploadFileXHR(file, prjFolderId, true, { tags: ["hide"] })
                  .then((res) => {
                    DEBUG &&
                      console.log(
                        "Uploaded Device file for project " +
                          prjName +
                          " to FileManager : ",
                        res
                      )
                  })
              }
            })
          })
        }
      }
    })
    .catch((e) => {
      console.log(e)
    })
}
