import React from "react"

import { Link } from "react-router-dom"

import { FiPlus } from "react-icons/fi"
import ReactTooltip from "react-tooltip"

const Project = () => {
  return (
    <Link
      to="/project"
      className="nav-link navbar-btn-action"
      data-for="rtt_navbar_project"
      data-tip="Add Project"
    >
      <FiPlus />
      <ReactTooltip id="rtt_navbar_project" effect="solid" place="bottom" />
    </Link>
  )
}

export default Project
