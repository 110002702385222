import React, { useState, useEffect, useContext } from "react"

import { FaPlus, FaArrowUp, FaArrowDown } from "react-icons/fa"
import { FiCopy, FiTrash2, FiEdit2 } from "react-icons/fi"
import ReactTooltip from "react-tooltip"
import { ContentStoreCtx } from "../Store/ContentStore"
import {
  TbAugmentedReality,
  TbTextScan2,
  TbLockAccess,
  TbLockUp,
} from "react-icons/tb"
import { MdQrCodeScanner } from "react-icons/md"

import HMKSVGSlide from "./HMKSVGSlide"

const Master = ({ sequence, onClick, dispatch, viewBox, refreshDetail }) => {
  const [, contentStoreDispatch] = useContext(ContentStoreCtx)
  const [mustDisplayLastAdded, setMustDisplayLastAdded] = useState(0)

  const onDelete = (e, item) => {
    dispatch({ type: "REMOVE_SLIDE", payload: item })
    refreshDetail()
    e.stopPropagation()
  }

  const onCopy = (e, item) => {
    // if we add a last slide in the list, prepare some scroll postpro
    const id = sequence.slides.indexOf(item)
    //if (id === sequence.slides.length - 1) {
    setMustDisplayLastAdded(id)
    //}
    // add a new slide after item
    dispatch({ type: "COPY_SLIDE", payload: item })
    e.stopPropagation()
  }

  const onAdd = (e, item) => {
    // if we add a last slide in the list, prepare some scroll postpro
    const id = sequence.slides.indexOf(item)
    //if (id === sequence.slides.length - 1) {
    setMustDisplayLastAdded(id)
    //}
    // add a new slide after item
    dispatch({ type: "ADD_SLIDE", payload: item })
    e.stopPropagation()
  }

  const onUp = (e, item) => {
    dispatch({ type: "SLIDE_UP", payload: item })
  }

  const onDown = (e, item) => {
    dispatch({ type: "SLIDE_DOWN", payload: item })
  }

  const onEdit = () => {
    contentStoreDispatch({ type: "VIEW_MODE", payload: "content" })
  }

  useEffect(() => {
    if (mustDisplayLastAdded) {
      const seq_id = sequence.slides[mustDisplayLastAdded + 1].seq_id
      const el = document.getElementById(`master-slide-${seq_id}`)
      el.scrollIntoView()
      setMustDisplayLastAdded(0)
    }
  }, [mustDisplayLastAdded, sequence.slides])

  return (
    <div className="master">
      {sequence.slides &&
        sequence.slides.map((item, i) => (
          <div
            key={item.seq_id}
            className={
              "card mb-3 mx-3 text-white" +
              (item.seq_id === sequence.currentId ? " selected" : "")
            }
            style={{ aspectRatio: `${viewBox[2]}/${viewBox[3]}` }}
            id={`master-slide-${item.seq_id}`}
            onClick={(e) => onClick(e, item.seq_id)}
          >
            {/** Componenent for the SVG slide content */}
            <HMKSVGSlide
              template={sequence.template}
              item={item}
              counter={{
                index: i + 1,
                total: sequence.slides.length,
              }}
              viewBox={viewBox}
            />
            {/** Display the master slide toolbar */}
            <div className="d-flex justify-content-between master-slide-toolbar">
              <div
                className="d-flex"
                style={{ gap: "0.5rem", paddingLeft: "0.25rem" }}
              >
                {item.detection_mode ? (
                  <div className="home-icon">
                    <div
                      data-for={`slide_master_detection_${i}`}
                      data-tip={`Detection mode: ${item.detection_mode}`}
                    >
                      {item.detection_mode === "ML" ? (
                        <TbAugmentedReality />
                      ) : item.detection_mode === "OCR" ? (
                        <TbTextScan2 />
                      ) : item.detection_mode === "QR" ? (
                        <MdQrCodeScanner />
                      ) : (
                        <></>
                      )}
                    </div>
                    <ReactTooltip
                      place="right"
                      id={`slide_master_detection_${i}`}
                      effect="solid"
                    />
                  </div>
                ) : (
                  <></>
                )}
                {item.blocking_mode ? (
                  <div className="home-icon">
                    <div
                      data-for={`slide_master_blocking_${i}`}
                      data-tip={`Blocking mode: ${item.blocking_mode}`}
                    >
                      {item.blocking_mode === "DETECTION" ? (
                        <TbLockAccess />
                      ) : item.blocking_mode === "SNAP" ? (
                        <TbLockUp />
                      ) : (
                        <></>
                      )}
                    </div>
                    <ReactTooltip
                      place="right"
                      id={`slide_master_blocking_${i}`}
                      effect="solid"
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className="d-flex">
                {i > 0 && (
                  <button className="home-icon" onClick={(e) => onUp(e, item)}>
                    <FaArrowUp
                      data-for={`slide_master_move_up_${i}`}
                      data-tip="Move Up"
                    />
                    <ReactTooltip
                      id={`slide_master_move_up_${i}`}
                      effect="solid"
                      place="bottom"
                    />
                  </button>
                )}
                {i < sequence.slides.length - 1 && (
                  <button
                    className="home-icon"
                    onClick={(e) => onDown(e, item)}
                  >
                    <FaArrowDown
                      data-for={`slide_master_move_down_${i}`}
                      data-tip="Move Down"
                    />
                    <ReactTooltip
                      id={`slide_master_move_down_${i}`}
                      effect="solid"
                      place="bottom"
                    />
                  </button>
                )}
                <button className="home-icon" onClick={(e) => onCopy(e, item)}>
                  <FiCopy
                    data-for={`slide_master_duplicate_${i}`}
                    data-tip="Duplicate"
                  />
                  <ReactTooltip
                    id={`slide_master_duplicate_${i}`}
                    effect="solid"
                    place="bottom"
                  />
                </button>
                <button className="home-icon" onClick={(e) => onEdit(e, item)}>
                  <FiEdit2
                    data-for={`slide_master_edit_${i}`}
                    data-tip="Edit"
                  />
                  <ReactTooltip
                    id={`slide_master_edit_${i}`}
                    effect="solid"
                    place="bottom"
                  />
                </button>
                <button className="home-icon" onClick={(e) => onAdd(e, item)}>
                  <FaPlus data-for={`slide_master_add_${i}`} data-tip="Add" />
                  <ReactTooltip
                    id={`slide_master_add_${i}`}
                    effect="solid"
                    place="bottom"
                  />
                </button>
                {sequence.slides.length > 1 && (
                  <button
                    className="home-icon"
                    onClick={(e) => onDelete(e, item)}
                  >
                    <FiTrash2
                      data-for={`slide_master_delete_${i}`}
                      data-tip="Delete"
                    />
                    <ReactTooltip
                      id={`slide_master_delete_${i}`}
                      effect="solid"
                      place="bottom"
                    />
                  </button>
                )}
              </div>
            </div>
          </div>
        ))}
      {sequence.parts &&
        sequence.parts.map((part) => (
          <div
            className="card mb-3 mx-3 bg-dark text-warning"
            key={part.part_id}
            onClick={() => onClick(part)}
          >
            <div className="card-header">{part.part_pick_name}</div>
            <div className="card-body">
              <p>Title: {part.part_name}</p>
            </div>
          </div>
        ))}
    </div>
  )
}

export default Master
